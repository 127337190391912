import { SupportedChainId } from './chains'

const PUBLIC_URL: Record<number, string> = {
  [SupportedChainId.MAINNET]: process.env.REACT_APP_PUBLIC_URL ?? 'https://yephome.io',
  [SupportedChainId.GOERLI]: process.env.REACT_APP_PUBLIC_URL ?? 'https://yephome.io'
}

const CLOUD_NAME: Record<number, string> = {
  [SupportedChainId.MAINNET]: 'Yep'
}

export const PublicUrl = (chainId: number) => {
  return PUBLIC_URL[chainId] ?? 'https://yephome.io'
}

export const GetCloudName = (chainId: number) => {
  return CLOUD_NAME[chainId] ?? 'Yep'
}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { CHAIN_API_URL, SupportedChainId } from 'constants/chains'
import store, { customValidateStatus } from 'state'
import { ResponseBackend } from 'state/userApi/types'

function getBaseUri(): any {
  const chainId = store?.getState()?.application?.chainId
    ? store?.getState()?.application?.chainId
    : SupportedChainId.MAINNET

  const apiUrl = chainId ? CHAIN_API_URL[chainId] : process.env.REACT_APP_API_GATEWAY
  return apiUrl
}

export const postApi = createApi({
  reducerPath: 'postApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUri(),
    prepareHeaders: (headers) => {
      const { user } = store?.getState()
      if (user && user.userInfo && user.userInfo.accessToken) {
        headers.set('Authorization', `Bearer ${user.userInfo.accessToken}`)
      }
      headers.set('x-api-key', 'AM2Ag22KKSasPLXpgIxyR5SGOek1ftXr49YqB2m3')
      return headers
    },
    fetchFn: (...args) => {
      // if (window.AwsWafIntegration) {
      //   console.log('use AwsWafIntegration')
      //   return window.AwsWafIntegration.fetch(...args)
      // } else {
      return fetch(...args)
      // }
    }
  }),
  endpoints: (build) => ({
    publishPost: build.mutation<
      ResponseBackend,
      {
        daoId: string
        content: string
        images: string[]
        tag?: string
        title?: string
        video?: [
          {
            url: string
          }
        ]
        brief?: string
        addressInfo?: string
        refUsers?: string[]
      }
    >({
      query: (body: any) => ({
        url: `/v1/posts/publish`,
        method: 'POST',
        body,
        validateStatus: customValidateStatus
      })
    }),
    deletePost: build.mutation<
      ResponseBackend,
      {
        postId: string
      }
    >({
      query: (body: any) => ({
        url: `/v1/posts/delete`,
        method: 'POST',
        body,
        validateStatus: customValidateStatus
      })
    }),
    postComment: build.mutation<
      ResponseBackend,
      {
        content: string
        upID: string
        daoId: string
        title?: string
        images?: string[]
        tag?: string
        brief?: string
        type?: string
        refUsers?: string[]
      }
    >({
      query: (body: any) => ({
        url: `/v1/posts/comment`,
        method: 'POST',
        body,
        validateStatus: customValidateStatus
      })
    }),
    getPostList: build.mutation<any, any>({
      query: (params: any) => ({
        url: `/v1/posts/list`,
        method: 'GET',
        params,
        signal: params.signal,
        validateStatus: customValidateStatus
      })
    }),
    deleteComment: build.mutation<
      ResponseBackend,
      {
        commentId: string
      }
    >({
      query: (body: any) => ({
        url: `/v1/posts/comment/delete`,
        method: 'POST',
        body,
        validateStatus: customValidateStatus
      })
    }),
    getCommentList: build.mutation<any, any>({
      query: (params: any) => ({
        url: `/v1/posts/comment/list`,
        method: 'GET',
        params,
        validateStatus: customValidateStatus
      })
    }),
    getPostInfo: build.mutation<any, any>({
      query: (params: any) => ({
        url: `v1/posts/info`,
        method: 'GET',
        params,
        validateStatus: customValidateStatus
      })
    })
  })
})

export const {
  useGetCommentListMutation,
  useDeletePostMutation,
  useGetPostListMutation,
  usePostCommentMutation,
  usePublishPostMutation,
  useDeleteCommentMutation,
  useGetPostInfoMutation
} = postApi

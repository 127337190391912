import * as S from 'components/TaskInfo/styles'
import { useEffect, useState } from 'react'
import { useLeftModalOpen, useToggleTaskInfo } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { useGetTaskDescriptionMutation } from 'state/taskApi/slice'

export default function TaskInfo() {
  const open = useLeftModalOpen(ApplicationModal.TASK_INFO)
  const toggle = useToggleTaskInfo()
  const [getTaskDescription] = useGetTaskDescriptionMutation()
  const [taskInfo, setTaskInfo] = useState([] as any[])

  useEffect(() => {
    if (open) {
      getTaskDescription(null).then((data: any) => {
        setTaskInfo(data?.data?.descriotion)
      })
    }
  }, [getTaskDescription, open])

  return (
    <>
      {open && (
        <S.Panel>
          <S.CloseIcon onClick={toggle}>
            <S.CloseColor />
          </S.CloseIcon>
          <S.Content>
            <S.Table>
              <S.TableTitle>
                <S.FirstCell>Daily Tasks</S.FirstCell>
                <S.UnitCell>Unit Reward</S.UnitCell>
                <S.MaxCell>Max Reward</S.MaxCell>
              </S.TableTitle>
              {taskInfo &&
                taskInfo.map((item, index) => {
                  return (
                    <S.TableContent key={index} lastItem={index === taskInfo.length - 1}>
                      <S.FirstCell>{item?.description}</S.FirstCell>
                      <S.UnitCell>{item?.quantity}</S.UnitCell>
                      <S.MaxCell>{item?.limitPerDay * item?.quantity}</S.MaxCell>
                    </S.TableContent>
                  )
                })}
            </S.Table>
          </S.Content>
        </S.Panel>
      )}
    </>
  )
}

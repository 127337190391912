import { ReactComponent as Close } from 'assets/svg/x.svg'
import styled from 'styled-components/macro'
import { CommonPanel } from 'theme'

export const Panel = styled(CommonPanel)<{ width?: number | undefined; innerheight?: number }>`
  margin-right: 1.45vw;
  flex: 1;
  height: fit-content;

  z-index: 1;
  position: fixed;
  top: 2.69vh;
  width: 68.46vw;

  ${({ theme, innerheight }) => theme.mediaWidth.upToExtraLarge`
    margin-right: 0.83vw;
  `};

  ${({ theme, innerheight }) => theme.mediaWidth.upToLarge`
    margin-right: 0.83vw;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 0.71vh;
    position: relative;
    top: 0px;
    width: 100%;
  `};
`

export const CloseIcon = styled.div`
  position: absolute;
  top: 1.61vh;
  right: 1.04vw;
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 2.49vh;
    right: 5.38vw;
  `}
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`
export const CloseColor = styled(Close)`
  color: ${({ theme }) => theme.text4};
  width: 0.87vw;
  height: 0.87vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 3.85vw;
    height: 3.85vw;
  `}
`
export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1.79vh 1.85vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 2.37vh 5.13vw;
  `}
`

export const AvatarPanel = styled.div`
  width: 8.93vh;
  height: 8.93vh;
  border-radius: 50%;

  cursor: pointer;
  .reload-image-error {
    border-radius: 50%;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 11.85vh;
    height: 11.85vh;
  `}
`

export const Avatar = styled.img`
  width: 8.93vh;
  height: 8.93vh;
  border-radius: 50%;
  overflow: hidden;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 11.85vh;
    height: 11.85vh;
  `}
  object-fit: cover;
`

export const UserInfoPanel = styled.div`
  flex: 1;
  margin-left: 1.38vw;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-left: 6.92vw;
  `}
`

export const NickNamePanel = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 1.43vh;
  line-height: 1.7vh;
  word-wrap: break-word;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.9vh;
    line-height: 2.25vh;
    max-width: 44.87vw;
  `}

  color: ${({ theme }) => theme.text4};
`

export const AddressPanel = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 1.25vh;
  line-height: 1.43vh;
  margin-top: 0.36vh;
  word-wrap: break-word;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.66vh;
    line-height: 1.9vh;
    margin-top: 0.47vh;
    max-width: 44.87vw;
  `}

  color: rgba(222, 222, 222, 0.22);
`

export const MessageButton = styled.div`
  background: ${({ theme }) => theme.primary1};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.71vh;
  margin-top: 2.32vh;
  width: fit-content;
  font-style: normal;
  font-weight: 400;
  font-size: 1.43vh;
  line-height: 1.7vh;
  text-align: center;
  padding: 0.54vh 1.85vw;
  cursor: pointer;

  color: ${({ theme }) => theme.text4};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    border-radius: 0.95vh;
    margin-top: 1.66vh;
    font-size: 1.9vh;
    line-height: 2.25vh;
    padding: 0.71vh 8.21vw;
  `}
`

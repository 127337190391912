import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { CHAIN_API_URL, SupportedChainId } from 'constants/chains'
import store, { customValidateStatus } from 'state'

function getBaseUri(): any {
  const chainId = store?.getState()?.application?.chainId
    ? store?.getState()?.application?.chainId
    : SupportedChainId.MAINNET

  const apiUrl = chainId ? CHAIN_API_URL[chainId] : process.env.REACT_APP_API_GATEWAY
  return apiUrl
}

export const taskApi = createApi({
  reducerPath: 'taskApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUri(),
    prepareHeaders: (headers) => {
      const { user } = store?.getState()
      if (user && user.userInfo && user.userInfo.accessToken) {
        headers.set('Authorization', `Bearer ${user.userInfo.accessToken}`)
      }
      headers.set('x-api-key', 'AM2Ag22KKSasPLXpgIxyR5SGOek1ftXr49YqB2m3')
      return headers
    },
    fetchFn: (...args) => {
      // if (window.AwsWafIntegration) {
      //   console.log('use AwsWafIntegration')
      //   return window.AwsWafIntegration.fetch(...args)
      // } else {
      return fetch(...args)
      // }
    }
  }),
  endpoints: (build) => ({
    getTaskDescription: build.mutation<any, any>({
      query: (params: any) => ({
        url: `/v1/task/description`,
        method: 'GET',
        params,
        validateStatus: customValidateStatus
      })
    })
  })
})

export const { useGetTaskDescriptionMutation } = taskApi

import { animated } from '@react-spring/web'
import { ReactComponent as CheckMark } from 'assets/svg/check-mark.svg'
import { ReactComponent as Close } from 'assets/svg/x.svg'
import { ButtonPrimary } from 'components/Button'
import { TextInput } from 'components/TextInput'
import styled from 'styled-components/macro'
import { CommonPanel } from 'theme'

export const ProfileSetting = styled(CommonPanel)`
  padding: 1.25vh 0.81vw 2.6vh 1.27vw;
  margin-top: 0.54vh;
  position: relative;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 1.9vh 5.13vw 3.44vh 5.13vw;
    margin-top: 0.71vh;
  `};
`

export const HeaderText = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 1.43vh;
  margin-bottom: 1.61vh;
  cursor: pointer;
  color: ${({ theme }) => theme.text4};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.9vh;
    margin-bottom: 1.9vh;
  `}
`

export const CloseIcon = styled.div`
  position: absolute;
  right: 0.81vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    right: 5.13vw;
  `}
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`
export const CloseColor = styled(Close)`
  color: ${({ theme }) => theme.text4};
  width: 0.87vw;
  height: 0.87vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 3.85vw;
    height: 3.85vw;
  `}
`
export const AvatarPanel = styled.div`
  width: 10.74vh;
  height: 10.74vh;
  position: relative;
  border-radius: 50%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 14.22vh;
    height: 14.22vh;
  `}
`

export const AvatarContainer = styled.div`
  width: 10.74vh;
  height: 10.74vh;
  position: relative;
  border-radius: 50%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 14.22vh;
    height: 14.22vh;
  `}
`

export const Avatar = styled(animated.img)`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`

export const EditContainer = styled(animated.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
`

export const EditIcon = styled.img`
  width: 1.61vh;
  height: 1.61vh;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 2.13vh;
    height: 2.13vh;
  `}
`

export const ItemContainer = styled.div`
  margin-top: 2.15vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 2.84vh;
  `}
`

export const ItemLabel = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 1.25vh;
  white-space: nowrap;

  color: ${({ theme }) => theme.text4};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.66vh;
  `}
`

export const SaveBtn = styled(ButtonPrimary)`
  width: 8.28vw;
  margin-top: 2.06vh;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25vh;
  text-transform: uppercase;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.66vh;
    width: 36.67vw;
    margin-top: 2.73vh;
  `}

  color: ${({ theme }) => theme.text4};
`

export const Input = styled(TextInput)`
  width: 100%;
  margin-left: 0.87vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-left: 3.85vw;
  `}

  background: none;
  input {
    background-color: transparent;
    caret-color: ${({ theme }) => theme.primary1};
    font-weight: 400;
    font-size: 1.25vh;

    ${({ theme }) => theme.mediaWidth.upToSmall`
      font-size: 1.66vh;
    `}

    color: rgba(222, 222, 222, 0.22);
    ::placeholder {
      font-style: normal;
      font-weight: 300;
      color: rgba(222, 222, 222, 0.22);
    }
  }
`

export const CheckoutAgent = styled(CheckMark)<{ checked: boolean }>`
  color: ${({ theme, checked }) => (checked ? theme.primary2 : theme.text4)};
  width: 2.86vh;
  height: 2.86vh;
  cursor: pointer;
  margin-left: 1.27vw;
`

export const AgentText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 1.25vh;
  margin-left: 0.29vw;

  color: ${({ theme }) => theme.white};
`

export const CheckoutDeveloper = styled(CheckMark)<{ checked: boolean }>`
  color: ${({ theme, checked }) => (checked ? theme.primary1 : theme.text4)};
  width: 2.86vh;
  height: 2.86vh;
  cursor: pointer;
  margin-left: 1.27vw;
`

export const DeveloperText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 1.25vh;
  margin-left: 0.29vw;

  color: ${({ theme }) => theme.white};
`

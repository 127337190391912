import * as S from 'components/Confirmation/styles'
import { GetProjectStatus, GetProjectType } from 'constants/projects'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useAddPopup, useLeftModalOpen, useToggleConfirmationInfo } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { useAppSelector } from 'state/hooks'
import { useGetDepositInfoByIdMutation } from 'state/projectApi/slice'

export default function Confirmation() {
  const open = useLeftModalOpen(ApplicationModal.CONFIRMATION)
  const depositId = useAppSelector((state) => state.home.depositId)
  const propertyId = useAppSelector((state) => state.home.propertyId)
  const toggle = useToggleConfirmationInfo()
  const [depositInfo, setDepositInfo] = useState(null as any)

  const [getDepositInfo] = useGetDepositInfoByIdMutation()
  const addPopup = useAddPopup()

  useEffect(() => {
    if (open && (depositId || propertyId)) {
      let param
      if (depositId) {
        param = { depositId }
      } else if (propertyId) {
        param = { propertyId }
      }
      getDepositInfo(param)
        .then((data: any) => {
          if (data?.data?.code === 0) {
            setDepositInfo(data?.data?.data)
          }
        })
        .catch((e) => {
          console.error(e)
          addPopup({ error: 'Error!' })
        })
    }
  }, [addPopup, depositId, getDepositInfo, open, propertyId])

  return (
    <>
      {open && (
        <S.Panel>
          <S.CloseIcon onClick={toggle}>
            <S.CloseColor />
          </S.CloseIcon>
          <S.Content>
            <S.ConfirmationTitle>Confirmation</S.ConfirmationTitle>
            <S.OverviewTitle>Overview</S.OverviewTitle>
            <S.PropertyPanel>
              <S.PropertyInfoPanel>
                <S.ProInfoPanel>
                  <S.Title>Property Name</S.Title>
                  <S.TextInfo>{depositInfo?.name}</S.TextInfo>
                </S.ProInfoPanel>
                <S.ProInfoPanel>
                  <S.Title>Project Status</S.Title>
                  <S.TextInfo>{GetProjectStatus(depositInfo?.status)}</S.TextInfo>
                </S.ProInfoPanel>
                <S.ProInfoPanel>
                  <S.Title>Project Type</S.Title>
                  <S.TextInfo>{GetProjectType(depositInfo?.type)}</S.TextInfo>
                </S.ProInfoPanel>
                <S.ProInfoPanel>
                  <S.Title>Property size</S.Title>
                  <S.TextInfo>{depositInfo?.totalArea} m²</S.TextInfo>
                </S.ProInfoPanel>
                <S.ProInfoPanel>
                  <S.Title>Deposit Time</S.Title>
                  <S.TextInfo>
                    {depositInfo?.createdAt ? moment(depositInfo?.createdAt).format('DD MMM yyyy') : ''}
                  </S.TextInfo>
                </S.ProInfoPanel>
              </S.PropertyInfoPanel>
              <S.PropertyInfoPanel>
                <S.ProInfoPanel>
                  <S.Title>Bedrooms</S.Title>
                  <S.TextInfo>{depositInfo?.bedRoom}</S.TextInfo>
                </S.ProInfoPanel>
                <S.ProInfoPanel>
                  <S.Title>Bathrooms</S.Title>
                  <S.TextInfo>{depositInfo?.bathRoom}</S.TextInfo>
                </S.ProInfoPanel>
                <S.ProInfoPanel>
                  <S.Title>Car Space</S.Title>
                  <S.TextInfo>{depositInfo?.carParking}</S.TextInfo>
                </S.ProInfoPanel>
                <S.LocationInfoPanel>
                  <S.Title>Location</S.Title>
                  <S.TextInfo>
                    {`Room ${depositInfo?.unitNum}, Level ${depositInfo?.level}, ${depositInfo?.address}, 
${depositInfo?.area}, ${depositInfo?.city}`}
                  </S.TextInfo>
                </S.LocationInfoPanel>
              </S.PropertyInfoPanel>
            </S.PropertyPanel>
            <S.ImageGrid>
              {depositInfo && depositInfo?.images && depositInfo?.images.length > 0 && (
                <S.DepositImage src={depositInfo?.images[0]} alt=""></S.DepositImage>
              )}
              {depositInfo && depositInfo?.floorPlans && depositInfo?.floorPlans.length > 0 && (
                <S.DepositImage src={depositInfo?.floorPlans[0]} alt=""></S.DepositImage>
              )}
            </S.ImageGrid>
            <S.SignPanel>
              <S.SignInfo>
                <S.SignLabel>signed by</S.SignLabel>
                <S.SignImagePanel>
                  <S.DepositImage src={depositInfo?.signed}></S.DepositImage>
                </S.SignImagePanel>
              </S.SignInfo>
              <S.ContractInfo>Contract: {depositInfo?.contract}</S.ContractInfo>
            </S.SignPanel>
          </S.Content>
        </S.Panel>
      )}
    </>
  )
}

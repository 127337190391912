import { useCallback, useRef, useState } from 'react'
import styled from 'styled-components/macro'

const StyledImage = styled.img``

const StyledError = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.bg7};
  height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.text4};
`

export default function ReloadImage({
  src,
  className,
  defaultSrc,
  reloadTimes = 3,
  alt = src
}: {
  src: string
  className?: string
  defaultSrc?: string
  reloadTimes?: number
  alt?: string
}) {
  const [image, setImage] = useState(src)
  const [times, setTimes] = useState(0)
  const imgRef = useRef(null as any)
  const [loadfailed, setLoadFailed] = useState(false)

  const handleError = useCallback(() => {
    setLoadFailed(true)
    if (times < reloadTimes) {
      setTimes(times + 1)
      setTimeout(() => {
        setImage(`${src}?${Date.now()}`)
      }, 500)
    } else {
      if (defaultSrc) {
        setImage(defaultSrc)
      }
      imgRef.current.onError = null
    }
  }, [defaultSrc, reloadTimes, src, times])
  return (
    <>
      {loadfailed ? (
        <StyledError className="reload-image-error"></StyledError>
      ) : (
        <StyledImage
          ref={imgRef}
          className={className}
          src={image}
          onLoad={() => {
            setLoadFailed(false)
          }}
          onError={handleError}
          alt={alt}
        />
      )}
    </>
  )
}

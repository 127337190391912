export enum ProjectStatus {
  NONE = 0,
  OFF_MARKET = 1,
  OFF_THE_PLAN = 2,
  UNDER_CONSTRUCTION = 3,
  COMPLETE = 4
}

export enum ProjectType {
  HOUSE = 1,
  TOWNHOUSE = 2,
  APARTMENT = 3,
  LAND = 4,
  PACKAGE_HOUSE = 4,
  PACKAGE_TOWNHOUSE = 4,
  PACKAGE_APARTMENT = 4,
  VILLA = 4
}

const STAUS_DESC: Record<number, string> = {
  [ProjectStatus.NONE]: 'Unavailable',
  [ProjectStatus.OFF_MARKET]: 'Off Market',
  [ProjectStatus.OFF_THE_PLAN]: 'Off The Plan',
  [ProjectStatus.UNDER_CONSTRUCTION]: 'Under Construction',
  [ProjectStatus.COMPLETE]: 'Complete'
}

const TYPE_DESC: Record<number, string> = {
  [ProjectType.HOUSE]: 'House',
  [ProjectType.TOWNHOUSE]: 'Townhouse',
  [ProjectType.APARTMENT]: 'Apartment',
  [ProjectType.LAND]: 'Land',
  [ProjectType.PACKAGE_HOUSE]: 'Package House',
  [ProjectType.PACKAGE_TOWNHOUSE]: 'Package Townhouse',
  [ProjectType.PACKAGE_APARTMENT]: 'Package Apartment',
  [ProjectType.VILLA]: 'Villa'
}

export const GetProjectStatus = (status: number) => {
  return STAUS_DESC[status] ?? ''
}

export const GetProjectType = (type: number) => {
  return TYPE_DESC[type] ?? ''
}

import MetaMaskSDK from '@metamask/sdk'
import { CommunicationLayerPreference } from '@metamask/sdk-communication-layer'
import { useSpring } from '@react-spring/web'
import ArrowRight from 'assets/images/icon-guide-arrow.png'
import Logo from 'assets/images/logo.png'
import Metamask from 'assets/images/metamask.png'
import ThreeDGallery from 'components/3DGallery'
import ThreeDInfo from 'components/3DInfo'
import ThreeDInfoMobile from 'components/3DInfoMobile'
import { sendEvent } from 'components/Analytics'
import BuyProperty from 'components/BuyProperty/BuyProperty'
import ChatInfoPanel from 'components/ChatInfoPanel/ChatInfoPanel'
import CommentsDetailPanel from 'components/CommentsDetailPanel/CommentsDetailPanel'
import Community from 'components/Community/Community'
import Confirmation from 'components/Confirmation/Confirmation'
import ConnectWalletPanel from 'components/ConnectWalletPanel/ConnectWalletPanel'
import CreatePost from 'components/CreatePost/CreatePost'
import GroundRules from 'components/GroundRulesPanel/GroundRulesPanel'
import InfoPanel from 'components/InfoPanel/InfoPanel'
import MapBox from 'components/MapBox'
import MobileChatInfo from 'components/MobileChatInfo/MobileChatInfo'
import MyCollections from 'components/MyCollections/MyCollections'
import NotificationList from 'components/NotificationList/NotificationList'
import OtherUserInfoPanel from 'components/OtherUserInfoPanel/OtherUserInfoPanel'
import PlacesList from 'components/PlacesList/PlacesList'
import PostImagePanel from 'components/PostImagePanel/PostImagePanel'
import ProfileSetting from 'components/ProfileSetting/ProfileSetting'
import SearchPanel from 'components/SearchPanel/SearchPanel'
import TaskInfo from 'components/TaskInfo/TaskInfo'
import TermsOfUse from 'components/TermsOfUsePanel/TermsOfUse'
import UserMenuPanel from 'components/UserMenuPanel/UserMenuPanel'
import { APP_VERSION } from 'constants/application'
import { CustomizeEvent } from 'constants/events'
import { doc, getDoc, setDoc } from 'firebase/firestore'
// import WalletModal from 'components/WalletModal'
import * as S from 'pages/Home/styles'
import { useCallback, useEffect, useState } from 'react'
import uuid from 'react-uuid'
import { AppState } from 'state'
import { useAddPopup, useModalOpen, useToggleLogin, useTopModalOpen } from 'state/application/hooks'
import { ApplicationModal, setOpenLeftModal, setOpenModal, setShowSign } from 'state/application/reducer'
import { useDailyVisitMutation } from 'state/daoApi/slice'
import { updateChatInfo, updateSelectBuildingName } from 'state/home/reducer'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { updateSelectedWallet, updateUserInfo, updateUserProfile } from 'state/user/reducer'
import {
  useGetProfileMutation,
  useLoginByGuestMutation,
  useLoginBySignedMutation,
  useLogoutMutation
} from 'state/userApi/slice'
import useBus from 'use-bus'
import { dispatch as dispatchEvent } from 'use-bus'
import { db } from 'utils/firestore'
import { isMobileBrowse } from 'utils/userAgent'

export default function Home() {
  const collapseCommunity = useAppSelector((state: AppState) => state.application.collapseCommunity)
  const currentUserInfo = useAppSelector((state) => state.user.userInfo)
  const leftOpenModal = useAppSelector((state: AppState) => state.application.openLeftModal)
  const loginOpenModal = useTopModalOpen(ApplicationModal.LOGIN)
  const showSign = useAppSelector((state: AppState) => state.application.showSign)
  const [metaSdk, setMetaSdk] = useState(null as any)
  const [account, setAccount] = useState('')
  const [controller, setController] = useState(null as any)

  const dispatch = useAppDispatch()
  const addPopup = useAddPopup()

  const [loginBySigned] = useLoginBySignedMutation()
  const [dailyVisit] = useDailyVisitMutation()
  const [loginByGuest] = useLoginByGuestMutation()

  const [logout] = useLogoutMutation()

  const [getProfile] = useGetProfileMutation()

  const toggleLogin = useToggleLogin()
  // const toggleTermOfUse = useToggleTermOfUse()
  // const toggleGroundRules = useToggleGroundRules()
  const openGroundRules = useModalOpen(ApplicationModal.GROUNDRULES)
  const openTermOfUse = useModalOpen(ApplicationModal.TERMOFUSE)
  const [showGuide, setShowGuide] = useState(false)

  useEffect(() => {
    if (currentUserInfo && currentUserInfo?.userId) {
      getDoc(doc(db, 'userChats', currentUserInfo?.userId))
        .then((res) => {
          if (!res.exists()) {
            setDoc(doc(db, 'userChats', currentUserInfo?.userId), {}).catch((e) => {
              console.error(e)
            })
          }
        })
        .catch((e) => {
          console.error(e)
        })

      getDoc(doc(db, 'userNotifications', currentUserInfo?.userId))
        .then((res) => {
          if (!res.exists()) {
            setDoc(doc(db, 'userNotifications', currentUserInfo?.userId), {}).catch((e) => {
              console.error(e)
            })
          }
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }, [currentUserInfo])

  const callDailyVisit = useCallback(
    (userId: string) => {
      dailyVisit(null)
        .then((data: any) => {
          if (data?.data?.code === 0) {
            addPopup({ tip: `Congratulation! Task Reward ${data?.data?.data?.asset?.quantity}` })
          }
        })
        .catch((e) => {
          console.error(e)
        })

      if (userId) {
        getProfile({ userId })
          .then((data: any) => {
            if (data?.data?.code === 0) {
              dispatch(updateUserProfile({ profile: data?.data?.data }))
            } else {
              dispatch(updateUserProfile({ profile: null }))
            }
          })
          .catch((e) => {
            console.error(e)
            dispatch(updateUserProfile({ profile: null }))
          })
      }
    },
    [addPopup, dailyVisit, dispatch, getProfile]
  )

  const loginGuest = useCallback(() => {
    if (controller) {
      controller.abort()
    }
    const temp = new AbortController()
    setController(temp)
    loginByGuest({ token: currentUserInfo?.accessToken, signal: temp.signal })
      .then((response: any) => {
        if (response?.data.code === 0) {
          dispatch(updateUserInfo({ userInfo: { ...response.data.data, account: response.data.data.userId } }))
          dispatch(updateUserProfile({ profile: null }))
          callDailyVisit(response.data.data.userId)

          if (response?.data?.data?.isRegister) {
            setShowGuide(true)
            sendEvent({
              category: 'Login',
              action: 'First-time Guest',
              label: response.data.data.userId
            })
          } else {
            sendEvent({
              category: 'Login',
              action: 'Login as a Guest',
              label: response.data.data.userId
            })
            addPopup({ tip: 'You have successfully logged in!' })
          }
        } else {
          addPopup({ error: 'Login failed. Please retry.' })
          dispatch(updateUserInfo({ userInfo: null }))
          dispatch(updateUserProfile({ profile: null }))
        }
      })
      .catch((e) => {
        console.error(e)
        addPopup({ error: 'Login failed. Please retry.' })
      })
  }, [addPopup, callDailyVisit, controller, currentUserInfo?.accessToken, dispatch, loginByGuest])

  const loginMetamask = useCallback(() => {
    let mmSdk = null
    if (!metaSdk) {
      mmSdk = new MetaMaskSDK({
        useDeeplink: true,
        communicationLayerPreference: CommunicationLayerPreference.SOCKET
      })
      setMetaSdk(mmSdk)
    } else {
      mmSdk = metaSdk
    }

    const ethereum = mmSdk.getProvider()
    if (!account || !ethereum.isConnected()) {
      ethereum
        .request({
          method: 'eth_requestAccounts',
          params: []
        })
        .then((accounts: any) => {
          if (isMobileBrowse()) {
            setAccount(accounts[0].toLowerCase())
            dispatch(setShowSign({ showSign: true }))
          } else {
            const message = `Welcome to YEP!\n\nClick to sign in and accept the YEP Terms of Service: https://yephome.io\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nYour authentication status will reset after 24 hours.\n\nWallet address:${account}\n\nNonce:\n${uuid()}`

            ethereum
              .request({ method: 'personal_sign', params: [accounts[0].toLowerCase(), message] })
              .then((res: any) => {
                dispatch(setShowSign({ showSign: false }))

                if (controller) {
                  controller.abort()
                }
                const temp = new AbortController()
                setController(temp)
                loginBySigned({ userId: accounts[0].toLowerCase(), message, signature: res, signal: temp.signal })
                  .then((response: any) => {
                    if (response?.data.code === 0) {
                      dispatch(updateUserInfo({ userInfo: { ...response.data.data, account } }))
                      dispatch(updateUserProfile({ profile: null }))
                      callDailyVisit(accounts[0].toLowerCase())
                      addPopup({ tip: 'Wallet Connected.' })
                      if (response?.data?.data?.isRegister) {
                        setShowGuide(true)
                        sendEvent({
                          category: 'Login',
                          action: 'First-time Member',
                          label: accounts[0].toLowerCase()
                        })
                      } else {
                        sendEvent({
                          category: 'Login',
                          action: 'Login as a Member',
                          label: accounts[0].toLowerCase()
                        })
                      }
                    } else {
                      addPopup({ error: 'Failed to connect wallet. Please try again.' })
                      if (!currentUserInfo?.isGuest) {
                        dispatch(updateUserInfo({ userInfo: null }))
                        dispatch(updateUserProfile({ profile: null }))
                      }
                    }
                  })
                  .catch((err) => {
                    addPopup({ error: 'Failed to connect wallet. Please try again.' })
                    if (!currentUserInfo?.isGuest) {
                      dispatch(updateUserInfo({ userInfo: null }))
                      dispatch(updateUserProfile({ profile: null }))
                    }
                  })
              })
              .catch((e: any) => {
                console.error(e)
                addPopup({ error: 'Failed to connect wallet. Please try again.' })

                dispatch(setShowSign({ showSign: false }))
                if (!currentUserInfo?.isGuest) {
                  dispatch(updateUserInfo({ userInfo: null }))
                  dispatch(updateUserProfile({ profile: null }))
                }
              })
          }
        })
        .catch((e: any) => {
          console.error('request accounts ERR', e)
          addPopup({ error: 'Failed to connect wallet. Please try again.' })
        })
    } else {
      const message = `Welcome to YEP!\n\nClick to sign in and accept the YEP Terms of Service: https://yephome.io\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nYour authentication status will reset after 24 hours.\n\nWallet address:${account}\n\nNonce:\n${uuid()}`

      ethereum
        .request({ method: 'personal_sign', params: [account, message] })
        .then((res: any) => {
          dispatch(setShowSign({ showSign: false }))

          if (controller) {
            controller.abort()
          }
          const temp = new AbortController()
          setController(temp)
          loginBySigned({ userId: account, message, signature: res, signal: temp.signal })
            .then((response: any) => {
              if (response?.data.code === 0) {
                dispatch(updateUserInfo({ userInfo: { ...response.data.data, account } }))
                dispatch(updateUserProfile({ profile: null }))
                callDailyVisit(account)
                addPopup({ tip: 'Wallet Connected.' })
                if (response?.data?.data?.isRegister) {
                  setShowGuide(true)
                  sendEvent({
                    category: 'Login',
                    action: 'First-time Member',
                    label: account
                  })
                } else {
                  sendEvent({
                    category: 'Login',
                    action: 'Login as a Member',
                    label: account
                  })
                }
              } else {
                addPopup({ error: 'Failed to connect wallet. Please try again.' })

                if (!currentUserInfo?.isGuest) {
                  dispatch(updateUserInfo({ userInfo: null }))
                  dispatch(updateUserProfile({ profile: null }))
                }
              }
            })
            .catch((err) => {
              addPopup({ error: 'Failed to connect wallet. Please try again.' })
              if (!currentUserInfo?.isGuest) {
                dispatch(updateUserInfo({ userInfo: null }))
                dispatch(updateUserProfile({ profile: null }))
              }
            })
        })
        .catch((e: any) => {
          console.error(e)
          addPopup({ error: 'Failed to connect wallet. Please try again.' })

          dispatch(setShowSign({ showSign: false }))
          if (!currentUserInfo?.isGuest) {
            dispatch(updateUserInfo({ userInfo: null }))
            dispatch(updateUserProfile({ profile: null }))
          }
        })
    }
  }, [account, addPopup, callDailyVisit, controller, currentUserInfo?.isGuest, dispatch, loginBySigned, metaSdk])

  useBus(
    CustomizeEvent.LOGIN_WITH_METAMASK,
    () => {
      loginMetamask()
    },
    [metaSdk, account, currentUserInfo]
  )

  useBus(
    CustomizeEvent.LOGIN_RELOGIN,
    () => {
      if (currentUserInfo && currentUserInfo?.expiresIn > new Date().getTime() / 1000) {
        callDailyVisit(currentUserInfo?.userId)
        return
      }
      if (currentUserInfo?.isGuest) {
        loginGuest()
      } else {
        loginMetamask()
      }
    },
    [metaSdk, account, currentUserInfo]
  )

  useEffect(() => {
    if (currentUserInfo && !currentUserInfo.isGuest && !metaSdk) {
      const mmSdk = new MetaMaskSDK({
        useDeeplink: true,
        communicationLayerPreference: CommunicationLayerPreference.SOCKET
      })
      setMetaSdk(mmSdk)
    }
  }, [currentUserInfo, metaSdk])

  useEffect(() => {
    if (currentUserInfo) {
      if (currentUserInfo.expiresIn > new Date().getTime() / 1000) {
        callDailyVisit(currentUserInfo?.userId)
        return
      }
    }

    if (!currentUserInfo) {
      loginGuest()
    } else {
      if (currentUserInfo.isGuest) {
        loginGuest()
      } else {
        loginMetamask()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserInfo, openGroundRules, openTermOfUse])

  // const handleClickTermOfUse = useCallback(() => {
  //   toggleTermOfUse()

  //   if (loginOpenModal) {
  //     toggleLogin()
  //   }
  // }, [loginOpenModal, toggleLogin, toggleTermOfUse])

  // const handleClickGroundRules = useCallback(() => {
  //   toggleGroundRules()
  //   if (loginOpenModal) {
  //     toggleLogin()
  //   }
  // }, [loginOpenModal, toggleGroundRules, toggleLogin])

  const handleAccountsChanged = useCallback(
    (accounts: string[]) => {
      if (
        currentUserInfo &&
        accounts[0] &&
        currentUserInfo?.userId &&
        currentUserInfo?.userId.toLowerCase() !== accounts[0].toLowerCase() &&
        !currentUserInfo?.isGuest
      ) {
        if (isMobileBrowse()) setAccount(accounts[0].toLowerCase())
        loginMetamask()
      }
    },
    [currentUserInfo, loginMetamask]
  )

  useEffect(() => {
    if (metaSdk) {
      const ethereum = metaSdk.getProvider()
      ethereum.on('accountsChanged', handleAccountsChanged)
      return () => {
        ethereum.removeListener('accountsChanged', handleAccountsChanged)
      }
    } else return
  }, [handleAccountsChanged, metaSdk])

  const sliderStyle = useSpring({
    from: { transform: 'translate3d(0%,0,0)' },
    transform: collapseCommunity ? 'translate3d(101.26%,0,0)' : 'translate3d(0%,0,0)',
    config: { duration: 200 }
  })

  const guideStyle = useSpring({
    from: { transform: 'translate3d(0,0%,0)', opacity: 1, display: 'none' },
    transform: showGuide ? 'translate3d(0,0%,0)' : 'translate3d(0,100%,0)',
    display: showGuide ? 'block' : 'none',
    config: { duration: 200 }
  })

  const logoutStyle = useSpring({
    from: { transform: 'translate3d(0,0%,0)', opacity: 1, display: 'none' },
    transform: loginOpenModal ? 'translate3d(0,0%,0)' : 'translate3d(0,100%,0)',
    display: loginOpenModal ? 'block' : 'none',
    config: { duration: 200 }
  })

  const handleLogout = useCallback(() => {
    logout(null).catch((e) => console.error(e))
    dispatch(updateSelectedWallet({ wallet: undefined }))
    dispatch(updateUserInfo({ userInfo: null }))
    dispatch(updateUserProfile({ profile: null }))
    dispatch(setOpenLeftModal(null))
    dispatch(setOpenModal(null))
    dispatch(updateChatInfo({ chatInfo: undefined }))
    dispatch(updateSelectBuildingName({ selectBuildingName: undefined }))
    toggleLogin()
  }, [dispatch, logout, toggleLogin])

  return (
    <S.PageWrapper
      tabIndex={-1}
      onKeyUp={(event: any) => {
        if (event.keyCode === 27) {
          dispatch(setOpenLeftModal(null))
        }

        if (event.keyCode === 37) {
          dispatchEvent(CustomizeEvent.EVENT_ARROW_LEFT)
        }

        if (event.keyCode === 39) {
          dispatchEvent(CustomizeEvent.EVENT_ARROW_RIGHT)
        }
      }}
    >
      {isMobileBrowse() && (
        <S.LandscapeWaring>
          <S.WaringPanel>For a better viewing experience, please rotate your devise.</S.WaringPanel>
        </S.LandscapeWaring>
      )}

      <S.PreLoader style={guideStyle}>
        <S.PreLoaderInner>
          <S.GuidePanel>
            <S.LogoPanel>
              <S.Logo src={Logo} alt={'Logo'}></S.Logo>
            </S.LogoPanel>
            <S.GuideTitle>IT starts here.</S.GuideTitle>
            <S.GudieContent>
              Do you want a place where you can <S.GuideSpan>find rentals, meet new people,</S.GuideSpan> and join great
              <S.GuideSpan> communities?</S.GuideSpan>
            </S.GudieContent>
            <S.StepButtonPanel
              onClick={() => {
                setShowGuide(false)
              }}
            >
              <S.StepButton>
                Then let’s go<S.ArrowIcon src={ArrowRight} alt={'arrow-right'}></S.ArrowIcon>
              </S.StepButton>
            </S.StepButtonPanel>
            <S.FooterPanel>
              {/* <S.FooterTermsLink onClick={handleClickTermOfUse}>Term of use</S.FooterTermsLink>
              <S.FooterGroundLink onClick={handleClickGroundRules}>Ground Rules</S.FooterGroundLink> */}
              <S.FooterBeta>{APP_VERSION}</S.FooterBeta>
            </S.FooterPanel>
          </S.GuidePanel>
        </S.PreLoaderInner>
      </S.PreLoader>
      <S.PreLoader style={logoutStyle}>
        <S.PreLoaderInner>
          <S.LogoutPanel>
            <S.CloseIcon onClick={toggleLogin}>
              <S.CloseColor />
            </S.CloseIcon>
            <S.LogoutTitle>
              Are you sure you want to <S.TitleSpan>log out?</S.TitleSpan>
            </S.LogoutTitle>
            <S.MenuPanel>
              <S.MenuItem>
                <S.ContentPanel>
                  <S.MenuTitle>Log out</S.MenuTitle>
                  <S.MenuContent>
                    Just a heads up, if you log out, you&apos;ll say goodbye to all your history and saved communities.
                    Keep that in mind!
                  </S.MenuContent>
                </S.ContentPanel>
                <S.ContinuteButton onClick={handleLogout}>Continue to log out</S.ContinuteButton>
              </S.MenuItem>
              <S.MenuItem>
                <S.ContentPanel>
                  <S.MenuTitle>Log back in</S.MenuTitle>
                  <S.MenuContent>
                    By logging in with MetaMask, you&apos;ll be able to preserve your settings and favourite
                    communities.
                  </S.MenuContent>
                </S.ContentPanel>
                <S.MetaMaskButton onClick={loginMetamask}>
                  <S.WalletIcon src={Metamask} alt="metamask"></S.WalletIcon>
                  <S.WalletTitle>{showSign ? 'Sign with Metamask' : 'Login with Metamask'}</S.WalletTitle>
                </S.MetaMaskButton>

                <S.ExternalPanel>
                  <S.StyledExternalLink href="https://metamask.io/">
                    What is Metamask and digital wallets?
                  </S.StyledExternalLink>
                </S.ExternalPanel>
              </S.MenuItem>
              <S.MobileExternalPanel>
                <S.StyledExternalLink href="https://metamask.io/">
                  What is Metamask and digital wallets?
                </S.StyledExternalLink>
              </S.MobileExternalPanel>
            </S.MenuPanel>
            <S.FooterPanel hideInSmall={1}>
              {/* <S.FooterTermsLink onClick={handleClickTermOfUse}>Term of use</S.FooterTermsLink>
              <S.FooterGroundLink onClick={handleClickGroundRules}>Ground Rules</S.FooterGroundLink> */}
              <S.FooterBeta>{APP_VERSION}</S.FooterBeta>
            </S.FooterPanel>
          </S.LogoutPanel>
        </S.PreLoaderInner>
      </S.PreLoader>
      <S.MapContainer>
        <MapBox></MapBox>
        {!isMobileBrowse() && <ThreeDInfo></ThreeDInfo>}
        {isMobileBrowse() && <ThreeDInfoMobile></ThreeDInfoMobile>}
        {!showGuide && !loginOpenModal && (
          <S.Container>
            <S.RightContainer>
              <S.AnimationPanel collesap={collapseCommunity ? 1 : 0} style={sliderStyle}>
                {currentUserInfo && currentUserInfo?.userId && <SearchPanel />}
                {currentUserInfo && currentUserInfo?.userId && <PlacesList />}
                {currentUserInfo && currentUserInfo?.userId && <UserMenuPanel />}
                {currentUserInfo && currentUserInfo?.userId && <ProfileSetting />}
                {/* <WalletModal /> */}
                {currentUserInfo && currentUserInfo?.userId && <MyCollections />}
                {currentUserInfo && currentUserInfo?.userId && <Community />}
                {currentUserInfo && currentUserInfo?.userId && currentUserInfo?.isGuest && <ConnectWalletPanel />}
                {currentUserInfo && currentUserInfo?.userId && <NotificationList />}
                <TermsOfUse />
                <GroundRules />
              </S.AnimationPanel>
            </S.RightContainer>
            {leftOpenModal &&
              leftOpenModal !== ApplicationModal.THREED_INFO_MOBILE &&
              leftOpenModal !== ApplicationModal.THREED_INFO && (
                <S.LeftContainer>
                  <PostImagePanel />
                  <CommentsDetailPanel />
                  <CreatePost />
                  <TaskInfo />
                  <InfoPanel />
                  <ThreeDGallery />
                  <BuyProperty />
                  <Confirmation />
                  <OtherUserInfoPanel />
                  <ChatInfoPanel />
                  <MobileChatInfo />
                </S.LeftContainer>
              )}
          </S.Container>
        )}
      </S.MapContainer>
    </S.PageWrapper>
  )
}

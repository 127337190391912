import { ReactComponent as CheckMark } from 'assets/svg/check-mark.svg'
import { ReactComponent as Close } from 'assets/svg/x.svg'
import { RowBetween } from 'components/Row'
import { TextInput } from 'components/TextInput'
import styled from 'styled-components/macro'
import { CommonPanel, PANEL_HEIGHTS } from 'theme'
import { CustomLightSpinner } from 'theme'

export const Panel = styled(CommonPanel)<{ width?: number | undefined }>`
  position: relative;
  margin-right: 1.45vw;
  height: 100%;
  flex: 1;
  height: ${PANEL_HEIGHTS.left};
  iframe {
    pointer-events: auto;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 0px;
  `};
`

export const CloseIcon = styled.div`
  position: absolute;
  top: 1.61vh;
  right: 1.04vw;
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 2.49vh;
    right: 5.38vw;
  `}
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`
export const CloseColor = styled(Close)`
  color: ${({ theme }) => theme.text4};
  width: 0.87vw;
  height: 0.87vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 3.85vw;
    height: 3.85vw;
  `}
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 24px 24px 24px;
  overflow: auto;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
    overflow-x: hidden;
  `}
  /* 滚动槽 */
  ::-webkit-scrollbar {
    width: 0.16vw;
    height: 0.16vw;
  }
  ::-webkit-scrollbar-track {
    border-radius: 0.08vw;
  }
  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    border-radius: 0.08vw;
  }
`

export const OverviewPanel = styled.div`
  border-bottom: 1px solid rgba(222, 222, 222, 0.22);
  width: 100%;
`

export const OverviewTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;

  color: ${({ theme }) => theme.text4};
`

export const OverviewInfo = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
`

export const PropertyGrid = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: auto;
  /* 滚动槽 */
  ::-webkit-scrollbar {
    width: 0.16vw;
    height: 0.16vw;
  }
  ::-webkit-scrollbar-track {
    border-radius: 0.08vw;
  }
  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    border-radius: 0.08vw;
  }
  position: relative;
  ${({ theme }) => theme.mediaWidth.upToSmall`
     //
  `};
`

export const ArrowLeftPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  height: 100%;
  cursor: pointer;
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
     visibility: hidden;
  `};
`

export const ArrowRightPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  height: 100%;
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToSmall`
     visibility: hidden;
  `};
`

export const ArrowIcon = styled.img`
  height: 20px;
  cursor: pointer;
  margin-right: -10px;
`

export const PropertyPanel = styled.div`
  background: rgba(222, 222, 222, 0.06);
  border-radius: 6px;
  padding: 12px;
  margin-left: 18px;
  cursor: pointer;
`

export const Card = styled.div`
  width: 13.5vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 25vw;
  `};
`

export const NoInfo = styled.div`
  color: white;
  margin-top: 25px;
`

export const UnitPanel = styled.div`
  display: flex;
  flex-direction: row;
`

export const UnitText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 21px;

  display: flex;
  align-items: center;
  text-align: center;

  color: rgba(222, 222, 222, 0.4);
`

export const StatusPanel = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const CheckboxPanel = styled.div`
  float: right;
  display: inline-block;
  position: absolute;
  margin-left: 12.5vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-left: 20vw;
  `};
`

export const CheckboxPanelCheckbox = styled.input<{ opacity: string }>`
  width: 15px;
  height: 15px;
  opacity: ${({ opacity }) => opacity};
`

export const StatusDot = styled.div<{ color: string }>`
  width: 5px;
  height: 5px;
  border-radius: 2.5px;

  background: ${({ color }) => color};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-left: -13vw;
    margin-top: 5vh;
  `};
`

export const StatusText = styled.div<{ color: string }>`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;

  display: flex;
  align-items: center;

  color: ${({ color }) => color};
  margin-left: 4px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 5vh;
  `};
`

export const PropertyInfoPanel = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;

  color: rgba(222, 222, 222, 0.4);
  margin-top: 28px;
`

export const PriceInfoPanle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.text4};
`

export const InfoRow = styled.div`
  flex: 1;
`

export const InfoTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: ${({ theme }) => theme.text4};
`

export const InfoContent = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-top: 14px;

  color: rgba(222, 222, 222, 0.6);
`

export const ImageRow = styled.div`
  flex: 2;
  height: 100%;
`

export const InfoPanel = styled.div`
  margin-top: 31px;
  margin-bottom: 12px;
`

export const DepositPanel = styled.div`
  flex: 1;
  padding-top: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 60px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: auto;
  `};
`

export const DepositTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: ${({ theme }) => theme.text4};
`

export const PropertyImage = styled.img`
  width: 100%;
  max-height: 100%;
`

export const PricePanel = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  margin-top: 10px;

  color: ${({ theme }) => theme.primary1};
`

export const DepositInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #161616;
  border: 1px solid rgba(222, 222, 222, 0.22);
  border-radius: 8px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 8px 0px 8px 0px;
`

export const PaymentPanel = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  max-height: 57.33vh;
  margin-top: 1vh;
`

export const PaymentInfo = styled.div`
  flex: 1;
  padding-left: 47px;
  padding-right: 45px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 2vh;
    margin-left: -8vw;
    margin-bottom: 2vh;
  `};
`

export const PaymentInfoCard = styled.div`
  flex: 1;
  padding-left: 47px;
  padding-right: 45px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-left: -85vw;
    margin-top: 15vh;
  `};
`

export const PaymentTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: 22px;

  color: ${({ theme }) => theme.text4};
`

export const PaymentTabs = styled.div`
  display: flex;
  flex-direction: row;
`

export const PaymentTab = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`

export const TabTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding: 0px 8px 6px 8px;

  color: ${({ theme }) => theme.text4};
`

export const ActiveBorder = styled.div`
  height: 4px;
  background: ${({ theme }) => theme.primary1};
  border-radius: 4px;
`

export const CheckoutForm = styled.form`
  background: #161616;
  border: 1px solid rgba(222, 222, 222, 0.22);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 47px 48px 44px 33px;
`

export const InputPanel = styled.div`
  border-bottom: 1px solid rgba(222, 222, 222, 0.22);
`

export const NextInputPanel = styled(InputPanel)`
  margin-top: 42px;
`

export const BuyerInfoInput = styled(TextInput)`
  background: none;
  padding: 14px 0px 6px;
  input {
    background-color: transparent;
    caret-color: #2142ff;
    ::placeholder {
      color: rgba(222, 222, 222, 0.22);
    }
  }
`

export const InputLabel = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: ${({ theme }) => theme.text4};
`

export const AgreePanel = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 47px;
  padding-right: 45px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 5vh;
    margin-left: -11vw;
    width: 200%;
    padding-right: 0px;
    display: block;
  `};
`

export const AgreeInfo = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  flex: 1;

  color: ${({ theme }) => theme.text4};
`

export const CheckoutColor = styled(CheckMark)<{ checked: boolean }>`
  color: ${({ theme, checked }) => (checked ? theme.primary1 : theme.text4)};
  width: 32px;
  height: 32px;
  margin-right: 10px;
  cursor: pointer;
`

export const SubmitPanel = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
    margin-top: -20vh;
    padding-bottom: 10vh;
    width: 40vw;
    margin-left: 30vw;
  `};
`

export const SubmitPayment = styled.button`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-top: 46px;
  background: ${({ theme }) => theme.primary1};
  border: none;
  border-radius: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  padding: 24px 53px 22px 64px;

  color: ${({ theme }) => theme.text4};
`

export const SubmitButton = styled.div`
  background: ${({ theme }) => theme.primary1};
  border-radius: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  padding: 24px 53px 22px 64px;
  margin-top: 15px;

  color: ${({ theme }) => theme.text4};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 25vh;
    margin-left: -20vw;
  `};
`

export const DisableSubmitButton = styled.div`
  background: gray;
  border-radius: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  padding: 24px 53px 22px 64px;
  margin-top: 15px;

  color: ${({ theme }) => theme.text4};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 25vh;
    margin-left: -20vw;
  `};
`

export const CardPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    visibility: hidden;
  `};
`

export const ColumnPanel = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 28px;
`

export const CardTypeImage = styled.img`
  width: 100%;
  max-width: 135px;
`

export const CryptoPanel = styled.div`
  padding-top: 37px;
`

export const CryptoTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;

  color: #dedede;
`

export const CryptoType = styled.div`
  background: rgba(222, 222, 222, 0.06);
  border-radius: 8px;

  margin-top: 17px;
  padding: 4px 23px;
  width: fit-content;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin-bottom: 80px;

  color: #dedede;
`

export const CyptoAddress = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: rgba(222, 222, 222, 0.22);
  margin-top: 14px;
  margin-bottom: 48px;
`

export const AmountPanel = styled(RowBetween)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: rgba(222, 222, 222, 0.22);
`

export const AmountText = styled.div`
  text-align: left;
`

export const EthText = styled.div`
  text-align: right;
`

export const Loading = styled(CustomLightSpinner)`
  width: 2vw;
  height: 2vw;
`

export const SuccessPanel = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(22, 22, 22, 0.22);
  backdrop-filter: blur(6px);
  border-radius: 8px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const SuccessInfo = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  text-transform: uppercase;

  color: #2142ff;
`

export const ViewButton = styled.div`
  background: rgba(222, 222, 222, 0.06);
  border-radius: 8px;
  padding: 3px 38px;
  cursor: pointer;
`

export const ViewText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #dedede;
`

export const PaymentElemnetPanel = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(22, 22, 22, 0.22);
  backdrop-filter: blur(6px);
  border-radius: 8px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

import { useSpring } from '@react-spring/web'
import Metamask from 'assets/images/metamask.png'
import * as S from 'components/ConnectWalletPanel/styles'
import { CustomizeEvent } from 'constants/events'
import { useWindowSize } from 'hooks/useWindowSize'
import { useCallback, useEffect } from 'react'
import { AppState } from 'state'
import { useModalOpen, useWalletModalToggle } from 'state/application/hooks'
import { ApplicationModal, setShowSign } from 'state/application/reducer'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { MEDIA_WIDTHS } from 'theme'
import { dispatch as dispatchEvent } from 'use-bus'

export default function ConnectWalletPanel() {
  const open = useModalOpen(ApplicationModal.WALLET)
  const dispatch = useAppDispatch()

  const showSign = useAppSelector((state: AppState) => state.application.showSign)
  const toggleWallet = useWalletModalToggle()

  const styleA = useSpring({
    from: { transform: 'translate3d(104.39%,0,0)' },
    transform: open ? 'translate3d(0%,0,0)' : 'translate3d(104.39%,0,0)',
    config: { duration: 200 }
  })

  const size = useWindowSize()
  const styleB = useSpring({
    from: { transform: 'translate3d(0,104.39%,0)', opacity: 0 },
    to: { transform: 'translate3d(0,0%,0)', opacity: 1 },
    leave: { transform: 'translate3d(0,104.39%,0)', opacity: 0 },
    config: { duration: 1200 }
  })

  const handleMetamask = useCallback(() => {
    dispatchEvent(CustomizeEvent.LOGIN_WITH_METAMASK)
  }, [])

  useEffect(() => {
    if (!open) {
      if (showSign) {
        dispatch(setShowSign({ showSign: false }))
      }
    }
  }, [open, showSign, dispatch])

  return (
    <>
      {open && (
        <S.Panel style={(size?.width ?? MEDIA_WIDTHS.upToMedium) <= MEDIA_WIDTHS.upToSmall ? styleB : styleA}>
          <S.CloseIcon onClick={toggleWallet}>
            <S.CloseColor />
          </S.CloseIcon>
          <S.PanelTitle>Connect Wallet</S.PanelTitle>
          <S.WalletItem onClick={handleMetamask}>
            <S.WalletIcon src={Metamask} alt="metamask"></S.WalletIcon>
            <S.WalletTitle>{showSign ? 'Sign with Metamask' : 'Metamask'}</S.WalletTitle>
          </S.WalletItem>
        </S.Panel>
      )}
    </>
  )
}

import { useWeb3React } from '@web3-react/core'
import * as S from 'components/MyCollections/styles'
import { useCallback } from 'react'
import { useToggleCommunity } from 'state/application/hooks'
import { updateBuildingId, updateSelectBuildingName } from 'state/home/reducer'
import { useAppDispatch } from 'state/hooks'
import { replaceImageUrl } from 'utils'

export default function CollectionCard({ item }: { item: any }) {
  const toggle = useToggleCommunity()
  const { account } = useWeb3React()

  const dispatch = useAppDispatch()
  const handleCollectionClick = useCallback(() => {
    dispatch(updateBuildingId({ buildingId: item?.buildingId }))
    dispatch(updateSelectBuildingName({ selectBuildingName: item?.name }))
    ;(window as any).openBuildingDaoId = item?.buildingId.toString()
    window.mapbox.flyTo({
      center: [item.longitude, item.latitude],
      essential: true // this animation is considered essential with respect to prefers-reduced-motion
    })
    toggle()
  }, [dispatch, item, toggle])

  return (
    <S.CardPanel onClick={handleCollectionClick}>
      <S.ImagePanel>
        <S.CardImage
          src={item?.images[0] ? replaceImageUrl(item?.images[0], 600) : ''}
          alt={'collecion-image'}
        ></S.CardImage>
        {item?.status === 6 && (
          <S.SoldTip>
            <S.TipContent>Sold</S.TipContent>
          </S.SoldTip>
        )}
        {item?.status === 6 && item?.buyerId === account && (
          <S.DepositedTip>
            <S.TipContent>Deposited</S.TipContent>
          </S.DepositedTip>
        )}
      </S.ImagePanel>
      <S.CardTitle>{item?.name}</S.CardTitle>
      <S.CardSubTitle>
        {(item?.address ?? '') +
          (item?.address && (item?.area || item?.city) ? ', ' : '') +
          (item?.area ?? '') +
          (item?.area && item?.city ? ', ' : '') +
          (item?.city ?? '') +
          ', ' +
          item?.country ?? 'Australia'}
      </S.CardSubTitle>
    </S.CardPanel>
  )
}

import { ReactComponent as Close } from 'assets/svg/x.svg'
import InfiniteScroll from 'react-infinite-scroller'
import styled from 'styled-components/macro'
import { CommonPanel, PANEL_HEIGHTS } from 'theme'

export const MyCollections = styled(CommonPanel)<{ innerheight?: number }>`
  padding: 1.25vh 1.27vw 2.06vh 1.27vw;
  margin-top: 0.54vh;
  position: relative;
  max-height: ${PANEL_HEIGHTS.right};
  ${({ theme, innerheight }) => theme.mediaWidth.upToLarge`
    max-height: ${innerheight ? innerheight * 0.8776 + 'px' : '85.94vh'};
  `};

  ${({ theme, innerheight }) => theme.mediaWidth.upToLarge`
    max-height: ${innerheight ? innerheight * 0.9094 + 'px' : '86.94vh'};
  `};

  ${({ theme, innerheight }) => theme.mediaWidth.upToSmall`
    padding: 1.9vh 4.87vw 1.9vh 5.38vw;
    margin-top: 0.71vh;
    max-height: ${innerheight ? innerheight * 0.8246 + 'px' : PANEL_HEIGHTS.mobile};
    flex: 1;
  `};
`

export const HeaderText = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 1.43vh;
  margin-bottom: 1.52vh;
  cursor: pointer;
  color: ${({ theme }) => theme.text4};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.9vh;
    margin-bottom: 3.55vh;
  `};
`

export const CloseIcon = styled.div`
  position: absolute;
  right: 0.93vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    right: 4.62vw;
  `}
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`
export const CloseColor = styled(Close)`
  color: ${({ theme }) => theme.text4};
  width: 0.87vw;
  height: 0.87vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 3.85vw;
    height: 3.85vw;
  `}
`

export const PostList = styled.div<{ innerheight?: number }>`
  overflow: auto;
  height: 75.76vh;

  ${({ theme, innerheight }) => theme.mediaWidth.upToExtraLarge`
    height: ${innerheight ? innerheight * 0.7986 + 'px' : '78.26vh'};
  `};

  ${({ theme, innerheight }) => theme.mediaWidth.upToLarge`
    height: ${innerheight ? innerheight * 0.8356 + 'px' : '79.26vh'};
  `};

  ${({ theme, innerheight }) => theme.mediaWidth.upToSmall`
    grid-gap: 1.01vh 1.67vw;
    height: ${innerheight ? innerheight * 0.7287 + 'px' : '72.87vh'};
  `}
  /* 滚动槽 */
  ::-webkit-scrollbar {
    width: 0.16vw;
    height: 0.16vw;
  }
  ::-webkit-scrollbar-track {
    border-radius: 0.08vw;
  }
  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    border-radius: 0.08vw;
  }
`

export const ScrollPanel = styled(InfiniteScroll)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-bottom: 8.41vh;
  `};
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: min-content;
  grid-gap: 1.48vh 0.49vw;
  justify-content: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-gap: 1.01vh 1.67vw;
  `}
`

export const CardPanel = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: fit-content;
`

export const ImagePanel = styled.div`
  position: relative;
  height: 17.91vh;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 18.6vh;
  `}
`

export const CardImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 0.36vh;
  object-fit: cover;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    border-radius: 0.47vh;
  `}
`

export const CardTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 1.25vh;
  color: ${({ theme }) => theme.text4};
  margin-top: 1.16vh;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.3vh;
    margin-top: 0.95vh;
  `}
`

export const CardSubTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 0.9vh;
  margin-top: 0.36vh;

  color: rgba(222, 222, 222, 0.6);
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.18vh;
    margin-top: 0.83vh;
  `}
`

export const SoldTip = styled.div`
  display: none;
  position: absolute;
  width: 100%;
  height: 27px;
  bottom: 0px;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.text4};
  background: rgba(22, 22, 22, 0.88);
`

export const DepositedTip = styled.div`
  display: none;
  position: absolute;
  width: 100%;
  height: 27px;
  bottom: 0px;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.text4};
  background: ${({ theme }) => theme.primary1};
`

export const TipContent = styled.div`
  text-align: center;
`

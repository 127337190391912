import { ReactComponent as Close } from 'assets/svg/x.svg'
import { ButtonEmpty } from 'components/Button'
import ReloadImage from 'components/ReloadImage'
import { CommentTextArea } from 'components/TextInput'
import InfiniteScroll from 'react-infinite-scroller'
import styled from 'styled-components/macro'
import { CommonPanel, PANEL_HEIGHTS } from 'theme'

export const Panel = styled(CommonPanel)<{ width?: number | undefined; innerheight?: number }>`
  position: relative;
  margin-right: 1.45vw;
  height: 100%;
  flex: 1;
  height: ${PANEL_HEIGHTS.left};

  ${({ theme, innerheight }) => theme.mediaWidth.upToExtraLarge`
    height: ${innerheight ? innerheight * 0.955 + 'px' : '96.5vh'};
    margin-right: 0.83vw;
  `};

  ${({ theme, innerheight }) => theme.mediaWidth.upToLarge`
    height: ${innerheight ? innerheight * 0.975 + 'px' : '97.5vh'};
    margin-right: 0.83vw;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 0px;
  `};
`

export const CloseIcon = styled.div`
  position: absolute;
  right: 1.04vw;
  top: 1.61vh;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 2.49vh;
    right: 5.38vw;
  `}
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`
export const CloseColor = styled(Close)`
  color: ${({ theme }) => theme.text4};
  width: 0.87vw;
  height: 0.87vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 3.85vw;
    height: 3.85vw;
  `}
`

export const PostContainer = styled.div`
  background: rgba(222, 222, 222, 0.06);
  border-radius: 8px 8px 0px 0px;

  display: flex;
  flex-direction: row;
  padding: 2.69vh 2.37vw 1.34vh 1.91vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `}
`

export const HeaderContainer = styled.div`
  margin-left: 0.52vw;
  flex: 1;
  width: 0;
  position: relative;
  display: flex;
  flex-direction: column;
`

export const HeaderTop = styled.div`
  height: 2.95vh;
  display: flex;
  align-items: center;
`

export const HeaderTime = styled.div`
  margin-left: 0.46vw;
`

export const HeaderContent = styled.div`
  width: 100%;
  font-style: normal;
  font-weight: 300;
  font-size: 1.25vh;
  color: rgba(222, 222, 222, 0.8);
  margin-bottom: 1.07vh;
  word-wrap: break-word;
  p {
    white-space: pre-line;
    margin: 0;
    a {
      color: rgba(222, 222, 222, 0.8);
    }
  }

  pre {
    white-space: pre-line;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.66vh;
  `}
`

export const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-gap: 0.23vw;
  justify-content: center;
  max-width: 100%;
`

export const PostImagePanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .reload-image-error {
    border-radius: 4px;
  }
  &:before {
    content: ' ';
    /* 设为块元素，宽度撑满 */
    display: block;
    background-color: blue;
    /* padding-bottom和padding-top百分比是取的父元素的宽度 */
    padding-bottom: 100%;
  }
`

export const PostImage = styled(ReloadImage)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const Content = styled.div`
  position: relative;

  max-height: 100%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-height: 100%;
  `}
  overflow: auto;
  /* 滚动槽 */
  ::-webkit-scrollbar {
    width: 0;
    height: 0.16vw;
  }
  ::-webkit-scrollbar-track {
    border-radius: 0.08vw;
  }
  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    border-radius: 0.08vw;
  }
`

export const ScrollPanel = styled(InfiniteScroll)`
  margin: 0 3.01vw 1.07vh 1.91vw;
  padding-top: 1.34vh;
  padding-bottom: 14.74vh;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 5.33vh 8.21vw 0 8.21vw;
    padding-top: 0;
    padding-bottom: 7.58vh;
  `}
`

export const GradientPanel = styled.div`
  height: 16.65vh;
  background: linear-gradient(180deg, rgba(22, 22, 22, 0) 0%, #161616 59.46%);
  border-radius: 0px 0px 8px 8px;
  position: absolute;
  bottom: 0;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 6.64vh;
    opacity: 0.6;
  `}
`

export const CommentsPanel = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 4.66vh;
  width: 100%;
  align-items: flex-end;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    bottom: 2.84vh;
  `}
`

export const AvatarPanel = styled.div<{ role: string }>`
  width: 2.95vh;
  height: 2.95vh;
  border-radius: 50%;
  .reload-image-error {
    border-radius: 50%;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 3.91vh;
    height: 3.91vh;
  `}// border: ${({ role, theme }) =>
    role === 'customer'
      ? `2px solid transparent`
      : role === 'developer'
      ? `2px solid ${theme.primary1}`
      : `2px solid ${theme.primary2}`};
`

export const Avatar = styled(ReloadImage)`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`

export const CommentInput = styled(CommentTextArea)`
  flex: 1;
  display: flex;
  margin-left: 1.91vw;
  width: auto;
  align-items: center;
  background: rgba(222, 222, 222, 0.06);
  justify-content: center;

  width: auto;
  align-items: center;
  justify-content: center;
  overflow: auto;
  caret-color: #2142ff;

  max-height: 11.28vh;
  padding: 0.63vh 0.75vw;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-left: 8.46vw;
    padding: 0.83vh 3.08vw;
    border-radius: 1.95vh;
    background: ${theme.bg7};
    max-height: 24.64vh;
    color: ${({ theme }) => theme.text4};
  `}

  textarea {
    background-color: transparent;
    caret-color: #2142ff;
    ::placeholder {
      color: rgba(222, 222, 222, 0.22);
    }
  }

  /* 滚动槽 */
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  ::-webkit-scrollbar-track {
    border-radius: 0.08vw;
  }
  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    border-radius: 0.08vw;
  }
`

export const SendButton = styled.div<{ disabled: boolean }>`
  display: block;
  cursor: pointer;
  font-style: normal;
  font-weight: 300;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25vh;
  line-height: 1.43vh;
  margin-right: 3.01vw;
  margin-left: 2.08vw;
  border-radius: 1.525vh;
  padding: 0.81vh 0.75vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.66vh;
    line-height: 1.9vh;

    margin-right: 8.21vw;
    margin-left: 1.03vw;
    border-radius: 1.9vh;
    padding: 0.95vh 4.36vw;
  `}
  color: ${({ disabled, theme }) => (disabled ? 'rgba(222, 222, 222, 0.22)' : theme.text4)};
  background: ${({ disabled, theme }) => (disabled ? '#232323' : theme.primary1)};
`

export const LevelOnePanel = styled.div`
  margin-bottom: 1.43vh;
  height: fit-content;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-bottom: 3.79vh;
  `}
`

export const LevelTwoPanel = styled.div`
  margin-left: 2.55vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-left: 11.28vw;
  `}
`

export const CommentPanel = styled.div`
  display: flex;
  flex-direction: row;
`

export const CommentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const InfoPanel = styled.div<{ type: number; showBorder: boolean }>`
  flex: 1;
  width: 0;
  padding-bottom: 1.34vh;
  margin-left: 0.52vw;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-bottom: 2.13vh;
    margin-left: 2.82vw;
  `}
  border-bottom: ${({ type, showBorder }) =>
    showBorder ? `1px ${type === 2 ? 'dashed' : 'solid'} rgba(222, 222, 222, 0.22)` : 'none'};
`

export const PostHeader = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 2.95vh;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 3.91vh;
  `}
`

export const AccountName = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 1.07vh;
  color: ${({ theme }) => theme.text4};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.42vh;
  `}
`

export const AccountType = styled.div<{ role: string }>`
  display: none;
  background: ${({ theme }) => theme.primary1};
  border-radius: 8px;
  font-style: normal;
  font-weight: 300;
  font-size: 0.9vh;
  color: ${({ role, theme }) => (role === 'customer' ? 'none' : role === 'developer' ? `${theme.text4}` : '#161616')};
  padding: 0.18vh 0.46vw;
  margin-left: 0.46vw;
  background: ${({ role, theme }) =>
    role === 'customer' ? 'none' : role === 'developer' ? `${theme.primary1}` : `${theme.primary2}`};
  ::first-letter {
    text-transform: uppercase;
  }
`

export const MoreInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`

export const TimeInfo = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 1.07vh;
  color: rgba(222, 222, 222, 0.6);
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.42vh;
  `}
`

export const MoreIcon = styled.div`
  width: 1.04vw;
  height: 1.25vh;
  margin-left: 0.69vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 4.62vw;
    height: 1.66vh;
    margin-left: 3.08vw;
  `}
  cursor: pointer;
  display: flex;
  align-items: center;
  img {
    width: 100%;
  }
`

export const PostContent = styled.div`
  width: 100%;
  max-width: 100%;
  font-style: normal;
  font-weight: 300;
  font-size: 1.25vh;
  color: rgba(222, 222, 222, 0.8);

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.66vh;
  `}
  word-wrap: break-word;
  p {
    margin: 0;
    white-space: pre-line;
    a {
      color: rgba(222, 222, 222, 0.8);
    }
  }
  pre {
    white-space: pre-line;
  }
`

export const ReplyPanel = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2.08vw;
  margin-top: 3.31vh;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `}
`
export const ReplyMobilePanel = styled.div`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
    width: 100%;
    margin-top: 2.01vh;
  `}
`

export const ReplyButtonPanel = styled.div`
  flex: 1;
  display: flex;
`

export const ShowReplyMobile = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 1.66vh;
  text-decoration-line: underline;
  cursor: pointer;
  flex: 4;

  color: rgba(222, 222, 222, 0.6);
`

export const ReplyButton = styled.div`
  width: 3.3vw;
  justify-content: center;
  align-items: center;

  background: rgba(222, 222, 222, 0.06);
  border-radius: 2.69vh;
  text-align: center;
  font-style: normal;
  font-weight: 300;
  font-size: 1.25vh;
  padding: 0.45vh 0.69vw;
  cursor: pointer;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex: 1;
    width: 100%;
    padding: 0.71vh 0;
    border-radius: 0.95vh;
  `}

  color: rgba(222, 222, 222, 0.6);
`

export const ShowReply = styled.div`
  background: rgba(222, 222, 222, 0.06);
  border-radius: 0.72vh;
  font-style: normal;
  font-weight: 300;
  font-size: 1.25vh;
  padding: 0.45vh 0;
  margin-top: 2.06vh;

  color: rgba(222, 222, 222, 0.6);
  text-align: center;
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `}
`

export const DeletaButton = styled(ButtonEmpty)`
  float: right;
  position: absolute;
  width: 6.99vh;
  height: 3vh;
  top: 3.72vh;
  right: 0;
  z-index: 1;
  margin-top: -0.5vh;
  background: #161616;
  border: 1px solid rgba(222, 222, 222, 0.22);
  color: white;
  font-style: normal;
  font-size: 1.43vh;
  font-weight: 400;
  text-decoration: none;
`

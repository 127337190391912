//import { useWeb3React } from '@web3-react/core'
import axios from 'axios'
import * as S from 'components/3DInfoMobile/styles'
import Joystick from 'joystick/joystick'
import { useCallback, useEffect } from 'react'
import { useAddPopup, useLeftModalOpen, useToggle3DGalleryInfo, useToggle3DInfoMobile } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
//import { useGetProjectPropertyVRMutation } from 'state/daoApi/slice'
import { useAppSelector } from 'state/hooks'
import { useGetPropertyVRMutation } from 'state/projectApi/slice'
import TCGSDK from 'tcg-sdk'
//import { setTimeout } from 'timers'

export default function ThreeDInfoMobile() {
  const open = useLeftModalOpen(ApplicationModal.THREED_INFO_MOBILE)
  //const { account } = useWeb3React()
  //const [getPropertyVrTask] = useGetProjectPropertyVRMutation()
  const [getPropertyVr] = useGetPropertyVRMutation()
  const propertyId = useAppSelector((state) => state.home.buildingId)
  const addPopup = useAddPopup()
  const toggle3DInfoMobile = useToggle3DInfoMobile()
  const toggle = useToggle3DGalleryInfo()
  //const [isStart, setIsStart] = useState(false)
  //const [timer, setTimer] = useState(null as any)
  const propertyInfo = useAppSelector((state: { home: { propertyInfo: any } }) => state.home.propertyInfo)

  /*const handleTaskReward = useCallback(() => {
    console.log('handleTaskReward')
    if (open && isStart) {
      console.log('dispatch reward')
      getPropertyVrTask({ propertyId }).then((data: any) => {
        console.log('property', data)
        if (data?.data?.code === 0) {
          if (data?.data?.data?.taskInfo && data?.data?.data?.taskInfo?.executed) {
            //addPopup({ tip: `Congratulation! Task Reward ${data?.data?.data?.taskInfo?.asset?.quantity}` })
          }
        }
      })
    }
  }, [addPopup, getPropertyVrTask, isStart, open, propertyId])*/

  /*useEffect(() => {
    console.log('isStart:', isStart, timer)
    if (isStart && !timer && account) {
      const temp = setTimeout(() => {
        handleTaskReward()
      }, 60000)
      console.log('startTimeout:', timer, temp)
      setTimer(temp)
    } else {
      if (timer) {
        console.log('clearTimeout:', isStart, timer)
        clearTimeout(timer)
        setTimer(null)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleTaskReward, isStart, account])*/

  const StartProject = useCallback(async () => {
    const headers = {
      'x-api-key': process.env.REACT_APP_X_API_KEY ?? ''
    }

    const url = propertyInfo.vrUrls[0].url + '/v1/vr/StartProject' // 后台服务可以参考后台demo 方案
    // 其他选填参数可以参考 ApplyConcurrent 接口文档
    await axios
      .post(
        url,
        {
          ProjectId: propertyInfo.vrUrls[0].projectId,
          UserId: propertyInfo.vrUrls[0].userId,
          ClientSession: TCGSDK.getClientSession()
        },
        {
          headers
        }
      )
      .then((res) => {
        console.log('%c StartProject res', 'color: blue; font-size: 14px', res.data)
        if (res.data.Code === 10200) {
          setTimeout(function () {
            StartProject().catch((e) => {
              console.error(e)
            })
          }, 5000)
          return
        }
        const {
          Code,
          SessionDescribe: { ServerSession }
        } = res.data

        if (Code === 0) {
          TCGSDK.start(ServerSession)
          if ((window as any).mapbox.hasControl((window as any).navigationControl)) {
            ;(window as any).mapbox.removeControl((window as any).navigationControl)
          }
          if ((window as any).mapbox.hasControl((window as any).geoControl)) {
            ;(window as any).mapbox.removeControl((window as any).geoControl)
          }
          //setIsStart(true)
        } else {
          addPopup({ error: 'Error!' })
        }
      })
  }, [addPopup, propertyInfo])

  const StopProject = useCallback(async () => {
    if (propertyInfo === undefined || propertyInfo.vrUrls === undefined) {
      return
    }
    const url = propertyInfo.vrUrls[0].url + '/v1/vr/StopProject' // 后台服务可以参考后台demo 方案
    const headers = {
      'x-api-key': process.env.REACT_APP_X_API_KEY ?? ''
    }
    // 其他选填参数可以参考 ApplyConcurrent 接口文档
    await axios.post(
      url,
      {
        ProjectId: propertyInfo.vrUrls[0].projectId,
        UserId: propertyInfo.vrUrls[0].userId
      },
      {
        headers
      }
    )
  }, [propertyInfo])

  useEffect(() => {
    if (open && propertyId) {
      getPropertyVr({ propertyId }).then((data: any) => {
        console.log('property', data)
        if (data?.data?.code === 0) {
          TCGSDK.init({
            appid: 1314418507,
            mount: 'mount-panel',
            debugSetting: {
              showLog: true
            },
            clientInteractMode: 'touch',
            loadingText: 'Yephome property is loading ...',
            autoRotateContainer: false,
            //autoRotateContainer: false,
            tabletMode: true,
            mobileGame: true,
            //forceShowCursor: true,
            // 连接成功回调
            onConnectSuccess: async (res) => {
              new Joystick({
                zone: document.querySelector('#right-joystick') as Element,
                position: { right: 50, bottom: 50 },
                type: 'dpad_updown'
              })

              new Joystick({
                zone: document.querySelector('#left-joystick') as Element,
                position: { left: 50, bottom: 50 },
                type: 'dpad_wasd',
                joystickImage: {
                  back: 'images/joystick.png'
                }
              })
            },
            // 网络中断/被踢触发此回调
            onDisconnect: (res) => {
              console.log('onDisconnect', res)
            },
            onWebrtcStatusChange: (res) => {
              console.log('onWebrtcStatusChange', res)
            },
            onInitSuccess: async (res) => {
              await StartProject().catch((e) => {
                console.error(e)
              })
            }
          })
        } else {
          //;(window as any).document.querySelector('#left-joystick')?.hide()
          //;(window as any).document.querySelector('#right-joystick')?.hide()
          addPopup({ error: 'Error!' })
        }
      })
    } else {
      if (!(window as any).mapbox.hasControl((window as any).geoControl)) {
        ;(window as any).mapbox.addControl((window as any).geoControl, 'bottom-left')
      }
      if (!(window as any).mapbox.hasControl((window as any).navigationControl)) {
        ;(window as any).mapbox.addControl((window as any).navigationControl, 'bottom-left')
      }
      //setIsStart(false)
      StopProject().catch((e) => {
        console.error(e)
      })
      //;(window as any).document.querySelector('#left-joystick')?.hide()
      //;(window as any).document.querySelector('#right-joystick')?.hide()
    }

    return () => {
      TCGSDK.destroy()
    }
  }, [StartProject, StopProject, propertyInfo, addPopup, getPropertyVr, open, propertyId])
  return (
    <S.MobilePanel visible={open}>
      <S.FullScreen
        onClick={() => {
          if (TCGSDK.getPageOrientation() === 'portrait') {
            TCGSDK.setVideoOrientation({ deg: 90, rotateContainer: true })
          } else {
            TCGSDK.setVideoOrientation({ deg: 0, rotateContainer: false })
          }
        }}
      >
        <img width="35px" height="35px" src="images/fullscreen.png" alt="Close" />
      </S.FullScreen>

      <S.Close
        onClick={() => {
          StopProject().catch((e) => {
            console.error(e)
          })
          toggle3DInfoMobile()
          toggle()
          if (TCGSDK.getPageOrientation() === 'portrait') {
            TCGSDK.setVideoOrientation({ deg: 0, rotateContainer: true })
          }
          //GeolocateControl
          //NavigationControl
        }}
      >
        <img width="35px" height="35px" src="images/close.png" alt="Close" />
      </S.Close>
      <div id="left-joystick"></div>
      <div id="right-joystick"></div>
      <div id="mount-panel"></div>
    </S.MobilePanel>
  )
}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { CHAIN_API_URL, SupportedChainId } from 'constants/chains'
import store, { customValidateStatus } from 'state'
import { ResponseBackend } from 'state/userApi/types'

function getBaseUri(): any {
  const chainId = store?.getState()?.application?.chainId
    ? store?.getState()?.application?.chainId
    : SupportedChainId.MAINNET

  const apiUrl = chainId ? CHAIN_API_URL[chainId] : process.env.REACT_APP_API_GATEWAY
  return apiUrl
}

export const payApi = createApi({
  reducerPath: 'payApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUri(),
    prepareHeaders: (headers) => {
      const { user } = store?.getState()
      if (user && user.userInfo && user.userInfo.accessToken) {
        headers.set('Authorization', `Bearer ${user.userInfo.accessToken}`)
      }
      headers.set('x-api-key', 'AM2Ag22KKSasPLXpgIxyR5SGOek1ftXr49YqB2m3')
      return headers
    },
    fetchFn: (...args) => {
      // if (window.AwsWafIntegration) {
      //   console.log('use AwsWafIntegration')
      //   return window.AwsWafIntegration.fetch(...args)
      // } else {
      return fetch(...args)
      // }
    }
  }),
  endpoints: (build) => ({
    payOrder: build.mutation<
      ResponseBackend,
      {
        productId: string
        daoId: string
        client: any
        products: []
        due: string
        verifyCard: boolean
        successRedirect: string
        failureRedirect: string
        cancelRedirect: string
        paymentMethod: string
      }
    >({
      query: (body: any) => ({
        url: `/v1/pay/order`,
        method: 'POST',
        body,
        validateStatus: customValidateStatus
      })
    }),
    payCryptoOrder: build.mutation<
      ResponseBackend,
      {
        productId: string
        daoId: string
        originalPrice: number
      }
    >({
      query: (body: any) => ({
        url: `/v1/pay/cryptoOrder`,
        method: 'POST',
        body,
        validateStatus: customValidateStatus
      })
    }),
    cancelOrder: build.mutation<
      ResponseBackend,
      {
        orderId: string
      }
    >({
      query: (body: any) => ({
        url: `/v1/pay/cancelOrder`,
        method: 'POST',
        body,
        validateStatus: customValidateStatus
      })
    }),
    checkOrderStatus: build.mutation<
      ResponseBackend,
      {
        orderId: string
      }
    >({
      query: (body: any) => ({
        url: `/v1/pay/checkOrderStatus`,
        method: 'POST',
        body,
        validateStatus: customValidateStatus
      })
    }),
    getPayOrders: build.mutation<any, any>({
      query: (params: any) => ({
        url: `/v1/pay/orders`,
        method: 'GET',
        params,
        validateStatus: customValidateStatus
      })
    }),
    checkSalesQty: build.mutation<any, any>({
      query: (params: any) => ({
        url: `/v1/pay/checkSalesQty`,
        method: 'GET',
        params,
        validateStatus: customValidateStatus
      })
    }),
    getEthPrice: build.mutation<any, any>({
      query: (params: any) => ({
        url: `/v1/pay/getEthPrice`,
        method: 'GET',
        params,
        validateStatus: customValidateStatus
      })
    }),
    addPendingTx: build.mutation<any, any>({
      query: (params: any) => ({
        url: `/v1/pay/addPendingTx`,
        method: 'GET',
        params,
        validateStatus: customValidateStatus
      })
    })
  })
})

export const {
  usePayOrderMutation,
  usePayCryptoOrderMutation,
  useCancelOrderMutation,
  useCheckOrderStatusMutation,
  useGetPayOrdersMutation,
  useGetEthPriceMutation,
  useCheckSalesQtyMutation,
  useAddPendingTxMutation
} = payApi

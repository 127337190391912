// import * as AWS from '@aws-sdk/client-wafv2'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { CHAIN_API_URL, SupportedChainId } from 'constants/chains'
import store, { customValidateStatus } from 'state'
import { ResponseBackend } from 'state/userApi/types'

function getBaseUri(): any {
  const chainId = store?.getState()?.application?.chainId
    ? store?.getState()?.application?.chainId
    : SupportedChainId.MAINNET

  const apiUrl = chainId ? CHAIN_API_URL[chainId] : process.env.REACT_APP_API_GATEWAY
  return apiUrl
}

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUri(),
    prepareHeaders: (headers) => {
      const { user } = store?.getState()
      if (user && user.userInfo && user.userInfo.accessToken) {
        headers.set('Authorization', `Bearer ${user.userInfo.accessToken}`)
      }
      headers.set('x-api-key', 'AM2Ag22KKSasPLXpgIxyR5SGOek1ftXr49YqB2m3')
      return headers
    },
    fetchFn: (...args) => {
      // if (window.AwsWafIntegration) {
      //   console.log('use AwsWafIntegration')
      //   return window.AwsWafIntegration.fetch(...args)
      // } else {
      return fetch(...args)
      // }
    }
  }),
  endpoints: (build) => ({
    loginBySigned: build.mutation<
      ResponseBackend,
      {
        userId: string
        message: string
        signature: string
        signal: any
      }
    >({
      query: (body: any) => ({
        url: `/v1/user/loginBySign`,
        method: 'POST',
        body,
        signal: body.signal,
        validateStatus: customValidateStatus
      })
    }),
    loginByGuest: build.mutation<
      ResponseBackend,
      {
        token?: string
        signal?: any
      }
    >({
      query: (body: any) => ({
        url: `/v1/user/loginByGuest`,
        method: 'POST',
        body,
        signal: body.signal,
        validateStatus: customValidateStatus
      })
    }),
    logout: build.mutation<ResponseBackend, null>({
      query: () => ({
        url: `/v1/user/logout`,
        method: 'GET',
        validateStatus: customValidateStatus
      })
    }),
    updateProfile: build.mutation<
      ResponseBackend,
      {
        userId: string
        avatar: string
        nickName: string
        gender?: string
        address?: string
        profile?: string
        birthday?: string
        role: string
      }
    >({
      query: (body: any) => ({
        url: `/v1/user/profile/update`,
        method: 'POST',
        body,
        validateStatus: customValidateStatus
      })
    }),
    getProfile: build.mutation<any, any>({
      query: (params: any) => ({
        url: `/v1/user/profile`,
        method: 'GET',
        params,
        validateStatus: customValidateStatus
      })
    }),
    getStoreConfig: build.mutation<any, any>({
      query: (params: any) => ({
        url: `/v1/user/store/config`,
        method: 'GET',
        params,
        validateStatus: customValidateStatus
      })
    })
  })
})

export const {
  useLoginBySignedMutation,
  useLoginByGuestMutation,
  useLogoutMutation,
  useUpdateProfileMutation,
  useGetProfileMutation,
  useGetStoreConfigMutation
} = userApi

import Image from 'assets/images/icon-image.png'
import Message from 'components/ChatInfoPanel/Message'
import * as S from 'components/ChatInfoPanel/styles'
import { arrayUnion, doc, onSnapshot, serverTimestamp, Timestamp, updateDoc } from 'firebase/firestore'
import { useCallback, useEffect, useState } from 'react'
import ReactS3Client from 'react-aws-s3-typescript'
import { FileUploader } from 'react-drag-drop-files'
import uuid from 'react-uuid'
import { useAddPopup, useLeftModalOpen } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { useAppSelector } from 'state/hooks'
import { useGetStoreConfigMutation } from 'state/userApi/slice'
import { db } from 'utils/firestore'

export default function Messages() {
  const open = useLeftModalOpen(ApplicationModal.CHATINFO)
  const chatInfo = useAppSelector((state) => state.home.chatInfo)
  const otherUserId = useAppSelector((state) => state.home.otherUserId)
  const currentUserInfo = useAppSelector((state) => state.user.userInfo)
  const [messages, setMessages] = useState([] as any[])
  const [sendMessage, setSendMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const addPopup = useAddPopup()
  const fileTypes = ['png', 'jpg', 'jpeg', 'webp', 'avif']
  const [currentChat, setCurrentChat] = useState(null as any)

  const [storeConfig, setStoreConfig] = useState(null as any)
  const [getStoreConfig] = useGetStoreConfigMutation()

  useEffect(() => {
    if (chatInfo && chatInfo?.chatId !== currentChat?.chatId) {
      setMessages([])
      setCurrentChat(chatInfo)
    }
  }, [chatInfo, currentChat?.chatId])

  useEffect(() => {
    if (!open) {
      setMessages([])
      setCurrentChat(null)
    }
  }, [open])

  useEffect(() => {
    getStoreConfig({ appName: 'yephome' })
      .then((data: any) => {
        setStoreConfig(data?.data?.data)
      })
      .catch((e) => {
        console.error(e)
      })
  }, [getStoreConfig])

  useEffect(() => {
    if (chatInfo && chatInfo?.chatId) {
      const unChatsSub = onSnapshot(doc(db as any, 'chats', chatInfo?.chatId), (doc) => {
        doc.exists() && setMessages(doc.data()?.messages)
      })

      return () => {
        setMessages([])
        unChatsSub()
      }
    } else {
      return
    }
  }, [chatInfo])

  const handleSubmitMessage = useCallback(() => {
    setLoading(true)
    if (currentUserInfo?.userId && chatInfo) {
      updateDoc(doc(db, 'chats', chatInfo?.chatId), {
        messages: arrayUnion({
          id: uuid(),
          text: sendMessage,
          senderId: currentUserInfo?.userId,
          date: Timestamp.now()
        }),
        hasUnread: true
      })
        .then(() => {
          const textMsg = sendMessage
          setSendMessage('')
          updateDoc(doc(db, 'userChats', currentUserInfo?.userId), {
            [chatInfo?.chatId + '.lastMessage']: {
              text: textMsg
            },
            [chatInfo?.chatId + '.date']: serverTimestamp()
          }).catch((e) => {
            console.error('1', e)
          })

          updateDoc(doc(db, 'userChats', otherUserId), {
            [chatInfo?.chatId + '.lastMessage']: {
              text: textMsg
            },
            [chatInfo?.chatId + '.date']: serverTimestamp(),
            [chatInfo?.chatId + '.hasUnread']: true
          }).catch((e) => {
            console.error('2', e)
          })

          setLoading(false)
        })
        .catch((e) => {
          setLoading(false)
          console.error(e)
        })
    }
  }, [chatInfo, currentUserInfo?.userId, otherUserId, sendMessage])

  const handleSubmitImage = useCallback(
    (file: any) => {
      if (currentUserInfo?.userId && chatInfo) {
        setLoading(true)
        const s3 = new ReactS3Client({
          bucketName: storeConfig.bucketName,
          dirName: 'chats/' + chatInfo?.chatId,
          region: storeConfig.region,
          accessKeyId: storeConfig.accessId,
          secretAccessKey: storeConfig.accessKey
        })

        s3.uploadFile(file, (currentUserInfo?.userId ?? '') + new Date().valueOf())
          .then((res) => {
            updateDoc(doc(db, 'chats', chatInfo?.chatId), {
              messages: arrayUnion({
                id: uuid(),
                image: res.location,
                senderId: currentUserInfo?.userId,
                date: Timestamp.now()
              })
            })
              .then(() => {
                updateDoc(doc(db, 'userChats', currentUserInfo?.userId), {
                  [chatInfo?.chatId + '.lastMessage']: {
                    text: '[image]'
                  },
                  [chatInfo?.chatId + '.date']: serverTimestamp()
                }).then(() => {
                  updateDoc(doc(db, 'userChats', otherUserId), {
                    [chatInfo?.chatId + '.lastMessage']: {
                      text: '[image]'
                    },
                    [chatInfo?.chatId + '.date']: serverTimestamp(),
                    [chatInfo?.chatId + '.hasUnread']: true
                  })
                })

                setLoading(false)
              })
              .catch((e) => {
                setLoading(false)
                console.error(e)
              })
          })
          .catch((e) => {
            addPopup({ error: 'The chat image was unsuccessful. Please try again.' })
          })
      }
    },
    [
      addPopup,
      chatInfo,
      currentUserInfo?.userId,
      otherUserId,
      storeConfig?.accessId,
      storeConfig?.accessKey,
      storeConfig?.bucketName,
      storeConfig?.region
    ]
  )

  return (
    <S.MessagesPanel>
      <S.MessagesContent>
        {messages.map((m, index) => {
          let showHeader = false
          if (index === 0 || m.senderId !== messages[index - 1].senderId) {
            showHeader = true
          }
          return <Message message={m} key={m.id} showHeader={showHeader}></Message>
        })}
      </S.MessagesContent>
      <S.InputPanel>
        <FileUploader handleChange={handleSubmitImage} name="file" types={fileTypes} maxSize={1024}>
          <S.InputImagePanel>
            <S.InputImage src={Image} alt={'icon-image'}></S.InputImage>
          </S.InputImagePanel>
        </FileUploader>
        <S.CommentInput
          resize={true}
          value={sendMessage}
          onUserInput={function (value: string): void {
            if (value.length > 2000) {
              addPopup({ error: "Message can't exceed 2000 characters" })
              value = value.substring(0, 2000)
            }
            setSendMessage(value)
          }}
          placeholder={'Write a comment here'}
          onKeyDown={(e: any) => {
            if (e.keyCode === 13 && e.shiftKey === true) {
              e.preventDefault()
              setSendMessage(sendMessage + '\n')
              return false
            } else if (e.keyCode === 13) {
              e.preventDefault()
              handleSubmitMessage()
              return false
            }
            return true
          }}
        ></S.CommentInput>
        <S.SendButton onClick={handleSubmitMessage} disabled={sendMessage.trim().length === 0 || loading}>
          Send
        </S.SendButton>
      </S.InputPanel>
    </S.MessagesPanel>
  )
}

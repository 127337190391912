import { parse } from 'qs'
import { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useToggleCommunity } from 'state/application/hooks'
import { updateMapLocation } from 'state/application/reducer'
import { updateBuildingId, updateSelectBuildingName } from 'state/home/reducer'
import { useAppDispatch } from 'state/hooks'
import { useGetBuildingInfoMutation } from 'state/projectApi/slice'

export default function BuildingQueryParamReader({ location: { search } }: RouteComponentProps): null {
  const dispatch = useAppDispatch()
  const toggle = useToggleCommunity()

  const [getBuildingInfo] = useGetBuildingInfoMutation()

  useEffect(() => {
    if (!search) return
    if (search.length < 2) return

    const parsed = parse(search, {
      parseArrays: false,
      ignoreQueryPrefix: true
    })

    const community = parsed.community

    if (typeof community !== 'string') return

    const splitarr = community?.split(';')
    if (splitarr.length < 2) return
    const buildingId = splitarr[1]
    getBuildingInfo({ buildingId })
      .then((data: any) => {
        if (data?.data?.code === 0 && data?.data?.data) {
          dispatch(
            updateMapLocation({
              lat: data?.data?.data?.latitude,
              lng: data?.data?.data?.longitude,
              building: data?.data?.data
            })
          )
          if (buildingId !== null && buildingId !== undefined && buildingId !== '0') {
            dispatch(updateBuildingId({ buildingId }))
            dispatch(updateSelectBuildingName({ selectBuildingName: data?.data?.data?.name }))
            toggle()
          }
          ;(window as any).openBuildingDaoId = buildingId.toString()
          window.mapbox.flyTo({
            center: [data?.data?.data?.longitude, data?.data?.data?.latitude],
            essential: true // this animation is considered essential with respect to prefers-reduced-motion
          })
        }
      })
      .catch((e) => {
        console.error(e)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getBuildingInfo, search])

  return null
}

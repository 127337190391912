import '@reach/dialog/styles.css'
import 'polyfills'
import 'components/Analytics'
import '@fontsource/roboto-flex'
import '@fontsource/roboto-flex/variable-full.css'

import Blocklist from 'components/Blocklist'
import Web3Provider from 'components/Web3Provider'
import { LanguageProvider } from 'i18n'
import { BlockNumberProvider } from 'lib/hooks/useBlockNumber'
import { MulticallUpdater } from 'lib/state/multicall'
import App from 'pages/App'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
// import { IntercomProvider } from 'react-use-intercom'
import * as serviceWorkerRegistration from 'serviceWorkerRegistration'
import store from 'state'
import ApplicationUpdater from 'state/application/updater'
import LogsUpdater from 'state/logs/updater'
import TransactionUpdater from 'state/transactions/updater'
import UserUpdater from 'state/user/updater'
import ThemeProvider, { ThemedGlobalStyle } from 'theme'

if (!!window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false
}

function Updaters() {
  return (
    <>
      <UserUpdater />
      <ApplicationUpdater />
      <TransactionUpdater />
      <MulticallUpdater />
      <LogsUpdater />
    </>
  )
}

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <LanguageProvider>
          <Web3Provider>
            <Blocklist>
              <BlockNumberProvider>
                <Updaters />
                <ThemeProvider>
                  <ThemedGlobalStyle />
                  {/* <IntercomProvider appId={process.env.REACT_APP_INTERCOM_ID ?? 'ynw14r8c'}> */}
                  <App />
                  {/* </IntercomProvider> */}
                </ThemeProvider>
              </BlockNumberProvider>
            </Blocklist>
          </Web3Provider>
        </LanguageProvider>
      </BrowserRouter>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
)

if (process.env.REACT_APP_SERVICE_WORKER !== 'false') {
  serviceWorkerRegistration.register()
}

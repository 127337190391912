import { ReactComponent as Close } from 'assets/svg/x.svg'
import { ButtonSecondary } from 'components/Button'
import ReloadImage from 'components/ReloadImage'
import { RowBetween } from 'components/Row'
import { TextInput } from 'components/TextInput'
import styled from 'styled-components/macro'
import { CommonPanel } from 'theme'

export const SearchPanel = styled(CommonPanel)`
  width: 100%;
  position: relative;
`
export const Logo = styled.img`
  height: 2.51vh;

  ${({ theme }) => theme.mediaWidth.upToSmall`
     height: 3.2vh;
  `};
`

export const Header = styled(RowBetween)`
  padding: 1.61vh 1.16vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 2.13vh 5.13vw;
  `};
`

export const AccountStatus = styled(ButtonSecondary)`
  ${({ theme }) => theme.flexRowNoWrap}
  width: fit-content;
  align-items: center;
  height: fit-content;
  padding: 0rem;
  border-radius: 2.69vh;
  cursor: pointer;
  user-select: none;
  color: #dedede;
  :focus {
    outline: none;
  }
  margin-right: -0.95vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    border-radius: 3.55vh;
    margin-right: -4.23vw;
  `};

  background-color: ${({ theme }) => theme.bg1};
  border: none;
  color: ${({ theme }) => theme.text1};
  font-weight: 300;
  &:hover,
  &:focus {
    border: none;
    box-shadow: none;
  }
`

export const AccountText = styled.p`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0.18vh 1.5vw 0.18vh 0.58vw;
  padding: 0;
  font-size: 1.07vh;
  width: fit-content;
  font-weight: 300;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0.24vh 6.67vw 0.24vh 2.56vw;
    font-size: 1.42vh;
  `};
`

export const AccountIconWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  z-index: 1;
  -webkit-transform: translateZ(1px);
  -moz-transform: translateZ(1px);
  -o-transform: translateZ(1px);
  transform: translateZ(1px);
  .reload-image-error {
    border-radius: 50%;
  }

  & > * {
    height: 2.95vh;
    width: 2.95vh;

    ${({ theme }) => theme.mediaWidth.upToSmall`
      height: 3.91vh;
      width: 3.91vh;
    `};
  }
`

export const AvatarImage = styled(ReloadImage)`
  border-radius: 50%;
  object-fit: cover;
`

export const NotifyPanel = styled.div`
  width: 0.89vh;
  height: 0.89vh;
  border-radius: 50%;
  background: ${({ theme }) => theme.primary1};
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 2;
  -webkit-transform: translateZ(1px);
  -moz-transform: translateZ(1px);
  -o-transform: translateZ(1px);
  transform: translateZ(1px);
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 1.18vh;
    height: 1.18vh;
  `};
`

export const Div = styled.div`
  height: 1px;
  margin: 0 1.16vw 0 1.39vw;
  background-color: rgba(222, 222, 222, 0.22);
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0 5.13vw;
  `};
`

export const SearchColumn = styled(RowBetween)`
  padding: 1.61vh 1.16vw;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 1.9vh 5.13vw;
  `};
`

export const SearchIcon = styled.img`
  height: 1.79vh;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 2.37vh;
  `};
`

export const SearchInput = styled(TextInput)`
  width: 100%;
  margin-left: 0.93vw;
  background: none;
  input {
    font-weight: 300;
    font-size: 1.43vh;
    color: ${({ theme }) => theme.text4};
    ${({ theme }) => theme.mediaWidth.upToSmall`
      font-size: 1.9vh;
    `};
    background-color: transparent;
    caret-color: #2142ff;
    ::placeholder {
      color: rgba(222, 222, 222, 0.22);
    }
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-left: 2.31vw;
  `};
`

export const CloseIcon = styled.div`
  position: absolute;
  right: 1.33vw;
  width: 0.87vw;
  height: 0.87vw;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    right: 1.33vw;
    width: 1.46vw;
    height: 1.46vw;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    right: 1.33vw;
    width: 1.46vw;
    height: 1.46vw;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    right: 5.9vw;
    width: 3.85vw;
    height: 3.85vw;
  `};
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`
export const CloseColor = styled(Close)`
  color: ${({ theme }) => theme.text4};
`

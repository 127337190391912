import { animated } from '@react-spring/web'
import { ReactComponent as Close } from 'assets/svg/x.svg'
import styled from 'styled-components/macro'
import { ExternalLink, PANEL_HEIGHTS, PANEL_WIDTHS, Z_INDEX } from 'theme'

export const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
`

export const LandscapeWaring = styled.div`
  display: none;

  color: #dedede;
  @media screen and (orientation: landscape) and (max-device-height: 767px) {
    display: flex;
    background: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(3.5px);
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    z-index: ${Z_INDEX.popover};
  }

  @media screen and (orientation: portrait) and (min-device-width: 767px) {
    display: flex;
    background: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(3.5px);
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    z-index: ${Z_INDEX.popover};
  }
`

export const WaringPanel = styled.div`
  border-radius: 20px;
  padding: 11.19vh 9.14vw;
`

export const PreLoader = styled(animated.div)`
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: ${Z_INDEX.preloader};
  overflow: hidden;
`

export const PreLoaderInner = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const GuidePanel = styled.div`
  background: #000000;

  border-radius: 1.79vh;
  padding: 3.76vh 3.94vw 1.97vh 3.94vw;
  width: 52.66vw;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    border-radius: 2.37vh;
    width: 82.05vw;
    padding: 2.61vh 4.87vw 2.96vh 4.87vw;
  `};
`

export const LogoPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.86vh;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 3.79vh;
  `};
`

export const Logo = styled.img`
  height: 100%;
`

export const GuideTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 8.59vh;
  line-height: 10.03vh;
  margin-top: 8.33vh;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 7.58vh;
    line-height: 95.19%;
  `};

  text-align: center;
  text-transform: uppercase;

  color: ${({ theme }) => theme.text4};
`

export const GudieContent = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 2.86vh;
  margin-top: 3.13vh;
  padding-left: 1.27vw;
  padding-right: 1.27vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 2.49vh;
    margin-top: 4.62vh;
    padding-left: 10.26vw;
    padding-right: 10.26vw;
  `};

  text-align: center;
  text-transform: uppercase;

  color: ${({ theme }) => theme.text4};
`

export const GuideSpan = styled.span`
  font-weight: 600;
  font-style: normal;
`

export const StepButtonPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.primary1};
  border-radius: 8px;
  margin-top: 13.07vh;
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 8.41vh;
  `};
`

export const StepButton = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 2.15vh;
  line-height: 2.51vh;
  text-transform: uppercase;
  padding: 2.33vh 0 2.86vh 0;
  text-align: center;

  color: ${({ theme }) => theme.text4};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.9vh;
    line-height: 2.25vh;
    padding: 3.55vh 0;
  `};
`

export const ArrowIcon = styled.img`
  height: 1.52vh;
  margin-left: 1.16vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 1.07vh;
    margin-left: 3.08vw;
  `};
`

export const LogoutPanel = styled.div`
  background: #000000;
  position: relative;

  border-radius: 1.79vh;
  padding: 5.28vh 0.52vw 1.43vh 0.41vw;
  width: 48.03vw;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    border-radius: 2.37vh;
    width: 82.05vw;
    padding: 3.32vh 5.13vw 3.32vh 4.87vw;
  `};
`

export const CloseIcon = styled.div`
  position: absolute;
  top: 1.7vh;
  right: 1.27vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 1.9vh;
    right: 4.36vw;
  `};
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`
export const CloseColor = styled(Close)`
  color: ${({ theme }) => theme.text4};
  width: 0.87vw;
  height: 0.87vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 3.85vw;
    height: 3.85vw;
  `};
`

export const LogoutTitle = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 4.3vh;
  line-height: 5.01vh;
  text-align: center;
  text-transform: uppercase;
  max-width: 35.76vw;
  margin: auto;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 2.84vh;
    line-height: 3.32vh;
    max-width: 65.38vw;
  `};

  color: ${({ theme }) => theme.text4};
`

export const TitleSpan = styled.span`
  font-weight: 700;
`

export const MenuPanel = styled.div`
  display: flex;
  margin-top: 8.5vh;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
    margin-top: 0;
    padding-top: 1.07vh;
  `};
`

export const MenuItem = styled.div`
  background: rgba(22, 22, 22, 0.8);
  position: relative;
  flex: 1;
  border-radius: 0.72vh;
  padding: 1.7vh 2.54vw 1.16vh 2.54vw;

  margin-left: 1.16vw;
  margin-right: 1.16vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 2.96vh;
    margin-left: 0;
    margin-right: 0;
    padding: 1.9vh 4.62vw 2.84vh 4.62vw;
  `};
`

export const ContentPanel = styled.div`
  flex: 1;
`

export const MenuTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 1.79vh;
  line-height: 2.06vh;
  text-align: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 2.37vh;
    line-height: 2.73vh;
  `};

  color: ${({ theme }) => theme.primary1};
`

export const MenuContent = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 1.43vh;
  line-height: 2.06vh;
  margin-top: 0.71vh;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.54vh;
    line-height: 2.13vh;
    margin-top: 1.3vh;
  `};
  text-align: center;

  color: ${({ theme }) => theme.text1};
`

export const ContinuteButton = styled.div`
  border: 1px solid rgba(222, 222, 222, 0.44);
  border-radius: 0.72vh;
  font-style: normal;
  font-weight: 400;
  font-size: 1.43vh;
  line-height: 1.7vh;
  padding: 1.97vh 0;
  margin-top: 4.03vh;
  margin-bottom: 3.76vh;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.66vh;
    line-height: 1.9vh;
    padding: 1.9vh 7.69vw 2.13vh 7.95vw;
    margin: auto;
    margin-top: 4.5vh;
    margin-bottom: 0;
    border-radius: 0.72vh;
    width: fit-content;
  `};

  color: rgba(222, 222, 222, 0.44);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`

export const MetaMaskButton = styled.div`
  background: ${({ theme }) => theme.bg7};
  border-radius: 0.72vh;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25vh;
  line-height: 1.43vh;
  padding: 1.25vh 0;
  margin-top: 3.58vh;
  margin-bottom: 1.7vh;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.66vh;
    line-height: 1.9vh;
    padding: 0.83vh 3.07vw;
    margin: auto;
    margin-top: 4.5vh;
    margin-bottom: 0;
    border-radius: 0.72vh;
    width: fit-content;
  `};

  color: ${({ theme }) => theme.text4};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`

export const WalletIcon = styled.img`
  height: 3.22vh;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 4.27vh;
  `};
`

export const WalletTitle = styled.div`
  margin-left: 0.46vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-left: 2.05vw;
  `};
`

export const ExternalPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

export const MobileExternalPanel = styled.div`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.18vh;
  `};
`

export const StyledExternalLink = styled(ExternalLink)`
  font-style: normal;
  font-weight: 300;
  font-size: 1.07vh;
  line-height: 2.06vh;
  text-align: center;
  text-decoration-line: underline;
  width: 100%;
  text-align: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.42vh;
    line-height: 2.73vh;
  `};

  color: ${({ theme }) => theme.text4};
`

export const FooterPanel = styled.div<{ hideInSmall?: number }>`
  display: flex;
  flex: 1;
  margin-top: 6.98vh;
  align-items: center;
  justify-content: center;

  font-style: normal;
  font-weight: 300;
  font-size: 1.07vh;
  width: 100%;
  text-align: center;

  color: #dedede;
  ${({ theme, hideInSmall }) => theme.mediaWidth.upToSmall`
    ${hideInSmall ? 'display: none;' : 'display: flex;'}
    margin-top: 4.5vh;
    font-size: 1.42vh;
    white-space: nowrap;
  `};
`

export const FooterBeta = styled.div`
  // margin-left: 2.26vw;
  font-size: 0.9vh;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    // margin-left: 10vw;
    font-size: 1.18vh;
  `};
`

export const FooterTermsLink = styled.div`
  text-decoration-line: underline;
  cursor: pointer;
`

export const FooterGroundLink = styled.div`
  text-decoration-line: underline;
  cursor: pointer;
  margin-left: 1.56vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-left: 6.92vw;
  `};
`

export const MapContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const Container = styled.div`
  position: fixed;
  top: 2.69vh;
  right: 1.56vw;
  display: flex;
  align-items: stretch;
  height: auto;
  direction: rtl;
  z-index: 3;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    top: 1.54vh;
    right: 1.32vw;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    top: 1.25vh;
    right: 0.83vw;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    top: 1.54vh;
    left: 3.33vw;
    right: 3.33vw;
  `};
`

export const RightContainer = styled.div`
  direction: ltr;
  height: 100%;
  position: relative;
  width: ${PANEL_WIDTHS.large};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};
`

export const AnimationPanel = styled(animated.div)<{ collesap: number }>`
  max-height: ${PANEL_HEIGHTS.left};
  width: ${PANEL_WIDTHS.large};
  display: flex;
  flex-direction: column;
  position: ${({ collesap }) => (collesap ? 'fixed' : 'relative')};
  ${({ theme }) => theme.mediaWidth.upToLarge`
    max-height: 97.5vh;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    height: 100%;
  `};
`

export const LeftContainer = styled.div`
  flex: 1;
  flex-grow: 1;
  direction: ltr;
  align-items: center;
  justify-content: center;
  width: ${PANEL_WIDTHS.left};
  height: fit-content;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    height: 97.5vh;
    width: 71.32vw;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};
`

import { useSpring } from '@react-spring/web'
import Guest from 'assets/images/icon-guest.png'
import { sendEvent } from 'components/Analytics'
import * as S from 'components/CreatePost/styles'
import { CustomizeEvent } from 'constants/events'
import { useWindowSize } from 'hooks/useWindowSize'
import { useCallback, useEffect, useMemo, useState } from 'react'
import ReactS3Client from 'react-aws-s3-typescript'
import { useAddPopup, useLeftModalOpen, useTogglePostCreate } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { useAppSelector } from 'state/hooks'
import { usePublishPostMutation } from 'state/postApi/slice'
import { useGetStoreConfigMutation } from 'state/userApi/slice'
import { MEDIA_WIDTHS } from 'theme'
import useBus, { dispatch as dispatchEvent } from 'use-bus'
import { replaceImageUrl } from 'utils'

import PostImage from './PostImage'

export default function CreatePost() {
  const currentUserInfo = useAppSelector((state) => state.user.userInfo)
  const open = useLeftModalOpen(ApplicationModal.POST_CREATE)
  const toggle = useTogglePostCreate()
  const [canPost, setCanPost] = useState(false)
  const [content, setContent] = useState('')
  const images = new Array(9).fill('')
  const [files, setFiles] = useState([])
  const [imageUrls, setImageUrls] = useState([] as string[])
  const [publishPost] = usePublishPostMutation()
  const [getStoreConfig] = useGetStoreConfigMutation()
  const [storeConfig, setStoreConfig] = useState(null as any)
  const daoId = useAppSelector((state) => state.home.daoId)
  const userProfile = useAppSelector((state) => state.user.userProfile)
  const [uploading, setUploading] = useState(false)

  const addPopup = useAddPopup()

  useEffect(() => {
    if (open) {
      setContent('')
      getStoreConfig({ appName: 'yephome' })
        .then((data: any) => {
          setStoreConfig(data?.data?.data)
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }, [getStoreConfig, open])

  useEffect(() => {
    if ((content.length > 0 && content.trim().length > 0) || files.length > 0) {
      setCanPost(true)
    } else {
      setCanPost(false)
    }
  }, [content, files])

  const handlePostImage = useCallback(
    (file: any, index: number) => {
      setFiles(files.concat(file))
    },
    [files]
  )
  const reset = useCallback(() => {
    setFiles([])
    setContent('')
    setImageUrls([])
    setUploading(false)
    setCanPost(false)
  }, [])
  useEffect(() => {
    if (!open) {
      reset()
    }
  }, [open, reset])
  const publish = useCallback(
    (uploaded?: string[]) => {
      if (content.length > 0) {
        sendEvent({
          category: 'Post',
          action: 'Post Content',
          label: daoId,
          value: content.length
        })
      }
      if (imageUrls.length > 0) {
        sendEvent({
          category: 'Post',
          action: 'Post Pictures',
          label: daoId,
          value: imageUrls.length
        })
      }
      publishPost({ daoId: daoId ?? '', content, images: uploaded ?? imageUrls })
        .then((data: any) => {
          if (data?.data?.code === 0) {
            if (data?.data?.data?.dailyTaskResp && data?.data?.data?.dailyTaskResp?.executed) {
              addPopup({ tip: `Congratulation! Task Reward ${data?.data?.data?.dailyTaskResp?.asset?.quantity}` })
            } else {
              addPopup({ tip: 'Your post was successfully submitted!' })
            }

            sendEvent({
              category: 'Post',
              action: 'Post Success',
              label: daoId
            })
            dispatchEvent(CustomizeEvent.REFRESH_POSTS)
            toggle()
          } else {
            if (data?.error?.status === 429) {
              addPopup({ error: "You're posting too quickly. Please wait a few minutes and try again." })
            } else {
              addPopup({ error: 'The post was unsuccessful. Please try again.' })
            }
            sendEvent({
              category: 'Post',
              action: 'Post Unsuccess',
              label: daoId
            })
            setCanPost(true)
          }
          setUploading(false)
        })
        .catch((e) => {
          console.error('teee', e)
          setUploading(false)
          setCanPost(true)
          addPopup({ error: 'The post was unsuccessful. Please try again.' })
          sendEvent({
            category: 'Post',
            action: 'Post Unsuccess',
            label: daoId
          })
        })
    },
    [addPopup, content, daoId, imageUrls, publishPost, toggle]
  )

  const handlePostSubmit = useCallback(() => {
    if (!canPost) {
      return
    }
    if (!daoId) {
      addPopup({ error: 'The community is no longer active.' })
      return
    }
    if (uploading) return
    if (files.length > 0) {
      setUploading(true)
      setCanPost(false)
      const s3 = new ReactS3Client({
        bucketName: storeConfig.bucketName,
        dirName: 'posts' /* Optional */,
        region: storeConfig.region,
        accessKeyId: storeConfig.accessId,
        secretAccessKey: storeConfig.accessKey
      })
      const uploaded = new Array(files.length).fill('')
      let uploadedLength = 0
      files.map((file, index) => {
        return s3
          .uploadFile(file, (currentUserInfo?.userId ?? '') + index + new Date().valueOf())
          .then((res) => {
            uploaded[index] = res.location
            uploadedLength++
            if (uploadedLength === files.length) {
              setImageUrls(uploaded)
              publish(uploaded)
            }
          })
          .catch((e) => {
            addPopup({ error: 'The post was unsuccessful. Please try again.' })

            sendEvent({
              category: 'Post',
              action: 'Post Unsuccess',
              label: daoId
            })
            setUploading(false)
            setCanPost(true)
          })
      })
    } else {
      if (content.length > 0 && content.trim().length > 0) {
        setUploading(true)
        setCanPost(false)
        publish()
      }
    }
  }, [
    currentUserInfo?.userId,
    canPost,
    addPopup,
    content,
    daoId,
    files,
    publish,
    storeConfig?.accessId,
    storeConfig?.accessKey,
    storeConfig?.bucketName,
    storeConfig?.region,
    uploading
  ])

  useBus(
    CustomizeEvent.EVENT_POST_RATE_LIMIT,
    () => {
      console.log('EVENT_POST_RATE_LIMIT')
      addPopup({ error: "You're posting too quickly. Please wait a few minutes and try again." })
    },
    []
  )

  const size = useWindowSize()
  const isMobile = (size?.width ?? MEDIA_WIDTHS.upToMedium) <= MEDIA_WIDTHS.upToSmall
  const style = useSpring({
    from: { opacity: 0, display: 'none', transform: isMobile ? 'translate3d(-100%,0,0)' : 'translate3d(-72.98vw,0,0)' },
    opacity: open ? 1 : 0,
    display: open ? 'flex' : 'none',
    transform: open ? 'translate3d(0%,0,0)' : isMobile ? 'translate3d(-100%,0,0)' : 'translate3d(-72.98vw,0,0)',
    config: { duration: 200 }
  })
  const innerHeight = useMemo(() => {
    return size.clientHeight ? size.clientHeight : 0
  }, [size])

  return (
    <S.Panel style={style} innerheight={innerHeight}>
      <S.CloseIcon onClick={toggle}>
        <S.CloseColor />
      </S.CloseIcon>
      <S.Content>
        <S.InfoPanel>
          <S.AvatarPanel>
            <S.Avatar
              src={userProfile?.avatar ? replaceImageUrl(userProfile?.avatar, 200) : Guest}
              alt={'post create avatar'}
            ></S.Avatar>
          </S.AvatarPanel>
          <S.PostInfoPanel>
            <S.PostInput
              resize={false}
              value={content}
              onUserInput={(value: string) => {
                setContent(value)
              }}
              placeholder={'Write something here'}
            ></S.PostInput>
          </S.PostInfoPanel>
        </S.InfoPanel>
        <S.InfoPanel style={{ flex: 1 }}>
          <S.PostImagesPanel>
            {images.map((item, index) => {
              return (
                <PostImage
                  key={index}
                  url={files[index] ? URL.createObjectURL(files[index]) : ''}
                  isUpload={index === files.length}
                  handleChange={(file: any) => {
                    handlePostImage(file, index)
                  }}
                  handleDelete={() => {
                    setFiles(files.slice(0, index).concat(files.slice(index + 1, files.length)))
                  }}
                ></PostImage>
              )
            })}
          </S.PostImagesPanel>
        </S.InfoPanel>
        <S.PostButton onClick={handlePostSubmit} disabled={!canPost} posting={uploading}>
          {uploading ? 'Posting......' : 'Post'}
        </S.PostButton>
      </S.Content>
    </S.Panel>
  )
}

import { useSpring } from '@react-spring/web'
import * as S from 'components/3DGallery/styles'
import { ButtonEmpty } from 'components/Button'
import { useWindowSize } from 'hooks/useWindowSize'
import { useEffect, useMemo, useState } from 'react'
import {
  useAddPopup,
  useLeftModalOpen,
  useToggle3DGalleryInfo,
  useToggle3DInfo,
  useToggle3DInfoMobile
} from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { updateBuildingInfo, updatePropertyInfo } from 'state/home/reducer'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { useGetBuildingPropertyMutation } from 'state/projectApi/slice'
import { MEDIA_WIDTHS } from 'theme'
import { isMobileBrowse } from 'utils/userAgent'

export default function ThreeDGallery() {
  const open = useLeftModalOpen(ApplicationModal.THREED_GALLERY_INFO)
  const buildingInfo = useAppSelector((state: { home: { buildingInfo: any } }) => state.home.buildingInfo)
  const [properties, setProperties] = useState([] as any[])
  const toggle = useToggle3DGalleryInfo()
  const dispatch = useAppDispatch()
  const addPopup = useAddPopup()
  const toggle3DInfo = useToggle3DInfo()
  const toggle3DInfoMobile = useToggle3DInfoMobile()
  const [getBuildingProperty] = useGetBuildingPropertyMutation()
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
  })

  const getStatusText = (statusId: number) => {
    switch (statusId) {
      case 0:
        return 'Unavailable'
      case 1:
        return 'Available'
      case 2:
        return 'EOI'
      case 3:
        return 'Reserved'
      case 4:
        return 'Exchange Conditional'
      case 5:
        return 'Unconditional'
      case 6:
        return 'Sold'
      default:
        return 'Available'
    }
  }

  const getStatusColor = (statusId: number) => {
    switch (statusId) {
      case 0:
        return 'orange'
      case 1:
        return '#74FF17'
      case 2:
        return 'orange'
      case 3:
        return 'orange'
      case 4:
        return 'orange'
      case 5:
        return 'orange'
      case 6:
        return 'grey'
      default:
        return 'Available'
    }
  }

  useEffect(() => {
    if (open && buildingInfo && buildingInfo.id) {
      document.title = 'Yephome - Properties List Of ' + buildingInfo.name
      getBuildingProperty({ buildingId: buildingInfo.id })
        .then((data: any) => {
          setProperties(data.data.data.data)
        })
        .catch((e) => {
          addPopup({ error: 'Error!' })
        })
    }
  }, [addPopup, buildingInfo, getBuildingProperty, open])

  const size = useWindowSize()
  const isMobile = (size?.width ?? MEDIA_WIDTHS.upToMedium) <= MEDIA_WIDTHS.upToSmall
  const style = useSpring({
    from: {
      opacity: 0,
      display: 'none',
      transform: isMobile ? 'translate3d(-100%,0,0)' : 'translate3d(-72.98vw,0,0)'
    },
    opacity: open ? 1 : 0,
    display: open ? 'flex' : 'none',
    transform: open ? 'translate3d(0,0,0)' : isMobile ? 'translate3d(-100%,0,0)' : 'translate3d(-72.98vw,0,0)',
    config: { duration: 200 }
  })
  const innerHeight = useMemo(() => {
    return size.clientHeight ? size.clientHeight - 46 : 0
  }, [size])
  return (
    <S.Panel style={style} innerheight={innerHeight}>
      <S.CloseIcon onClick={toggle}>
        <S.CloseColor />
      </S.CloseIcon>
      <S.GalleryPanel innerheight={innerHeight}>
        {properties.map((item, index) => {
          return (
            <S.GalleryImageBox key={index}>
              <S.GalleryImageBoxHeader>
                <S.GalleryImageBoxHeaderUnit>{item.unitNum}</S.GalleryImageBoxHeaderUnit>
                <S.GalleryImageBoxHeaderStatus statusColor={getStatusColor(item.status)}>
                  • {getStatusText(item.status)}
                </S.GalleryImageBoxHeaderStatus>
              </S.GalleryImageBoxHeader>
              <S.GalleryImageBoxContent>
                <img
                  alt="3D render"
                  onClick={() => {
                    dispatch(updateBuildingInfo({ buildingInfo }))
                  }}
                  style={{
                    maxWidth: '18.35vw',
                    maxHeight: '20.35vh',
                    cursor: 'pointer'
                  }}
                  src={
                    item.floorPlans.length > 0
                      ? item.floorPlans[0]
                      : 'https://web3-resources-dev.s3.ap-southeast-2.amazonaws.com/resource.project/1/property/floorplan.jpg'
                  }
                />
              </S.GalleryImageBoxContent>
              <S.GalleryImageBoxFooter>
                <S.GalleryImageBoxFooterSubtitle>
                  {item.bedRoom} Bed/ {item.bathRoom} Bath / {item.carParking} Car
                </S.GalleryImageBoxFooterSubtitle>
                <S.GalleryImageBoxDesc>
                  <S.GalleryImageBoxFooterPrice>{formatter.format(item.price)}</S.GalleryImageBoxFooterPrice>
                  <S.GalleryImageBoxFooterRight>
                    {item.vrUrls !== null && item.vrUrls !== '' && item.vrUrls.length > 0 && item.vrUrls[0] !== '' && (
                      <ButtonEmpty
                        style={{
                          float: 'right',
                          width: '9.99vh',
                          height: '3vh',
                          background: '#161616',
                          border: '1px solid rgba(222, 222, 222, 0.22)',
                          color: 'white',
                          fontFamily: 'Roboto FlexVariable',
                          fontStyle: 'normal',
                          fontSize: '16px',
                          textDecoration: 'none',
                          paddingLeft: '2px',
                          paddingRight: '2px'
                        }}
                        color="#DEDEDE"
                        $borderRadius="4px"
                        fontWeight={400}
                        onClick={() => {
                          const propertyInfo = item
                          dispatch(updateBuildingInfo({ buildingInfo }))
                          dispatch(updatePropertyInfo({ propertyInfo }))
                          if (isMobileBrowse()) {
                            //https://ex.cloud-gaming.myqcloud.com/cloud_gaming_web/demo/index.html?type=application&env=intl
                            /*window.open(
                              'https://ex.cloud-gaming.myqcloud.com/cloud_gaming_web/demo/index.html?&env=intl&code=EG1VBOFR'
                            )*/
                            toggle3DInfoMobile()
                          } else {
                            toggle3DInfo()
                          }
                        }}
                      >
                        View VR
                      </ButtonEmpty>
                    )}
                  </S.GalleryImageBoxFooterRight>
                </S.GalleryImageBoxDesc>
              </S.GalleryImageBoxFooter>
            </S.GalleryImageBox>
          )
        })}
      </S.GalleryPanel>
    </S.Panel>
  )
}

import { getDoc } from '@firebase/firestore'
import { useSpring } from '@react-spring/web'
import Guest from 'assets/images/icon-guest.png'
import * as S from 'components/OtherUserInfoPanel/styles'
import { doc, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore'
import { useWindowSize } from 'hooks/useWindowSize'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  useLeftModalOpen,
  useToggleChatInfo,
  useToggleMobileChatInfo,
  useToggleOtherUser
} from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { updateChatInfo, updateOtherId } from 'state/home/reducer'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { useGetProfileMutation } from 'state/userApi/slice'
import { MEDIA_WIDTHS } from 'theme'
import { replaceImageUrl } from 'utils'
import { db } from 'utils/firestore'

export default function OtherUserInfoPanel() {
  const open = useLeftModalOpen(ApplicationModal.OTHERUSER)
  const dispatch = useAppDispatch()
  const toggle = useToggleOtherUser()
  const toggleChatInfo = useToggleChatInfo()
  const toggleMobileChatInfo = useToggleMobileChatInfo()
  const otherUserId = useAppSelector((state) => state.home.otherUserId)
  const currentUserInfo = useAppSelector((state) => state.user.userInfo)
  const currentUserProfile = useAppSelector((state) => state.user.userProfile)
  const [otherUserInfo, setOtherUserInfo] = useState(null as any)

  const [getProfile] = useGetProfileMutation()

  useEffect(() => {
    if (!open) {
      setOtherUserInfo(null)
    }
  }, [open])

  const size = useWindowSize()
  const isMobile = (size?.width ?? MEDIA_WIDTHS.upToMedium) <= MEDIA_WIDTHS.upToSmall
  const style = useSpring({
    from: { opacity: 0, display: 'none', transform: isMobile ? 'translate3d(-100%,0,0)' : 'translate3d(-72.98vw,0,0)' },
    opacity: open ? 1 : 0,
    display: open ? 'flex' : 'none',
    transform: open ? 'translate3d(0%,0,0)' : isMobile ? 'translate3d(-100%,0,0)' : 'translate3d(-72.98vw,0,0)',
    config: { duration: 200 }
  })

  const innerHeight = useMemo(() => {
    return size.clientHeight ? size.clientHeight : 0
  }, [size])

  useEffect(() => {
    if (otherUserId) {
      getProfile({ userId: otherUserId })
        .then((data: any) => {
          setOtherUserInfo(data?.data?.data)
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }, [getProfile, otherUserId])

  const avatar = useMemo(() => {
    return otherUserInfo?.avatar ? replaceImageUrl(otherUserInfo?.avatar, 200) : Guest
  }, [otherUserInfo?.avatar])

  const handleMessage = useCallback(() => {
    if (currentUserInfo?.userId) {
      const combinedId =
        currentUserInfo?.userId > otherUserId
          ? currentUserInfo?.userId + otherUserId
          : otherUserId + currentUserInfo?.userId
      getDoc(doc(db as any, 'chats', combinedId))
        .then((res) => {
          if (!res.exists()) {
            //create a chat in chats collection
            setDoc(doc(db as any, 'chats', combinedId), { messages: [] }).then(async () => {
              await updateDoc(doc(db as any, 'userChats', currentUserInfo?.userId), {
                [combinedId + '.userInfo']: {
                  uid: otherUserId,
                  displayName: otherUserInfo?.nickName,
                  photoURL: otherUserInfo?.avatar
                },
                [combinedId + '.date']: serverTimestamp()
              })

              await updateDoc(doc(db as any, 'userChats', otherUserId), {
                [combinedId + '.userInfo']: {
                  uid: currentUserInfo?.userId,
                  displayName: currentUserProfile?.nickName,
                  photoURL: currentUserProfile?.avatar
                },
                [combinedId + '.date']: serverTimestamp()
              })
              dispatch(
                updateChatInfo({
                  chatInfo: {
                    chatId: combinedId,
                    user: {
                      uid: otherUserId,
                      displayName: otherUserInfo?.nickName,
                      photoURL: otherUserInfo?.avatar
                    }
                  }
                })
              )
              if (isMobile) {
                if (currentUserInfo?.userId) {
                  updateDoc(doc(db, 'userChats', currentUserInfo?.userId), {
                    [combinedId + '.hasUnread']: false
                  })
                }

                dispatch(updateOtherId({ userId: otherUserId }))
                toggleMobileChatInfo()
              } else {
                toggleChatInfo()
              }
            })
          } else {
            dispatch(
              updateChatInfo({
                chatInfo: {
                  chatId: combinedId,
                  user: {
                    uid: otherUserId,
                    displayName: otherUserInfo?.nickName,
                    photoURL: otherUserInfo?.avatar
                  }
                }
              })
            )
            if (isMobile) {
              if (currentUserInfo?.userId) {
                updateDoc(doc(db, 'userChats', currentUserInfo?.userId), {
                  [combinedId + '.hasUnread']: false
                })
              }

              dispatch(updateOtherId({ userId: otherUserId }))
              toggleMobileChatInfo()
            } else {
              toggleChatInfo()
            }
          }
        })
        .catch((e) => {
          console.error(e)
          dispatch(
            updateChatInfo({
              chatId: combinedId,
              user: {
                uid: otherUserId,
                displayName: otherUserInfo?.nickName,
                photoURL: otherUserInfo?.avatar
              }
            })
          )
          toggleChatInfo()
        })
    }
  }, [
    currentUserInfo?.userId,
    otherUserId,
    otherUserInfo?.nickName,
    otherUserInfo?.avatar,
    currentUserProfile?.nickName,
    currentUserProfile?.avatar,
    dispatch,
    isMobile,
    toggleMobileChatInfo,
    toggleChatInfo
  ])

  return (
    <S.Panel style={style} innerheight={innerHeight}>
      <S.CloseIcon onClick={toggle}>
        <S.CloseColor />
      </S.CloseIcon>
      <S.Content>
        <S.AvatarPanel>
          <S.Avatar src={avatar} alt="post avatar"></S.Avatar>
        </S.AvatarPanel>
        <S.UserInfoPanel>
          <S.NickNamePanel>{otherUserInfo?.nickName}</S.NickNamePanel>
          <S.AddressPanel>{otherUserInfo?.userId}</S.AddressPanel>
          {otherUserInfo && <S.MessageButton onClick={handleMessage}>Message</S.MessageButton>}
        </S.UserInfoPanel>
      </S.Content>
    </S.Panel>
  )
}

export const CustomizeEvent = {
  REFRESH_COMMENT: '@@refresh/comments/',
  REFRESH_REPLIES: '@@refresh/replies/',
  REFRESH_PAYMENT: '@@refresh/paymentform',
  REFRESH_POSTS: '@@refresh/posts',
  LOGIN_WITH_METAMASK: '@@login/metamask',
  LOGIN_DONE: '@@login/done',
  LOGIN_RELOGIN: '@@login/relogin',
  EVENT_ARROW_LEFT: '@@event/arrowleft',
  EVENT_ARROW_RIGHT: '@@event/arrowright',
  EVENT_POST_RATE_LIMIT: '@@event/postratelimit',
  EVENT_COMMENT_RATE_LIMIT: '@@event/commentratelimit'
}

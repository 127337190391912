import { useSpring } from '@react-spring/web'
import * as S from 'components/MyCollections/styles'
import { useWindowSize } from 'hooks/useWindowSize'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useModalOpen, useToggleMenu, useToggleMyCollection } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { useGetCollectionListMutation } from 'state/projectApi/slice'

import CollectionCard from './CollectionCard'

export default function MyCollections() {
  const open = useModalOpen(ApplicationModal.MY_COLLECTION)
  const toggle = useToggleMyCollection()
  const toggleMenu = useToggleMenu()
  const pageSize = 20
  const [current, setCurrent] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [collections, setCollections] = useState([])
  const throttling = useRef(false)

  const [getCollectionList] = useGetCollectionListMutation()

  const size = useWindowSize()
  const innerHeight = useMemo(() => {
    return size.clientHeight ? size.clientHeight - 46 : 0
  }, [size])

  const getCollectionListCall = useCallback(
    (current) => {
      getCollectionList({ current, pageSize })
        .then((data: any) => {
          if (data?.data?.code === 0) {
            if (data?.data?.data?.count < pageSize) {
              setHasMore(false)
            }
            if (current === 1) {
              setCollections([].concat(data?.data?.data?.data))
            } else {
              setCollections(collections.concat(data?.data?.data?.data))
            }
          }

          throttling.current = false
        })
        .catch((e) => {
          console.error(e)
          throttling.current = false
        })
    },
    [collections, getCollectionList]
  )

  useEffect(() => {
    if (open) {
      setCurrent(1)
      setHasMore(true)
      getCollectionListCall(1)
    }
    return () => {
      setCurrent(1)
      setHasMore(false)
      setCollections([])
    }
    // don't add collections, it will occur circle call
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const { transform } = useSpring({
    from: { transform: 'translate3d(104.39%,0,0)' },
    transform: open ? 'translate3d(0%,0,0)' : 'translate3d(104.39%,0,0)',
    config: { duration: 200 }
  })

  return (
    <>
      {open && (
        <S.MyCollections style={{ transform }} innerheight={innerHeight}>
          <S.CloseIcon
            onClick={() => {
              if (open) {
                toggle()
                toggleMenu()
              }
            }}
          >
            <S.CloseColor />
          </S.CloseIcon>
          <S.HeaderText>My Community</S.HeaderText>
          <S.PostList innerheight={innerHeight}>
            <S.ScrollPanel
              initialLoad={false}
              pageStart={1}
              threshold={250}
              loadMore={() => {
                if (throttling.current || !hasMore) {
                  return
                }
                throttling.current = true
                setTimeout(() => {
                  setCurrent(current + 1)
                  getCollectionListCall(current + 1)
                }, 200)
              }}
              hasMore={hasMore}
              useWindow={false}
              loader={<div key={1}></div>}
            >
              <S.Grid key={2}>
                {collections.map((item, index) => {
                  return <CollectionCard key={index} item={item}></CollectionCard>
                })}
              </S.Grid>
            </S.ScrollPanel>
          </S.PostList>
        </S.MyCollections>
      )}
    </>
  )
}

import styled from 'styled-components/macro'

export const Close = styled.div`
  background: white;
  color: black;
  width: 45px;
  height: 30px;
  position: absolute;
  left: 2vw;
  top 3vh;
  z-index: 2147483600 !important;
`

export const Panel = styled.div<{ visible: boolean }>`
  width: ${({ visible }) => (visible ? '100%' : '1px')};
  height: ${({ visible }) => (visible ? '100%' : '1px')};
  background: #161616;
  position: fixed;
  top: 0;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`

//import { useWeb3React } from '@web3-react/core'
import axios from 'axios'
import * as S from 'components/3DInfo/styles'
import { useCallback, useEffect } from 'react'
import { AppState } from 'state'
import { useAddPopup, useLeftModalOpen, useToggle3DInfo } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
//import { useGetProjectPropertyVRMutation } from 'state/daoApi/slice'
import { useAppSelector } from 'state/hooks'
import { useGetPropertyVRMutation } from 'state/projectApi/slice'
import TCGSDK from 'tcg-sdk'
//import { setTimeout } from 'timers'

export default function ThreeDInfo() {
  const open = useLeftModalOpen(ApplicationModal.THREED_INFO)
  const collapseCommunity = useAppSelector((state: AppState) => state.application.collapseCommunity)
  //const { account } = useWeb3React()
  //const [getPropertyVrTask] = useGetProjectPropertyVRMutation()
  const [getPropertyVr] = useGetPropertyVRMutation()
  const propertyId = useAppSelector((state) => state.home.buildingId)
  const addPopup = useAddPopup()
  const toggle3DInfo = useToggle3DInfo()
  //const [isStart, setIsStart] = useState(false)
  //const [timer, setTimer] = useState(null as any)
  const propertyInfo = useAppSelector((state: { home: { propertyInfo: any } }) => state.home.propertyInfo)

  /*const handleTaskReward = useCallback(() => {
    console.log('handleTaskReward')
    if (open && isStart) {
      getPropertyVrTask({ propertyId }).then((data: any) => {
        if (data?.data?.code === 0) {
          if (data?.data?.data?.taskInfo && data?.data?.data?.taskInfo?.executed) {
            //addPopup({ tip: `Congratulation! Task Reward ${data?.data?.data?.taskInfo?.asset?.quantity}` })
          }
        }
      })
    }
  }, [addPopup, getPropertyVrTask, isStart, open, propertyId])*/

  /*useEffect(() => {
    console.log('isStart:', isStart, timer)
    if (isStart && !timer && account) {
      const temp = setTimeout(() => {
        handleTaskReward()
      }, 60000)
      setTimer(temp)
    } else {
      if (timer) {
        clearTimeout(timer)
        setTimer(null)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleTaskReward, isStart, account])*/

  const StartProject = useCallback(async () => {
    const headers = {
      'x-api-key': process.env.REACT_APP_X_API_KEY ?? ''
    }
    const url = propertyInfo.vrUrls[0].url + '/v1/vr/StartProject' // 后台服务可以参考后台demo 方案
    // 其他选填参数可以参考 ApplyConcurrent 接口文档
    const { data } = await axios.post(
      url,
      {
        ProjectId: propertyInfo.vrUrls[0].projectId,
        UserId: propertyInfo.vrUrls[0].userId,
        ClientSession: TCGSDK.getClientSession()
      },
      {
        headers
      }
    )

    console.log('%c StartProject res', 'color: blue; font-size: 14px', data)

    const {
      Code,
      SessionDescribe: { ServerSession }
    } = data

    if (Code === 0) {
      TCGSDK.start(ServerSession)
      if ((window as any).mapbox.hasControl((window as any).navigationControl)) {
        ;(window as any).mapbox.removeControl((window as any).navigationControl)
      }
      if ((window as any).mapbox.hasControl((window as any).geoControl)) {
        ;(window as any).mapbox.removeControl((window as any).geoControl)
      }
      if (collapseCommunity === false) {
        if ((window as any).document.getElementById('js-slider') !== null) {
          ;(window as any).document.getElementById('js-slider').click()
        }
      }
      //setIsStart(true)
    } else {
      addPopup({ error: 'Error!' })
    }
  }, [addPopup, collapseCommunity, propertyInfo])

  const StopProject = useCallback(async () => {
    if (propertyInfo === undefined || propertyInfo.vrUrls === undefined) {
      return
    }
    const url = propertyInfo.vrUrls[0].url + '/v1/vr/StopProject' // 后台服务可以参考后台demo 方案
    const headers = {
      'x-api-key': process.env.REACT_APP_X_API_KEY ?? ''
    }
    // 其他选填参数可以参考 ApplyConcurrent 接口文档
    await axios.post(
      url,
      {
        ProjectId: propertyInfo.vrUrls[0].projectId,
        UserId: propertyInfo.vrUrls[0].userId
      },
      {
        headers
      }
    )
  }, [propertyInfo])

  useEffect(() => {
    if (open && propertyId) {
      getPropertyVr({ propertyId }).then((data: any) => {
        if (data?.data?.code === 0) {
          TCGSDK.init({
            appid: 1314418507,
            mount: 'mount-panel',
            debugSetting: {
              showLog: true
            },
            loadingText: 'Yephome property is loading ...',
            autoRotateContainer: false,
            //tabletMode: false,
            //mobileGame: true,
            forceShowCursor: true,
            // 连接成功回调
            onConnectSuccess: async (res) => {
              console.log('onConnectSuccess', res)
            },
            // 网络中断/被踢触发此回调
            onDisconnect: (res) => {
              console.log('onDisconnect', res)
            },
            onWebrtcStatusChange: (res) => {
              console.log('onWebrtcStatusChange', res)
            },
            onInitSuccess: async (res) => {
              console.log('%c onInitSuccess', 'color: red', res)
              await StartProject().catch((e) => {
                console.error(e)
              })
            }
          })
        } else {
          addPopup({ error: 'Error!' })
        }
      })
    } else {
      if (!(window as any).mapbox.hasControl((window as any).geoControl)) {
        ;(window as any).mapbox.addControl((window as any).geoControl, 'bottom-left')
      }
      if (!(window as any).mapbox.hasControl((window as any).navigationControl)) {
        ;(window as any).mapbox.addControl((window as any).navigationControl, 'bottom-left')
      }
      if (collapseCommunity === true) {
        if ((window as any).document.getElementById('js-slider') !== null) {
          ;(window as any).document.getElementById('js-slider').click()
        }
      }
      //setIsStart(false)
      StopProject().catch((e) => {
        console.error(e)
      })
    }
    return () => {
      TCGSDK.destroy()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, propertyId])

  return (
    <S.Panel visible={open}>
      <S.Close
        onClick={() => {
          StopProject().catch((e) => {
            console.error(e)
          })
          toggle3DInfo()
          //GeolocateControl
          //NavigationControl
        }}
      >
        Close
      </S.Close>
      <div id="mount-panel"></div>
    </S.Panel>
  )
}

import * as S from 'components/ChatInfoPanel/styles'
import { getChatTime } from 'constants/misc'
import { useEffect, useRef } from 'react'
import { useAppSelector } from 'state/hooks'

export default function Message({ message, showHeader = false }: { message: any; showHeader?: boolean }) {
  const ref = useRef(null as any)
  const currentUserInfo = useAppSelector((state) => state.user.userInfo)
  const chatInfo = useAppSelector((state) => state.home.chatInfo)
  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: 'smooth' })
  }, [message])
  return (
    <S.MessagePanel ref={ref}>
      {message.senderId === currentUserInfo?.userId ? (
        <S.OwnerMessage>
          <S.OwnerPanel>
            {showHeader && (
              <S.OwnerHeaderPanel>
                <S.MessageTime>{getChatTime(message?.date?.seconds * 1000)}</S.MessageTime>
              </S.OwnerHeaderPanel>
            )}
            <S.OwnerMessageContent>
              {message.text && <p>{message.text}</p>}
              {message.image && <S.MessageImage src={message.image} alt="" />}
            </S.OwnerMessageContent>
          </S.OwnerPanel>
        </S.OwnerMessage>
      ) : (
        <S.Message>
          {showHeader && (
            <S.MessageHeaderPanel>
              <S.MessageAvatar src={chatInfo?.user?.photoURL}></S.MessageAvatar>
              <S.MessageName>{chatInfo?.user?.displayName}</S.MessageName>
              <S.MessageTime>{getChatTime(message?.date?.seconds * 1000)}</S.MessageTime>
            </S.MessageHeaderPanel>
          )}
          <S.MessageContent>
            {message.text && <p>{message.text}</p>}
            {message.image && <S.MessageImage src={message.image} alt="" />}
          </S.MessageContent>
        </S.Message>
      )}
    </S.MessagePanel>
  )
}

import { ReactComponent as Close } from 'assets/svg/x.svg'
import TipPopup from 'components/Popups/ErrorPopup'
import TransactionPopup from 'components/Popups/TransactionPopup'
import { useCallback, useContext, useEffect } from 'react'
import { animated } from 'react-spring'
import { useSpring } from 'react-spring/web'
import { useRemovePopup } from 'state/application/hooks'
import { PopupContent } from 'state/application/reducer'
import styled, { ThemeContext } from 'styled-components/macro'
import { Z_INDEX } from 'theme'

const StyledClose = styled(Close)`
  position: absolute;
  right: 0.58vw;
  top: 1.07vh;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    z-index: ${Z_INDEX.tooltip};
    right: 4.87vw;
    top: 2.37vh;
    height: 1.78vh;
  `}

  :hover {
    cursor: pointer;
  }
`
const Popup = styled.div<{ error: boolean }>`
  display: inline-block;
  width: 100%;
  padding: 1em;
  background-color: ${({ theme, error }) => (error ? theme.red1 : theme.primary1)};
  position: relative;
  border-radius: 8px;
  padding: 10px 21px;
  overflow: hidden;
  max-height: 3.33vh;
  font-size: 1.43vh;
  font-style: normal;
  font-weight: 300;

  color: ${({ theme }) => theme.text4};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    height: 6.4vh;
    max-height: 100%;
    border-radius: 0;
    display: flex;
    align-items: center;
    font-size: 1.9vh;
    padding: 0;
  `}
`
const Fader = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.bg3};
`

const AnimatedFader = animated(Fader)

export default function PopupItem({
  removeAfterMs,
  content,
  popKey
}: {
  removeAfterMs: number | null
  content: PopupContent
  popKey: string
}) {
  const removePopup = useRemovePopup()
  const removeThisPopup = useCallback(() => removePopup(popKey), [popKey, removePopup])
  useEffect(() => {
    if (removeAfterMs === null) return undefined

    const timeout = setTimeout(() => {
      removeThisPopup()
    }, removeAfterMs)

    return () => {
      clearTimeout(timeout)
    }
  }, [removeAfterMs, removeThisPopup])

  const theme = useContext(ThemeContext)

  let popupContent
  let error = false
  if ('txn' in content) {
    const {
      txn: { hash }
    } = content
    popupContent = <TransactionPopup hash={hash} />
  } else if ('tip' in content) {
    popupContent = <TipPopup errorInfo={content.tip} />
  } else if ('error' in content) {
    error = true
    popupContent = <TipPopup errorInfo={content.error} />
  }

  const faderStyle = useSpring({
    from: { width: '100%' },
    to: { width: '0%' },
    config: { duration: removeAfterMs ?? undefined }
  })

  return (
    <Popup error={error}>
      <StyledClose color={theme.text4} onClick={removeThisPopup} />
      {popupContent}
      {removeAfterMs !== null ? <AnimatedFader style={faderStyle} /> : null}
    </Popup>
  )
}

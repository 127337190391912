import { createSlice, nanoid } from '@reduxjs/toolkit'
import { DEFAULT_TXN_DISMISS_MS } from 'constants/misc'

export type PopupContent =
  | {
      txn: {
        hash: string
      }
    }
  | {
      tip: string
    }
  | {
      error: string
    }

export enum ApplicationModal {
  BLOCKED_ACCOUNT,
  MENU,
  WALLET,
  PROFILE_SETTING,
  MY_COLLECTION,
  PLACES_LIST,
  COMMUNITY,
  NOTIFICATIONS,
  POST_LARGE_IMAGE,
  POST_COMMENTS,
  POST_CREATE,
  TASK_INFO,
  PROPERTY_INFO,
  BUY_PROPERTY,
  THREED_INFO,
  THREED_INFO_MOBILE,
  THREED_GALLERY_INFO,
  CONFIRMATION,
  OTHERUSER,
  CHATINFO,
  MOBILE_CHATINFO,
  // top modal
  LOGIN,
  TERMOFUSE,
  GROUNDRULES
}

type PopupList = Array<{ key: string; show: boolean; content: PopupContent; removeAfterMs: number | null }>

export interface ApplicationState {
  readonly chainId: number | null
  readonly openModal: ApplicationModal | null
  readonly openLeftModal: ApplicationModal | null
  readonly openTopModal: ApplicationModal | null
  readonly popupList: PopupList
  readonly mapCenterLat: number
  readonly mapCenterLng: number
  readonly mapSearchText: string
  readonly selectedMapBuiding: any | null
  readonly collapseCommunity: boolean
  readonly showSign: boolean
}

const initialState: ApplicationState = {
  chainId: null,
  openModal: null,
  openLeftModal: null,
  openTopModal: null,
  popupList: [],
  mapCenterLat: -37.80977,
  mapCenterLng: 144.96246,
  selectedMapBuiding: null,
  mapSearchText: '',
  collapseCommunity: false,
  showSign: false
}

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    updateMapSearchText(state, action) {
      state.mapSearchText = action.payload.searchText
    },
    updateMapLocation(state, action) {
      state.mapCenterLat = action.payload.lat
      state.mapCenterLng = action.payload.lng
      if (action.payload.building.id !== '0') {
        state.selectedMapBuiding = action.payload.building
      }
    },
    updateChainId(state, action) {
      const { chainId } = action.payload
      state.chainId = chainId
    },
    setShowSign(state, action) {
      const { showSign } = action.payload
      state.showSign = showSign
    },
    setCollsapeCommunity(state, action) {
      const { collapse } = action.payload
      state.collapseCommunity = collapse
    },
    setOpenModal(state, action) {
      state.openModal = action.payload
    },
    setOpenTopModal(state, action) {
      state.openTopModal = action.payload
    },
    setOpenLeftModal(state, action) {
      state.openLeftModal = action.payload
    },
    addPopup(state, { payload: { content, key, removeAfterMs = DEFAULT_TXN_DISMISS_MS } }) {
      state.popupList = (key ? state.popupList.filter((popup) => popup.key !== key) : state.popupList).concat([
        {
          key: key || nanoid(),
          show: true,
          content,
          removeAfterMs
        }
      ])
    },
    removePopup(state, { payload: { key } }) {
      state.popupList.forEach((p) => {
        if (p.key === key) {
          p.show = false
        }
      })
    }
  }
})

export const {
  updateChainId,
  setOpenModal,
  setOpenLeftModal,
  setOpenTopModal,
  addPopup,
  removePopup,
  updateMapLocation,
  updateMapSearchText,
  setCollsapeCommunity,
  setShowSign
} = applicationSlice.actions
export default applicationSlice.reducer

import { AutoColumn } from 'components/Column'
import { AutoRow } from 'components/Row'
import styled from 'styled-components/macro'

const RowNoFlex = styled(AutoRow)`
  flex-wrap: nowrap;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 4.1vw;
  `};
`

export default function ErrorPopup({ errorInfo }: { errorInfo: string }) {
  return (
    <RowNoFlex>
      <AutoColumn>{errorInfo}</AutoColumn>
    </RowNoFlex>
  )
}

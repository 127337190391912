import { useSpring } from '@react-spring/web'
import IconEdit from 'assets/images/icon-edit.png'
import Guest from 'assets/images/icon-guest.png'
import * as S from 'components/ProfileSetting/styles'
import { addDoc, collection } from 'firebase/firestore'
import { useCallback, useEffect, useState } from 'react'
import ReactS3Client from 'react-aws-s3-typescript'
import { FileUploader } from 'react-drag-drop-files'
import { useAddPopup, useModalOpen, useToggleMenu, useToggleProfileSetting } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { updateUserProfile } from 'state/user/reducer'
import { useGetProfileMutation, useGetStoreConfigMutation, useUpdateProfileMutation } from 'state/userApi/slice'
import { replaceImageUrl } from 'utils'
import { db } from 'utils/firestore'

export default function ProfileSetting() {
  const currentUserInfo = useAppSelector((state) => state.user.userInfo)
  const menuOpen = useModalOpen(ApplicationModal.PROFILE_SETTING)
  const toggle = useToggleProfileSetting()
  const toggleMenu = useToggleMenu()
  const [isHovering, setIsHovering] = useState(false)
  const [nickName, setNickname] = useState('')
  const [avatar, setAvatar] = useState(null as any)
  const [walletAddress, setWalletAddress] = useState(currentUserInfo?.userId)
  const [getProfile] = useGetProfileMutation()
  const [getStoreConfig] = useGetStoreConfigMutation()
  const [updateProfile] = useUpdateProfileMutation()
  const [storeConfig, setStoreConfig] = useState(null as any)
  const [role, setRole] = useState('customer')
  const addPopup = useAddPopup()
  const dispatch = useAppDispatch()

  const fileTypes = ['png', 'jpg', 'jpeg', 'webp', 'avif']
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    if (refresh) {
      setRefresh(false)

      getProfile({ userId: currentUserInfo?.userId })
        .then((data: any) => {
          addDoc(collection(db, 'ACTIONS'), {
            ACTION: 'PROFILE',
            TIME: new Date().toLocaleDateString('en-AU'),
            TIMESTAMP: Date.now(),
            USER_ID: currentUserInfo?.userId !== undefined ? currentUserInfo.userId : ''
          })
          setWalletAddress(currentUserInfo?.userId)
          setNickname(data?.data?.data?.nickName ?? '')
          setAvatar(data?.data?.data?.avatar ?? '')
          setRole(data?.data?.data?.role ?? 'customer')
          dispatch(updateUserProfile({ profile: data?.data?.data }))
        })
        .catch((e) => {
          console.error(e)
          dispatch(updateUserProfile({ profile: null }))
        })
    }
  }, [currentUserInfo?.userId, dispatch, getProfile, refresh])

  useEffect(() => {
    if (currentUserInfo?.userId && menuOpen) {
      getStoreConfig({ appName: 'yephome' })
        .then((data: any) => {
          setStoreConfig(data?.data?.data)
        })
        .catch((e) => {
          console.error(e)
        })
      getProfile({ userId: currentUserInfo?.userId })
        .then((data: any) => {
          if (data?.data?.code === 0) {
            setWalletAddress(currentUserInfo?.userId)
            setNickname(data?.data?.data?.nickName ?? '')
            setAvatar(data?.data?.data?.avatar ?? '')
            setRole(data?.data?.data?.role ?? 'customer')
            dispatch(updateUserProfile({ profile: data?.data?.data }))
          }
        })
        .catch((e) => {
          console.error(e)
        })
    } else {
      setFile(null)
      setWalletAddress('')
      setNickname('')
      setAvatar('')
      setRole('customer')
    }
  }, [getProfile, getStoreConfig, menuOpen, dispatch, currentUserInfo?.userId])

  const [file, setFile] = useState(null as any)
  const handleChange = async (file: any) => {
    setFile(file)
  }

  const handleSaveProfile = useCallback(() => {
    if (file) {
      const s3 = new ReactS3Client({
        bucketName: storeConfig.bucketName,
        dirName: 'avatars' /* Optional */,
        region: storeConfig.region,
        accessKeyId: storeConfig.accessId,
        secretAccessKey: storeConfig.accessKey
      })
      s3.uploadFile(file, (currentUserInfo?.userId ?? '') + new Date().valueOf())
        .then((res) => {
          setAvatar(res.location)

          updateProfile({ userId: currentUserInfo?.userId ?? '', avatar: res.location, nickName, role })
            .then((data: any) => {
              if (data?.data?.code === 0 && data?.data?.data?.status === 1) {
                setRefresh(true)
                if (menuOpen) {
                  toggle()
                  toggleMenu()
                }
                addPopup({ tip: 'You successfully edited your profile!' })
              } else {
                addPopup({ error: 'Failed to edit profile. Please try again.' })
              }
            })
            .catch((e) => {
              addPopup({ error: 'Failed to edit profile. Please try again.' })
            })
        })
        .catch((e) => {
          addPopup({ error: 'Failed to edit profile. Please try again.' })
        })
    } else {
      updateProfile({ userId: currentUserInfo?.userId ?? '', avatar, nickName, role })
        .then((data: any) => {
          if (data?.data?.code === 0 && data?.data?.data?.status === 1) {
            setRefresh(true)
            if (menuOpen) {
              toggle()
              toggleMenu()
            }
            addPopup({ tip: 'You successfully edited your profile!' })
          } else {
            addPopup({ error: 'Failed to edit profile. Please try again.' })
          }
        })
        .catch((e) => {
          addPopup({ error: 'Failed to edit profile. Please try again.' })
        })
    }
  }, [
    file,
    storeConfig,
    currentUserInfo?.userId,
    updateProfile,
    nickName,
    role,
    menuOpen,
    addPopup,
    toggle,
    toggleMenu,
    avatar
  ])
  const { scale, opacity } = useSpring({
    from: { scale: 1, opacity: 1 },
    scale: isHovering ? 0.95 : 1,
    opacity: isHovering ? 0.12 : 1,
    config: { duration: 200 }
  })

  const { opacity: titleOpacity } = useSpring({
    from: { opacity: 1 },
    opacity: isHovering ? 1 : 0,
    config: { duration: 200 }
  })
  const { transform } = useSpring({
    from: { transform: 'translate3d(104.39%,0,0)' },
    transform: menuOpen ? 'translate3d(0%,0,0)' : 'translate3d(104.39%,0,0)',
    config: { duration: 200 }
  })
  return (
    <>
      {menuOpen && (
        <S.ProfileSetting style={{ transform }}>
          <S.CloseIcon
            onClick={() => {
              if (menuOpen) {
                toggle()
                toggleMenu()
              }
            }}
          >
            <S.CloseColor />
          </S.CloseIcon>
          <S.HeaderText>Profile Setting</S.HeaderText>
          <S.AvatarPanel>
            <FileUploader handleChange={handleChange} name="file" types={fileTypes} maxSize={1024}>
              <S.AvatarContainer
                onMouseEnter={() => {
                  setIsHovering(true)
                }}
                onMouseLeave={() => {
                  setIsHovering(false)
                }}
              >
                <S.Avatar
                  style={{ scale, opacity }}
                  src={file ? URL.createObjectURL(file) : avatar ? replaceImageUrl(avatar, 200) : Guest}
                  alt={'avatar'}
                ></S.Avatar>

                <S.EditContainer
                  style={{
                    opacity: titleOpacity
                  }}
                >
                  <S.EditIcon src={IconEdit} alt={'edit-icon'}></S.EditIcon>
                </S.EditContainer>
              </S.AvatarContainer>
            </FileUploader>
          </S.AvatarPanel>
          <S.ItemContainer>
            <S.ItemLabel>Nickname</S.ItemLabel>
            <S.Input
              value={nickName}
              onUserInput={function (value: string): void {
                setNickname(value)
              }}
              maxLength={30}
              placeholder={'Enter nickname'}
              fontSize={'1.25vh'}
            ></S.Input>
          </S.ItemContainer>
          {!currentUserInfo?.isGuest && (
            <S.ItemContainer>
              <S.ItemLabel>Wallet Address</S.ItemLabel>
              <S.Input
                value={walletAddress ?? ''}
                disabledInput={true}
                onUserInput={function (value: string): void {
                  setWalletAddress(value)
                }}
                placeholder={''}
                fontSize={'1.25vh'}
              ></S.Input>
            </S.ItemContainer>
          )}
          <S.ItemContainer style={{ display: 'none' }}>
            <S.ItemLabel>Verify as</S.ItemLabel>

            <S.CheckoutDeveloper
              checked={role === 'developer'}
              onClick={() => {
                if (role === 'developer') {
                  setRole('customer')
                } else {
                  setRole('developer')
                }
              }}
            />
            <S.DeveloperText>Developer</S.DeveloperText>
            <S.CheckoutAgent
              checked={role === 'agent'}
              onClick={() => {
                if (role === 'agent') {
                  setRole('customer')
                } else {
                  setRole('agent')
                }
              }}
            />
            <S.AgentText>Agent</S.AgentText>
          </S.ItemContainer>
          <S.SaveBtn $borderRadius="1.07vh" padding="1.07vh 0.69vw" onClick={handleSaveProfile}>
            SAVE
          </S.SaveBtn>
        </S.ProfileSetting>
      )}
    </>
  )
}

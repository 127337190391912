import { useAnalyticsReporter } from 'components/Analytics'
import ErrorBoundary from 'components/ErrorBoundary'
import Loader from 'components/Loader'
import Popups from 'components/Popups'
import TopLevelModals from 'components/TopLevelModals'
import { addDoc, collection } from 'firebase/firestore'
import Home from 'pages/Home'
import { RedirectPathToHomeOnly } from 'pages/Home/redirects'
import { Suspense } from 'react'
import { useEffect } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { useAppSelector } from 'state/hooks'
import styled from 'styled-components/macro'
import BuildingQueryParamReader from 'theme/BuildingQueryParamReader'
import { db } from 'utils/firestore'

const AppWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #000;
`

export default function App() {
  const history = useHistory()
  const currentUserInfo = useAppSelector((state) => state.user.userInfo)
  useAnalyticsReporter(useLocation())

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
    })
    return () => {
      unlisten()
    }
  }, [history])

  useEffect(() => {
    fetch(process.env.REACT_APP_VISIT_API ?? 'https://prod.api.yephomes.org/v1/statistics/visit')
      .then(() => {
        console.debug('visit done')
      })
      .catch((e) => {
        console.error(e)
      })
  }, [])

  useEffect(() => {
    if (currentUserInfo && currentUserInfo?.userId) {
      addDoc(collection(db, 'REFERAL'), {
        SOURCE: window.document.referrer !== '' ? window.document.referrer : window.location.href,
        TIME: new Date().toLocaleDateString('en-AU'),
        TIMESTAMP: Date.now(),
        USER_ID: currentUserInfo.userId
      })
    }
    if (currentUserInfo && currentUserInfo?.userId) {
      addDoc(collection(db, 'ACTIONS'), {
        ACTION: 'MAP',
        TIME: new Date().toLocaleDateString('en-AU'),
        TIMESTAMP: Date.now(),
        USER_ID: currentUserInfo?.userId
      })
    }
  }, [currentUserInfo])

  return (
    <ErrorBoundary>
      {/* <Route component={DarkModeQueryParamReader} />
      <Route component={ApeModeQueryParamReader} /> */}
      <Route component={BuildingQueryParamReader} />

      <AppWrapper>
        <Popups />
        <TopLevelModals />
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route exact strict path="/" component={Home} />

            <Route component={RedirectPathToHomeOnly} />
          </Switch>
        </Suspense>
      </AppWrapper>
    </ErrorBoundary>
  )
}

import { useEffect, useState } from 'react'

const isClient = typeof window === 'object'

function getSize() {
  let clientWidth, clientHeight
  if (isClient) {
    if (document.compatMode === 'CSS1Compat') {
      clientWidth = document.documentElement.clientWidth
      clientHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
    } else {
      clientWidth = document.body.clientWidth
      clientHeight = Math.max(document.body.clientHeight, window.innerHeight || 0)
    }
  }
  return {
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined,
    clientWidth: isClient ? clientWidth : undefined,
    clientHeight: isClient ? clientHeight : undefined
  }
}

// https://usehooks.com/useWindowSize/
export function useWindowSize() {
  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize())
    }

    if (isClient) {
      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
    return undefined
  }, [])

  return windowSize
}

import './slider.css'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import ArrowLeft from 'assets/images/icon-arrow-left.png'
import ArrowRight from 'assets/images/icon-arrow-right.png'
import * as S from 'components/BuyProperty/styles'
import { CustomizeEvent } from 'constants/events'
import { GetPropertyColor, GetPropertyStatus, PropertyStatus } from 'constants/property'
import { useCallback, useEffect, useState } from 'react'
import { useAddPopup, useLeftModalOpen, useToggleBuyProperty, useToggleConfirmationInfo } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { updatePropertyId } from 'state/home/reducer'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { useCheckSalesQtyMutation } from 'state/payApi/slice'
import { useGetBuildingPropertyMutation } from 'state/projectApi/slice'
import { dispatch as dispatchEvent } from 'use-bus'

import CheckoutForm from './CheckoutForm'
import PaymentForm from './PaymentForm'

export default function BuyProperty() {
  const open = useLeftModalOpen(ApplicationModal.BUY_PROPERTY)
  const buildingId = useAppSelector((state) => state.home.buildingId)
  const toggle = useToggleBuyProperty()
  const [clientSecret, setClientSecret] = useState('')
  const dispatch = useAppDispatch()
  const [stripePromise, setStripePromise] = useState(null as any)

  const [showPayment, setShowPayment] = useState(false)

  const [propertys, setPropertys] = useState([] as any[])
  const [getBuildingProperty] = useGetBuildingPropertyMutation()

  const [orderId, setOrderId] = useState('')

  const addPopup = useAddPopup()
  const [selected, setSelected] = useState(-1)
  const [curIndex, setCurIndex] = useState(0)
  const [lastAction, setLastAction] = useState(0) // 0 initail 1 pre 2 next

  const [price, setPrice] = useState(null as any)
  const [success, setSuccess] = useState(false)

  const reset = useCallback(() => {
    setSuccess(false)
    setSelected(-1)
    setCurIndex(0)
    setLastAction(0)
    setShowPayment(false)
    setOrderId('')
    setClientSecret('')
    setPrice(null)
    setStripePromise(null)
    dispatchEvent(CustomizeEvent.REFRESH_PAYMENT)
  }, [])

  const handleNext = useCallback(() => {
    if (lastAction === 0 || lastAction === 1) {
      let steps = curIndex + 7
      if (steps > propertys.length - 1) {
        steps = propertys.length - 1
      }
      const element = document.getElementById(`section-${steps}`)
      setCurIndex(steps)
      if (element) {
        setLastAction(2)
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth' })
      }
    } else {
      let steps = curIndex + 4
      if (steps > propertys.length - 1) {
        steps = propertys.length - 1
      }
      const element = document.getElementById(`section-${steps}`)
      setCurIndex(steps)
      if (element) {
        setLastAction(2)
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [curIndex, lastAction, propertys.length])

  const handlePre = useCallback(() => {
    if (lastAction === 0 || lastAction === 2) {
      let steps = curIndex - 7
      if (steps < 0) {
        steps = 0
      }
      const element = document.getElementById(`section-${steps}`)
      setCurIndex(steps)
      if (element) {
        setLastAction(1)
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth' })
      }
    } else {
      let steps = curIndex - 4
      if (steps < 0) {
        steps = 0
      }
      const element = document.getElementById(`section-${steps}`)
      setCurIndex(steps)
      if (element) {
        setLastAction(1)
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [curIndex, lastAction])

  const [checkSalesQty] = useCheckSalesQtyMutation()
  const toggleConfirmation = useToggleConfirmationInfo()

  useEffect(() => {
    if (open && buildingId) {
      getBuildingProperty({ buildingId })
        .then((data: any) => {
          if (data?.data?.code === 0) {
            setPropertys(data?.data?.data?.data)
            reset()
          }
        })
        .catch((e) => {
          console.error(e)
          addPopup({ error: 'Error!' })
        })
    }
  }, [addPopup, buildingId, getBuildingProperty, open, reset])

  useEffect(() => {
    if (propertys.length > 0 && selected >= 0) {
      checkSalesQty({ productId: propertys[selected].id })
        .then((data: any) => {
          if (data?.data?.code === 0) {
            if (data?.data?.data?.status === 1) {
              setStripePromise(loadStripe(data?.data?.data?.stripePublicKey))
              setPrice(data?.data?.data?.prices)
            } else {
              addPopup({ error: 'Error!' })
            }
          }
        })
        .catch((e) => {
          console.error(e)
          addPopup({ error: 'Error!' })
        })
    }
  }, [addPopup, checkSalesQty, propertys, selected])

  useEffect(() => {
    if (!open) {
      reset()
    }
  }, [open, reset])

  return (
    <>
      {open && (
        <S.Panel>
          {success && (
            <S.SuccessPanel>
              <S.SuccessInfo>Congratulations!</S.SuccessInfo>
              <S.ViewButton
                onClick={() => {
                  dispatch(updatePropertyId({ propertyId: propertys[selected].id }))
                  setSuccess(false)
                  toggleConfirmation()
                }}
              >
                <S.ViewText>View your confirmation of deposit</S.ViewText>
              </S.ViewButton>
            </S.SuccessPanel>
          )}

          {showPayment && (
            <S.PaymentElemnetPanel>
              {clientSecret && stripePromise && orderId && propertys.length > 0 && (
                <Elements
                  options={{
                    clientSecret,
                    appearance: {
                      theme: 'night',
                      variables: {
                        colorPrimary: '#2142FF',
                        colorBackground: '#161616',
                        colorText: '#DEDEDE',
                        colorDanger: '#df1b41',
                        fontFamily: 'Roboto FlexVariable'
                      }
                    }
                  }}
                  stripe={stripePromise}
                >
                  <PaymentForm
                    orderId={orderId}
                    onSuccess={() => {
                      setShowPayment(false)
                      setSuccess(true)
                    }}
                    onClose={() => {
                      setShowPayment(false)
                    }}
                  />
                </Elements>
              )}
            </S.PaymentElemnetPanel>
          )}
          <S.CloseIcon onClick={toggle}>
            <S.CloseColor />
          </S.CloseIcon>
          <S.Content>
            <S.OverviewPanel>
              <S.OverviewTitle>Overview</S.OverviewTitle>
              <S.OverviewInfo>
                <S.ArrowLeftPanel>
                  <S.ArrowIcon onClick={handlePre} src={ArrowLeft} alt={'arrow-left'}></S.ArrowIcon>
                </S.ArrowLeftPanel>
                {propertys && propertys.length > 0 && (
                  <S.PropertyGrid>
                    {propertys.map((item, index) => {
                      return (
                        <S.PropertyPanel
                          id={`section-${index}`}
                          key={index}
                          onClick={() => {
                            if (
                              item.status === PropertyStatus.AVAILABLE ||
                              item.status === PropertyStatus.UNCONDITIONAL
                            )
                              setSelected(index)
                          }}
                          className={index === selected ? 'selected' : ''}
                        >
                          <S.Card>
                            <S.UnitPanel>
                              <S.UnitText>{item?.unitNum}</S.UnitText>
                              <S.StatusPanel>
                                <S.StatusDot color={GetPropertyColor(item?.status)}></S.StatusDot>
                                <S.StatusText color={GetPropertyColor(item?.status)}>
                                  {GetPropertyStatus(item?.status)}
                                </S.StatusText>
                              </S.StatusPanel>
                              {item.status === PropertyStatus.AVAILABLE && (
                                <S.CheckboxPanel>
                                  <S.CheckboxPanelCheckbox
                                    type="checkbox"
                                    id="scales"
                                    name="scales"
                                    opacity={index === selected ? '1' : '0.2'}
                                    checked={index === selected}
                                  ></S.CheckboxPanelCheckbox>
                                </S.CheckboxPanel>
                              )}
                            </S.UnitPanel>
                            <S.PropertyInfoPanel>
                              {item?.bedRoom} Bed / {item?.bathRoom} Bath / {item?.carParking} Car Park
                            </S.PropertyInfoPanel>
                            <S.PriceInfoPanle>
                              $
                              {parseInt(item?.price).toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              })}
                            </S.PriceInfoPanle>
                          </S.Card>
                        </S.PropertyPanel>
                      )
                    })}
                  </S.PropertyGrid>
                )}
                <S.ArrowRightPanel>
                  <S.ArrowIcon onClick={handleNext} src={ArrowRight} alt={'arrow-right'}></S.ArrowIcon>
                </S.ArrowRightPanel>
              </S.OverviewInfo>
            </S.OverviewPanel>
            <S.DepositPanel>
              <S.DepositTitle>Deposit</S.DepositTitle>
              {price && (
                <S.PricePanel>
                  {price?.currency}${price?.stripe}
                </S.PricePanel>
              )}
              {selected < 0 && <S.NoInfo>Please select a property to purchase</S.NoInfo>}
              {propertys.length > 0 && selected >= 0 && (
                <CheckoutForm
                  productId={propertys[selected].id}
                  price={price}
                  onPayment={(orderId: string, clientSecret: string) => {
                    setOrderId(orderId)
                    setClientSecret(clientSecret)
                    setShowPayment(true)
                  }}
                  onSuccess={() => {
                    setSuccess(true)
                  }}
                />
              )}
            </S.DepositPanel>
          </S.Content>
        </S.Panel>
      )}
    </>
  )
}

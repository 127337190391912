import { useSpring } from '@react-spring/web'
import * as S from 'components/UserMenuPanel/styles'
import { useWindowSize } from 'hooks/useWindowSize'
import { useCallback } from 'react'
import {
  useModalOpen,
  useToggleChatInfo,
  useToggleGroundRules,
  useToggleLogin,
  useToggleMenu,
  useToggleMyCollection,
  useToggleNotification,
  useToggleProfileSetting,
  useToggleTermOfUse,
  useWalletModalToggle
} from 'state/application/hooks'
import { ApplicationModal, setOpenLeftModal, setOpenModal } from 'state/application/reducer'
import { updateChatInfo, updateSelectBuildingName } from 'state/home/reducer'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { updateSelectedWallet, updateUserInfo, updateUserProfile } from 'state/user/reducer'
import { useLogoutMutation } from 'state/userApi/slice'
import { MEDIA_WIDTHS } from 'theme'

export default function UserMenuPanel() {
  const currentUserInfo = useAppSelector((state) => state.user.userInfo)
  const showNotify = useAppSelector((state) => state.home.showNotify)
  const showMessage = useAppSelector((state) => state.home.showMessage)
  const dispatch = useAppDispatch()
  const menuOpen = useModalOpen(ApplicationModal.MENU)
  const toggleMenu = useToggleMenu()
  const toggleLogin = useToggleLogin()
  const toggleProfileSetting = useToggleProfileSetting()
  const toggleMyCollection = useToggleMyCollection()
  const toggleTermOfUse = useToggleTermOfUse()
  const toggleGroundRules = useToggleGroundRules()
  const toggleWallet = useWalletModalToggle()
  const toggleChatInfo = useToggleChatInfo()
  const toggleNotification = useToggleNotification()
  const [logout] = useLogoutMutation()

  const handleLogout = useCallback(() => {
    if (currentUserInfo?.isGuest) {
      toggleLogin()
    } else {
      if (menuOpen) toggleMenu()

      logout(null).catch((e) => console.error(e))
      dispatch(updateSelectedWallet({ wallet: undefined }))
      dispatch(updateUserInfo({ userInfo: null }))
      dispatch(updateUserProfile({ profile: null }))
      dispatch(setOpenLeftModal(null))
      dispatch(setOpenModal(null))
      dispatch(updateChatInfo({ chatInfo: undefined }))
      dispatch(updateSelectBuildingName({ selectBuildingName: undefined }))
    }
  }, [currentUserInfo?.isGuest, dispatch, logout, menuOpen, toggleLogin, toggleMenu])

  const styleA = useSpring({
    from: { transform: 'translate3d(104.39%,0,0)' },
    transform: menuOpen ? 'translate3d(0%,0,0)' : 'translate3d(104.39%,0,0)',
    config: { duration: 200 }
  })

  const size = useWindowSize()
  const styleB = useSpring({
    from: { transform: 'translate3d(0,104.39%,0)', opacity: 0 },
    to: { transform: 'translate3d(0,0%,0)', opacity: 1 },
    leave: { transform: 'translate3d(0,104.39%,0)', opacity: 0 },
    config: { duration: 1200 }
  })

  return (
    <>
      {menuOpen && (
        <S.MenuPanel style={(size?.width ?? MEDIA_WIDTHS.upToMedium) <= MEDIA_WIDTHS.upToSmall ? styleB : styleA}>
          <S.CloseIcon onClick={toggleMenu}>
            <S.CloseColor />
          </S.CloseIcon>
          <S.MenuItem onClick={toggleNotification}>
            Notifications{' '}
            {showNotify && (
              <S.NotifyContent>
                <S.NotifyPanel></S.NotifyPanel>
              </S.NotifyContent>
            )}
          </S.MenuItem>
          <S.MenuItem
            onClick={() => {
              toggleMyCollection()
            }}
          >
            My Community
          </S.MenuItem>

          <S.MenuItem onClick={toggleChatInfo}>Messages {showMessage && <S.NotifyPanel></S.NotifyPanel>}</S.MenuItem>
          <S.MenuItem
            onClick={() => {
              toggleProfileSetting()
            }}
          >
            Profile Setting
          </S.MenuItem>

          {currentUserInfo?.isGuest && (
            <S.MenuItem
              onClick={() => {
                toggleWallet()
              }}
            >
              Connect Wallet
            </S.MenuItem>
          )}
          <S.LogoutItem onClick={handleLogout}>Log out</S.LogoutItem>
          <S.FooterPanel>
            <S.FooterItem onClick={toggleTermOfUse}>Term of use</S.FooterItem>
            <S.GroundRulesItem onClick={toggleGroundRules}>Ground Rules</S.GroundRulesItem>
          </S.FooterPanel>
        </S.MenuPanel>
      )}
    </>
  )
}

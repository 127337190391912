import { ReactComponent as Close } from 'assets/svg/x.svg'
import styled from 'styled-components/macro'
import { CommonPanel } from 'theme'

export const MenuPanel = styled(CommonPanel)`
  padding: 1.25vh 1.27vw 1.7vh 1.27vw;
  margin-top: 0.54vh;
  position: relative;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 1.9vh 5.38vw 2.13vh 5.38vw;
    margin-top: 0.71vh;
  `};
`

export const MenuItem = styled.div<{ disabled?: boolean }>`
  display: flex;
  font-style: normal;
  font-weight: 700;
  font-size: 1.43vh;
  margin-bottom: 1.52vh;
  cursor: pointer;
  color: ${({ theme, disabled }) => (disabled ? 'rgba(222, 222, 222, 0.22)' : theme.text4)};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.9vh;
    margin-bottom: 2.01vh;
  `};
`

export const NotifyContent = styled.div`
  display: flex;
  align-items: center;
`

export const NotifyPanel = styled.div`
  background: ${({ theme }) => theme.primary1};
  width: 0.89vh;
  height: 0.89vh;
  border-radius: 50%;
  margin-left: 0.46vw;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 0.71vh;
  line-height: 133.19%;
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ theme }) => theme.text1};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 1.18vh;
    height: 1.18vh;
    font-size: 0.95vh;
    margin-left: 3.08vw;
  `};
`

export const LogoutItem = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 1.43vh;
  cursor: pointer;
  color: ${({ theme }) => theme.text4};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.9vh;
  `};
`

export const CloseIcon = styled.div`
  position: absolute;
  right: 1.04vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    right: 4.62vw;
  `};
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`
export const CloseColor = styled(Close)`
  color: ${({ theme }) => theme.text4};
  width: 0.87vw;
  height: 0.87vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 3.85vw;
    height: 3.85vw;
  `};
`

export const FooterPanel = styled.div`
  margin-top: 5.01vh;
  display: flex;
  font-style: normal;
  font-weight: 300;
  font-size: 1.07vh;

  text-decoration-line: underline;

  color: #dedede;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.42vh;
    margin-top: 4.74vh;
  `};
`

export const FooterItem = styled.div`
  cursor: pointer;
`

export const GroundRulesItem = styled.div`
  margin-left: 1.56vw;
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-left: 6.92vw;
  `};
`

import { useWeb3React } from '@web3-react/core'
import AbortController from 'abort-controller'
import { sendEvent } from 'components/Analytics'
import * as S from 'components/PlacesList/styles'
import { useWindowSize } from 'hooks/useWindowSize'
import { useEffect, useMemo, useState } from 'react'
import { useModalOpen, useTogglePlaces } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { useAppSelector } from 'state/hooks'
import { useGetSearchBuildingMutation } from 'state/projectApi/slice'

import PlaceItem from './PlaceItem'

export default function PlacesList() {
  const open = useModalOpen(ApplicationModal.PLACES_LIST)
  const toggle = useTogglePlaces()
  const { account } = useWeb3React()

  const [places, setPlaces] = useState([])
  const [getSearchBuildings] = useGetSearchBuildingMutation()

  const searchString = useAppSelector((state) => state.application.mapSearchText)
  const [controller, setController] = useState(null as any)

  const size = useWindowSize()
  const pageHeight = useMemo(() => {
    return size.clientHeight ? size.clientHeight - 46 : 0
  }, [size])

  useEffect(() => {
    if (searchString.length > 0) {
      if (controller) {
        controller.abort()
      }
      const temp = new AbortController()
      setController(temp)
      getSearchBuildings({ name: searchString, signal: temp.signal })
        .then((res: any) => {
          if (res?.data?.code === 0) {
            setPlaces(res.data.data.data)
            if (account) {
              sendEvent({
                category: 'Search',
                action: 'Search as member',
                label: searchString
              })
            } else {
              sendEvent({
                category: 'Search',
                action: 'Search as guest',
                label: searchString
              })
            }
            if (!open) {
              toggle()
            }
          }
        })
        .catch((e) => {
          console.error(e)
        })
    }
    // don't add collections, it will occur circle call
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString])
  return (
    <>
      {open && (
        <S.PlacesListPanel pageheight={pageHeight}>
          <S.HeaderText>Places</S.HeaderText>
          <S.PlacesList innerHeight={pageHeight}>
            {places.map((item: any) => {
              return <PlaceItem key={item?.id + Math.random()} item={item}></PlaceItem>
            })}
          </S.PlacesList>
        </S.PlacesListPanel>
      )}
    </>
  )
}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { CHAIN_API_URL, SupportedChainId } from 'constants/chains'
import store, { customValidateStatus } from 'state'
import { ResponseBackend } from 'state/userApi/types'

function getBaseUri(): any {
  const chainId = store?.getState()?.application?.chainId
    ? store?.getState()?.application?.chainId
    : SupportedChainId.MAINNET

  const apiUrl = chainId ? CHAIN_API_URL[chainId] : process.env.REACT_APP_API_GATEWAY
  return apiUrl
}

export const projectApi = createApi({
  reducerPath: 'projectApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUri(),
    prepareHeaders: (headers) => {
      const { user } = store?.getState()
      if (user && user.userInfo && user.userInfo.accessToken) {
        headers.set('Authorization', `Bearer ${user.userInfo.accessToken}`)
      }
      headers.set('x-api-key', 'AM2Ag22KKSasPLXpgIxyR5SGOek1ftXr49YqB2m3')
      return headers
    },
    fetchFn: (...args) => {
      // if (window.AwsWafIntegration) {
      //   console.log('use AwsWafIntegration')
      //   return window.AwsWafIntegration.fetch(...args)
      // } else {
      return fetch(...args)
      // }
    }
  }),
  endpoints: (build) => ({
    getProjectInfo: build.mutation<any, any>({
      query: (params: any) => ({
        url: `/v1/project/project/info`,
        method: 'GET',
        params,
        validateStatus: customValidateStatus
      })
    }),
    getMapBuilding: build.mutation<any, any>({
      query: (params: any) => ({
        url: `/v1/project/map/buildings`,
        method: 'GET',
        params,
        validateStatus: customValidateStatus
      })
    }),
    get3dMapBuilding: build.mutation<any, any>({
      query: (params: any) => ({
        url: `/v1/project/map/buildings/3d`,
        method: 'GET',
        params,
        validateStatus: customValidateStatus
      })
    }),
    getSearchBuilding: build.mutation<any, any>({
      query: (params: any) => ({
        url: `/v1/project/search/buildings`,
        method: 'GET',
        params,
        signal: params.signal,
        validateStatus: customValidateStatus
      })
    }),
    getBuildingInfo: build.mutation<any, any>({
      query: (params: any) => ({
        url: `/v1/project/building/info`,
        method: 'GET',
        params,
        validateStatus: customValidateStatus
      })
    }),
    getBuildingProperty: build.mutation<any, any>({
      query: (params: any) => ({
        url: `/v1/project/building/property`,
        method: 'GET',
        params,
        validateStatus: customValidateStatus
      })
    }),
    getPropertyInfo: build.mutation<any, any>({
      query: (params: any) => ({
        url: `/v1/project/property/info`,
        method: 'GET',
        params,
        validateStatus: customValidateStatus
      })
    }),
    getPropertyVR: build.mutation<any, any>({
      query: (params: any) => ({
        url: `/v1/project/property/vr`,
        method: 'GET',
        params,
        validateStatus: customValidateStatus
      })
    }),
    getBuildingVR: build.mutation<any, any>({
      query: (params: any) => ({
        url: `/v1/project/building/vr`,
        method: 'GET',
        params,
        validateStatus: customValidateStatus
      })
    }),
    getCollectionList: build.mutation<any, any>({
      query: (params: any) => ({
        url: `/v1/project/collection/list`,
        method: 'GET',
        params,
        validateStatus: customValidateStatus
      })
    }),
    getDepositList: build.mutation<any, any>({
      query: (params: any) => ({
        url: `/v1/project/deposit/list`,
        method: 'GET',
        params,
        validateStatus: customValidateStatus
      })
    }),
    getDepositInfoById: build.mutation<any, any>({
      query: (params: any) => ({
        url: `/v1/project/deposit/info`,
        method: 'GET',
        params,
        validateStatus: customValidateStatus
      })
    }),
    addCollection: build.mutation<
      ResponseBackend,
      {
        buildingId: string
      }
    >({
      query: (body: any) => ({
        url: `/v1/project/collection/add`,
        method: 'POST',
        body,
        validateStatus: customValidateStatus
      })
    }),
    removeCollection: build.mutation<
      ResponseBackend,
      {
        collectionId: string
      }
    >({
      query: (body: any) => ({
        url: `/v1/project/collection/remove`,
        method: 'POST',
        body,
        validateStatus: customValidateStatus
      })
    })
  })
})

export const {
  useGetProjectInfoMutation,
  useGetMapBuildingMutation,
  useGet3dMapBuildingMutation,
  useGetSearchBuildingMutation,
  useGetBuildingInfoMutation,
  useGetBuildingPropertyMutation,
  useGetPropertyInfoMutation,
  useGetPropertyVRMutation,
  useGetBuildingVRMutation,
  useGetCollectionListMutation,
  useAddCollectionMutation,
  useRemoveCollectionMutation,
  useGetDepositInfoByIdMutation,
  useGetDepositListMutation
} = projectApi

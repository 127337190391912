import { useWeb3React } from '@web3-react/core'
import Express from 'assets/images/icon-express.png'
import JCB from 'assets/images/icon-jcb.png'
import Master from 'assets/images/icon-master.png'
import Visa from 'assets/images/icon-visa.png'
import Circle from 'assets/svg/blue-loader.svg'
import * as S from 'components/BuyProperty/styles'
import { parseUnits } from 'ethers/lib/utils'
import { useCallback, useEffect, useState } from 'react'
import { useAddPopup } from 'state/application/hooks'
import { useAppSelector } from 'state/hooks'
import {
  useAddPendingTxMutation,
  useCheckSalesQtyMutation,
  useGetEthPriceMutation,
  usePayCryptoOrderMutation,
  usePayOrderMutation
} from 'state/payApi/slice'
import { useIsTransactionConfirmed, useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'

export default function CheckoutForm({
  productId,
  price,
  onSuccess,
  onPayment
}: {
  productId: string
  price: any
  onSuccess: () => void
  onPayment: (orderId: string, clientSecret: string) => void
}) {
  const daoId = useAppSelector((state) => state.home.daoId)
  const { provider, account } = useWeb3React()

  const [activeTab, setActiveTab] = useState(1)
  const [isAgree, setIsAgree] = useState(false)
  const [legalName, setLegalName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const addPopup = useAddPopup()

  const addTransaction = useTransactionAdder()

  const [createPayOrder] = usePayOrderMutation()
  const [createPayCryptoOrder] = usePayCryptoOrderMutation()
  const [checkSalesQty] = useCheckSalesQtyMutation()
  const [getEthPrice] = useGetEthPriceMutation()
  const [addPendingTx] = useAddPendingTxMutation()

  const [isLoading, setIsLoading] = useState(false)

  const [payAddress, setPayAddress] = useState('')
  const [ethPrice, setEthPrice] = useState('')

  const [orderId, setOrderId] = useState('')
  const [tx, setTx] = useState('')
  const txConfirmed = useIsTransactionConfirmed(tx)

  const checkFormReady = () => {
    return legalName !== '' && email !== '' && phone !== '' && address !== ''
  }

  useEffect(() => {
    if (txConfirmed) {
      setIsLoading(false)
      onSuccess()
    }
  }, [onSuccess, orderId, txConfirmed])

  useEffect(() => {
    if (activeTab === 2) {
      getEthPrice(null)
        .then((data: any) => {
          if (data?.data?.code === 0) {
            setEthPrice(data?.data?.data)
          }
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }, [activeTab, createPayCryptoOrder, daoId, getEthPrice, productId])

  const handleSubmit = useCallback(
    (e: any) => {
      e.preventDefault()
      setIsLoading(true)
      if (isAgree && legalName && email && phone && address) {
        checkSalesQty({ productId })
          .then((data: any) => {
            if (data?.data?.code === 0) {
              if (data?.data?.data?.status === 1) {
                if (activeTab === 1) {
                  createPayOrder({
                    productId,
                    daoId: daoId ?? '',
                    client: {
                      email,
                      phone,
                      legalName,
                      address,
                      cardHolderName: ''
                    },
                    products: [],
                    due: '2022-11-20 00:00:00',
                    verifyCard: false,
                    successRedirect: 'test successRedirect',
                    failureRedirect: 'test failureRedirect',
                    cancelRedirect: 'test cancelRedirect',
                    paymentMethod: 'stripe'
                  })
                    .then(async (data: any) => {
                      if (data?.data?.code === 0) {
                        setOrderId(data?.data?.data?.orderId)
                        onPayment(data?.data?.data?.orderId, data?.data?.data?.stripeClientSecret)
                        setIsLoading(false)
                      } else {
                        setIsLoading(false)
                      }
                    })
                    .catch((e) => {
                      console.error(e)
                      setIsLoading(false)
                    })
                } else if (activeTab === 2) {
                  if (provider) {
                    createPayCryptoOrder({
                      productId,
                      daoId: daoId ?? '',
                      originalPrice: 2
                    })
                      .then((data: any) => {
                        if (data?.data?.code === 0 || data?.data?.code === 10700114) {
                          setPayAddress(data?.data?.data?.payAddress)
                          setOrderId(data?.data?.data?.orderId)
                          provider
                            .getSigner(account)
                            .sendTransaction({
                              from: account,
                              to: data?.data?.data?.payAddress,
                              value: parseUnits(data?.data?.data?.asset?.amount, data?.data?.data?.asset?.precision)
                            })
                            .then((response) => {
                              setTx(response?.hash)
                              addTransaction(response, {
                                type: TransactionType.TRANSFER
                              })

                              addPendingTx({ orderId: data?.data?.data?.orderId, txHash: response?.hash })
                                .then((data) => {
                                  console.info('addPendingTx', data)
                                })
                                .catch((e) => {
                                  console.error(e)
                                  setIsLoading(false)
                                })
                            })
                            .catch((e) => {
                              console.error(e)
                              setIsLoading(false)
                            })
                        } else {
                          setIsLoading(false)
                        }
                      })
                      .catch((e) => {
                        console.error(e)
                        setIsLoading(false)
                      })
                  } else {
                    setIsLoading(false)
                    addPopup({ error: 'Error!' })
                  }
                } else {
                  setIsLoading(false)
                }
              } else {
                setIsLoading(false)
              }
            } else {
              setIsLoading(false)
            }
          })
          .catch((e) => {
            console.error(e)
            addPopup({ error: 'Error!' })
            setIsLoading(false)
          })
      } else {
        addPopup({ error: 'Error!' })
        setIsLoading(false)
        return
      }
    },
    [
      account,
      activeTab,
      addPendingTx,
      addPopup,
      addTransaction,
      address,
      checkSalesQty,
      createPayCryptoOrder,
      createPayOrder,
      daoId,
      email,
      isAgree,
      legalName,
      onPayment,
      phone,
      productId,
      provider
    ]
  )

  return (
    <S.DepositInfo>
      <S.PaymentPanel>
        <S.PaymentInfo>
          <S.PaymentTitle>Payment Information</S.PaymentTitle>
          <S.PaymentTabs>
            <S.PaymentTab
              onClick={() => {
                setActiveTab(1)
              }}
            >
              <S.TabTitle>Credit Card</S.TabTitle>
              {activeTab === 1 && <S.ActiveBorder></S.ActiveBorder>}
            </S.PaymentTab>
            {/*<S.PaymentTab
              onClick={() => {
                setActiveTab(2)
              }}
            >
              <S.TabTitle>Cryptocurrency</S.TabTitle>
              {activeTab === 2 && <S.ActiveBorder></S.ActiveBorder>}
            </S.PaymentTab>*/}
          </S.PaymentTabs>
          {activeTab === 1 && (
            <S.CardPanel>
              <S.ColumnPanel>
                <S.CardTypeImage src={Visa} alt="visa"></S.CardTypeImage>
                <S.CardTypeImage src={Master} alt="master"></S.CardTypeImage>
                <S.CardTypeImage src={Express} alt="express"></S.CardTypeImage>
                <S.CardTypeImage src={JCB} alt="jcb"></S.CardTypeImage>
              </S.ColumnPanel>
            </S.CardPanel>
          )}
          {activeTab === 2 && (
            <S.CryptoPanel>
              <S.CryptoTitle>Currency</S.CryptoTitle>
              <S.CryptoType>ETH</S.CryptoType>
              <S.CryptoTitle>Wallet Address</S.CryptoTitle>
              <S.CyptoAddress>{payAddress}</S.CyptoAddress>
              <S.CryptoTitle>Deposit Amount</S.CryptoTitle>
              <S.AmountPanel>
                <S.AmountText>{`${price?.currency}$${price?.stripe}`}</S.AmountText>
                {ethPrice && <S.EthText>{(price?.stripe / parseFloat(ethPrice)).toFixed(4)} ETH</S.EthText>}
              </S.AmountPanel>
            </S.CryptoPanel>
          )}
        </S.PaymentInfo>
        <S.PaymentInfoCard>
          <S.PaymentTitle>Buyer Information</S.PaymentTitle>
          <S.InputPanel>
            <S.InputLabel>Legal Name</S.InputLabel>
            <S.BuyerInfoInput
              value={legalName}
              onUserInput={function (value: string): void {
                setLegalName(value)
              }}
              placeholder={''}
              fontSize={'16px'}
            ></S.BuyerInfoInput>
          </S.InputPanel>
          <S.NextInputPanel>
            <S.InputLabel>Email</S.InputLabel>
            <S.BuyerInfoInput
              value={email}
              onUserInput={function (value: string): void {
                setEmail(value)
              }}
              placeholder={''}
              fontSize={'16px'}
            ></S.BuyerInfoInput>
          </S.NextInputPanel>
          <S.NextInputPanel>
            <S.InputLabel>Phone</S.InputLabel>
            <S.BuyerInfoInput
              value={phone}
              onUserInput={function (value: string): void {
                setPhone(value)
              }}
              placeholder={''}
              fontSize={'16px'}
            ></S.BuyerInfoInput>
          </S.NextInputPanel>
          <S.NextInputPanel>
            <S.InputLabel>Address</S.InputLabel>
            <S.BuyerInfoInput
              value={address}
              onUserInput={function (value: string): void {
                setAddress(value)
              }}
              placeholder={''}
              fontSize={'16px'}
            ></S.BuyerInfoInput>
          </S.NextInputPanel>
        </S.PaymentInfoCard>
      </S.PaymentPanel>
      <S.AgreePanel>
        <S.AgreeInfo>
          <S.CheckoutColor
            checked={isAgree}
            onClick={() => {
              setIsAgree(!isAgree)
            }}
          />
          I agree to the Yep Privacy Collection Statement
        </S.AgreeInfo>
        <S.SubmitPanel onClick={handleSubmit}>
          {checkFormReady() ? (
            <S.SubmitButton>
              {isLoading ? <S.Loading src={Circle} alt="loader"></S.Loading> : 'SUBMIT Payment'}
            </S.SubmitButton>
          ) : (
            <S.DisableSubmitButton>SUBMIT Payment</S.DisableSubmitButton>
          )}
        </S.SubmitPanel>
      </S.AgreePanel>
    </S.DepositInfo>
  )
}

import AddIcon from 'assets/images/icon-add.png'
import CloseIcon from 'assets/images/icon-close.png'
import * as S from 'components/CreatePost/styles'
import { FileUploader } from 'react-drag-drop-files'

export default function PostImage({
  url,
  isUpload,
  handleChange,
  handleDelete
}: {
  url: string
  isUpload: boolean
  handleChange: (file: any) => void
  handleDelete: () => void
}) {
  const fileTypes = ['png', 'jpg', 'jpeg', 'webp', 'avif']

  return (
    <S.EmptyImage empty={!url && !isUpload} isUpload={isUpload}>
      {url && (
        <>
          <S.PostImage src={url} alt={'post-image'}></S.PostImage>
          <S.DeleteIcon onClick={handleDelete} src={CloseIcon} alt={'detete-image'}></S.DeleteIcon>
        </>
      )}
      {isUpload && (
        <FileUploader handleChange={handleChange} name="file" types={fileTypes} maxSize={1024}>
          <S.UploadPanel>
            <S.AddIcon src={AddIcon} alt={'add-icon'}></S.AddIcon>
            <S.UploadText>Choose a file or drag it here.</S.UploadText>
          </S.UploadPanel>
        </FileUploader>
      )}
    </S.EmptyImage>
  )
}

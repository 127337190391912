/**
 * List of all the networks supported by the Uniswap Interface
 */
export enum SupportedChainId {
  MAINNET = 1,
  GOERLI = 5
}

export const CHAIN_IDS_TO_NAMES = {
  [SupportedChainId.MAINNET]: 'mainnet',
  [SupportedChainId.GOERLI]: 'goerli'
}

export const CHAIN_API_URL: Record<number, string> = {
  [SupportedChainId.MAINNET]: process.env.REACT_APP_API_GATEWAY ?? 'https://prod.api.yephomes.org',
  [SupportedChainId.GOERLI]: process.env.REACT_APP_API_GATEWAY ?? 'https://prod.api.yephomes.org'
}
/**
 * Array of all the supported chain IDs
 */
export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = Object.values(SupportedChainId).filter(
  (id) => typeof id === 'number'
) as SupportedChainId[]

export const SUPPORTED_GAS_ESTIMATE_CHAIN_IDS = [SupportedChainId.MAINNET]

/**
 * All the chain IDs that are running the Ethereum protocol.
 */
export const L1_CHAIN_IDS = [SupportedChainId.MAINNET, SupportedChainId.GOERLI] as const

export type SupportedL1ChainId = typeof L1_CHAIN_IDS[number]

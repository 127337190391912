import * as S from 'components/NotificationList/styles'
import { getChatTime } from 'constants/misc'
import { doc, onSnapshot, updateDoc } from 'firebase/firestore'
import { useWindowSize } from 'hooks/useWindowSize'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  useModalOpen,
  useToggleGroundRules,
  useToggleNotification,
  useTogglePostComments
} from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { updateBuildingId, updateBuildingInfo, updateDaoId, updatePostInfo } from 'state/home/reducer'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { useGetPostInfoMutation } from 'state/postApi/slice'
import { useGetBuildingInfoMutation } from 'state/projectApi/slice'
import { db } from 'utils/firestore'

export default function NotificationList() {
  const open = useModalOpen(ApplicationModal.NOTIFICATIONS)
  const currentUserInfo = useAppSelector((state) => state.user.userInfo)
  const toggle = useToggleNotification()
  const toggleComments = useTogglePostComments()
  const dispatch = useAppDispatch()
  const toggleGround = useToggleGroundRules()

  const [messages, setMessages] = useState([] as any[])
  const [getPostInfo] = useGetPostInfoMutation()
  const [getBuildingInfo] = useGetBuildingInfoMutation()

  const size = useWindowSize()
  const pageHeight = useMemo(() => {
    return size.clientHeight ? size.clientHeight - 46 : 0
  }, [size])

  useEffect(() => {
    const getNotifications = () => {
      const unNotifySub = onSnapshot(doc(db as any, 'userNotifications', currentUserInfo?.userId ?? ''), (doc: any) => {
        doc.exists() && setMessages(doc.data()?.messages)
      })

      return () => {
        unNotifySub()
      }
    }

    currentUserInfo?.userId && getNotifications()
  }, [currentUserInfo?.userId])

  const handleNotificationClick = useCallback(
    (item: any) => {
      getPostInfo({ postId: item?.postId })
        .then((data: any) => {
          if (data?.data?.code === 0) {
            dispatch(updatePostInfo({ postInfo: data?.data?.data }))
            dispatch(updateDaoId({ daoId: item?.daoId }))
            dispatch(updateBuildingId({ buildingId: item?.community?.id }))
            toggleComments()
            for (let i = 0; i < messages.length; i++) {
              if (messages[i].id === item?.id) {
                messages[i].hasUnread = false
              }
            }
            if (currentUserInfo?.userId) {
              updateDoc(doc(db, 'userNotifications', currentUserInfo?.userId), {
                messages
              }).catch((e) => {
                console.error(e)
              })
            }
          }
        })
        .catch((e) => {
          console.error(e)
        })
      getBuildingInfo({ buildingId: item?.community?.id })
        .then((data: any) => {
          if (data?.data?.code === 0) {
            dispatch(updateBuildingInfo({ buildingInfo: data?.data?.data }))
          }
        })
        .catch((e) => {
          console.error(e)
        })
    },
    [currentUserInfo?.userId, dispatch, getBuildingInfo, getPostInfo, messages, toggleComments]
  )

  const handleDeleteClick = useCallback(
    (item: any) => {
      for (let i = 0; i < messages.length; i++) {
        if (messages[i].id === item?.id) {
          messages[i].hasUnread = false
        }
      }
      if (currentUserInfo?.userId) {
        updateDoc(doc(db, 'userNotifications', currentUserInfo?.userId), {
          messages
        }).catch((e) => {
          console.error(e)
        })
      }
    },
    [currentUserInfo?.userId, messages]
  )

  return (
    <>
      {open && (
        <S.NotificationsListPanel pageheight={pageHeight}>
          <S.HeaderText>Notifications</S.HeaderText>
          <S.CloseIcon onClick={toggle}>
            <S.CloseColor />
          </S.CloseIcon>
          <S.NotificationsList innerHeight={pageHeight}>
            {messages && messages.length > 0 ? (
              messages.map((item, index) => {
                return (
                  <>
                    {item?.type.indexOf('delete-') !== -1 ? (
                      <S.NotifyItem
                        key={index}
                        onClick={() => {
                          handleDeleteClick(item)
                        }}
                      >
                        <S.NotifyInfo>
                          <S.NormalNotify>Your recent </S.NormalNotify>
                          {item?.type === 'delete-replay'
                            ? ' replay in '
                            : item?.type === 'delete-comment'
                            ? ' comment in '
                            : ' post in '}
                          <S.NormalNotify>{item?.community?.name}</S.NormalNotify>{' '}
                          {' has been deleted due to violation of our '}
                          <S.StyledInLink onClick={toggleGround}>Ground Rule</S.StyledInLink>
                          {', if you would like to dispute this decision, please email '}
                          <S.StyledExtLink href="mailto:support@yephome.io">support@yephome.io</S.StyledExtLink>
                        </S.NotifyInfo>
                        <S.NotifySubInfo>
                          <S.TimeInfo>{getChatTime(item?.date?.seconds * 1000)}</S.TimeInfo>
                          <S.NotifyContent>{item?.hasUnread && <S.NotifyPanel></S.NotifyPanel>}</S.NotifyContent>
                        </S.NotifySubInfo>
                      </S.NotifyItem>
                    ) : (
                      <S.NotifyItem
                        key={index}
                        onClick={() => {
                          handleNotificationClick(item)
                        }}
                      >
                        <S.NotifyInfo>
                          <S.NormalNotify>{item?.sendUser?.displayName}</S.NormalNotify>
                          {item?.type === 'replay' ? ' replied you in ' : ' commented on your post in '}
                          <S.NormalNotify>{item?.community?.name}</S.NormalNotify>
                        </S.NotifyInfo>
                        <S.NotifySubInfo>
                          <S.TimeInfo>{getChatTime(item?.date?.seconds * 1000)}</S.TimeInfo>
                          <S.NotifyContent>{item?.hasUnread && <S.NotifyPanel></S.NotifyPanel>}</S.NotifyContent>
                        </S.NotifySubInfo>
                      </S.NotifyItem>
                    )}
                  </>
                )
              })
            ) : (
              <S.NoContentPanel>
                <S.NoContentTitle>No notifications</S.NoContentTitle>
                <S.NoContentSubTitle>You don’t have any notifications yet</S.NoContentSubTitle>
              </S.NoContentPanel>
            )}
          </S.NotificationsList>
        </S.NotificationsListPanel>
      )}
    </>
  )
}

import { useSpring } from '@react-spring/web'
import Guest from 'assets/images/icon-guest.png'
import IconSearch from 'assets/images/icon-search.png'
import Logo from 'assets/images/logo.png'
import * as S from 'components/SearchPanel/styles'
import { doc, onSnapshot } from 'firebase/firestore'
import { useWindowSize } from 'hooks/useWindowSize'
import { useEffect, useRef, useState } from 'react'
import { useModalOpen, useToggleMenu, useTogglePlaces } from 'state/application/hooks'
import { ApplicationModal, setOpenModal } from 'state/application/reducer'
import { updateMapSearchText } from 'state/application/reducer'
import { updateSelectBuildingName, updateShowMessage, updateShowNotify } from 'state/home/reducer'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { MEDIA_WIDTHS } from 'theme'
import { replaceImageUrl, shortenAddress } from 'utils'
import { db } from 'utils/firestore'

export default function SearchPanel() {
  const userProfile = useAppSelector((state) => state.user.userProfile)
  const currentUserInfo = useAppSelector((state) => state.user.userInfo)
  const selectBuildingName = useAppSelector((state) => state.home.selectBuildingName)
  const showNotify = useAppSelector((state) => state.home.showNotify)
  const showMessage = useAppSelector((state) => state.home.showMessage)
  const searchInputRef = useRef(null as any)

  const open = useModalOpen(ApplicationModal.PLACES_LIST)
  const toggle = useTogglePlaces()
  const [searchText, setSearchText] = useState('')
  const dispatch = useAppDispatch()
  const toggleMenu = useToggleMenu()
  const handleKeyUp = (event: any) => {
    if (event.key === 'Enter') {
      if (open) {
        const placeItems = document.getElementsByClassName('js-placeItem') as HTMLCollectionOf<HTMLElement>
        if (placeItems[0] !== undefined && placeItems[0] != null) {
          placeItems[0].click()
        }
      }
    }
  }

  useEffect(() => {
    if (selectBuildingName) {
      setSearchText(selectBuildingName)
    }
  }, [selectBuildingName])

  const size = useWindowSize()
  const { transform } = useSpring({
    from: { transform: 'translate3d(104.39%,0,0)' },
    transform: currentUserInfo?.userId ? 'translate3d(0%,0,0)' : 'translate3d(104.39%,0,0)',
    config: { duration: 200 }
  })
  const { transform: transformMobile } = useSpring({
    from: { transform: 'translate3d(0,-104.39%,0)' },
    transform: currentUserInfo?.userId ? 'translate3d(0,0%,0)' : 'translate3d(0,-104.39%,0)',
    config: { duration: 200 }
  })

  useEffect(() => {
    const getChats = () => {
      const unUserChatsSub = onSnapshot(doc(db as any, 'userChats', currentUserInfo?.userId ?? ''), (doc: any) => {
        if (doc && doc?.data()) {
          let notify = false
          Object.entries(doc?.data()).forEach((userChat: any) => {
            if (userChat[1].hasUnread) {
              notify = true
              return
            }
          })

          dispatch(updateShowMessage({ notify }))
        }
      })

      return () => {
        unUserChatsSub()
      }
    }

    currentUserInfo?.userId && getChats()
  }, [currentUserInfo?.userId, dispatch])

  useEffect(() => {
    const getNotifications = () => {
      const unNotifySub = onSnapshot(doc(db as any, 'userNotifications', currentUserInfo?.userId ?? ''), (doc: any) => {
        if (doc && doc?.data() && doc?.data().messages) {
          let notify = false
          Object.entries(doc?.data().messages).forEach((message: any) => {
            if (message[1].hasUnread) {
              notify = true
              return
            }
          })
          dispatch(updateShowNotify({ notify }))
        }
      })

      return () => {
        unNotifySub()
      }
    }

    currentUserInfo?.userId && getNotifications()
  }, [currentUserInfo?.userId, dispatch])

  return (
    <S.SearchPanel
      style={{
        transform: (size?.width ?? MEDIA_WIDTHS.upToMedium) <= MEDIA_WIDTHS.upToSmall ? transformMobile : transform
      }}
    >
      <S.Header>
        <S.Logo src={Logo} alt={'Logo'}></S.Logo>
        <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }} onClick={toggleMenu}>
          <S.AccountStatus>
            <S.AccountText>
              {userProfile?.nickName ||
                (currentUserInfo?.userId && !currentUserInfo?.isGuest ? shortenAddress(currentUserInfo?.userId) : '')}
            </S.AccountText>
          </S.AccountStatus>
          <S.AccountIconWrapper>
            <S.AvatarImage
              src={userProfile?.avatar ? replaceImageUrl(userProfile?.avatar, 200) : Guest}
              alt={'avatar'}
            ></S.AvatarImage>
          </S.AccountIconWrapper>
          {(showNotify || showMessage) && <S.NotifyPanel></S.NotifyPanel>}
        </div>
      </S.Header>
      <S.Div />
      <S.SearchColumn>
        <S.SearchIcon src={IconSearch} alt={'Search Icon'}></S.SearchIcon>
        <S.SearchInput
          refInput={searchInputRef}
          onKeyUp={handleKeyUp}
          value={searchText}
          onUserInput={function (value: string): void {
            if (selectBuildingName) {
              if (value === selectBuildingName) {
                return
              } else {
                dispatch(updateSelectBuildingName({ selectBuildingName: undefined }))
              }
            }
            setSearchText(value)
            dispatch(updateMapSearchText({ searchText: value }))
            if (value.length === 0) {
              toggle()
            }
          }}
          placeholder={'Search suburb, postcode or state'}
          fontSize={'16px'}
        ></S.SearchInput>
        {searchText.length > 0 && (
          <S.CloseIcon
            onClick={() => {
              setSearchText('')
              dispatch(setOpenModal(null))
              dispatch(updateSelectBuildingName({ selectBuildingName: undefined }))
              if (searchInputRef.current) {
                searchInputRef.current.focus()
              }
            }}
          >
            <S.CloseColor />
          </S.CloseIcon>
        )}
      </S.SearchColumn>
    </S.SearchPanel>
  )
}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { CHAIN_API_URL, SupportedChainId } from 'constants/chains'
import store, { customValidateStatus } from 'state'

function getBaseUri(): any {
  const chainId = store?.getState()?.application?.chainId
    ? store?.getState()?.application?.chainId
    : SupportedChainId.MAINNET

  const apiUrl = chainId ? CHAIN_API_URL[chainId] : process.env.REACT_APP_API_GATEWAY
  return apiUrl
}

export const daoApi = createApi({
  reducerPath: 'daoApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUri(),
    prepareHeaders: (headers) => {
      const { user } = store?.getState()
      if (user && user.userInfo && user.userInfo.accessToken) {
        headers.set('Authorization', `Bearer ${user.userInfo.accessToken}`)
      }
      headers.set('x-api-key', 'AM2Ag22KKSasPLXpgIxyR5SGOek1ftXr49YqB2m3')
      return headers
    },
    fetchFn: (...args) => {
      // if (window.AwsWafIntegration) {
      //   console.log('use AwsWafIntegration')
      //   return window.AwsWafIntegration.fetch(...args)
      // } else {
      return fetch(...args)
      // }
    }
  }),
  endpoints: (build) => ({
    createDao: build.mutation<any, any>({
      query: (params: any) => ({
        url: `/v1/dao/create`,
        method: 'GET',
        params,
        validateStatus: customValidateStatus
      })
    }),
    getDaoInfoByDaoId: build.mutation<any, any>({
      query: (params: any) => ({
        url: `/v1/dao/infobydaoid`,
        method: 'GET',
        params,
        validateStatus: customValidateStatus
      })
    }),
    getDaoInfoByReferenceId: build.mutation<any, any>({
      query: (params: any) => ({
        url: `/v1/dao/infobyreferenceid`,
        method: 'GET',
        params,
        validateStatus: customValidateStatus
      })
    }),
    joinDao: build.mutation<any, any>({
      query: (params: any) => ({
        url: `/v1/dao/join`,
        method: 'GET',
        params,
        validateStatus: customValidateStatus
      })
    }),
    isDaoMember: build.mutation<any, any>({
      query: (params: any) => ({
        url: `/v1/dao/is-member`,
        method: 'GET',
        params,
        validateStatus: customValidateStatus
      })
    }),
    getDaoTask: build.mutation<any, any>({
      query: (params: any) => ({
        url: `/v1/dao/task/${params.type}`,
        method: 'GET',
        params,
        validateStatus: customValidateStatus
      })
    }),
    getProjectPropertyInfo: build.mutation<any, any>({
      query: (params: any) => ({
        url: `/v1/dao/getprojectpropertyinfo`,
        method: 'GET',
        params,
        validateStatus: customValidateStatus
      })
    }),
    getProjectPropertyVR: build.mutation<any, any>({
      query: (params: any) => ({
        url: `/v1/dao/getprojectpropertyvr`,
        method: 'GET',
        params,
        validateStatus: customValidateStatus
      })
    }),
    dailyVisit: build.mutation<any, any>({
      query: (params: any) => ({
        url: `/v1/dao/daily-visit`,
        method: 'GET',
        params,
        validateStatus: customValidateStatus
      })
    })
  })
})

export const {
  useCreateDaoMutation,
  useGetDaoInfoByDaoIdMutation,
  useGetDaoInfoByReferenceIdMutation,
  useGetProjectPropertyInfoMutation,
  useGetProjectPropertyVRMutation,
  useDailyVisitMutation
} = daoApi

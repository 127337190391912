import { useSpring } from '@react-spring/web'
import ArrowLeft from 'assets/images/icon-arrow-left.png'
import ArrowRight from 'assets/images/icon-arrow-right.png'
import * as S from 'components/PostImagePanel/styles'
import ReloadImage from 'components/ReloadImage'
import { CustomizeEvent } from 'constants/events'
import { useWindowSize } from 'hooks/useWindowSize'
import { useEffect, useMemo, useRef } from 'react'
import { useLeftModalOpen, useTogglePostImage } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { useAppSelector } from 'state/hooks'
import { MEDIA_WIDTHS } from 'theme'
import useBus from 'use-bus'
import { replaceImageUrl } from 'utils'

export default function PostImagePanel() {
  const open = useLeftModalOpen(ApplicationModal.POST_LARGE_IMAGE)
  const toggle = useTogglePostImage()
  const postImages = useAppSelector((state: any) => state.home.postImages)
  const currentImage = useAppSelector((state: any) => state.home.currentImage)
  const sliderRef = useRef(null as any)
  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(currentImage)
    }
  }, [currentImage])

  const size = useWindowSize()
  const isMobile = (size?.width ?? MEDIA_WIDTHS.upToMedium) <= MEDIA_WIDTHS.upToSmall
  const style = useSpring({
    from: { opacity: 0, display: 'none', transform: isMobile ? 'translate3d(-100%,0,0)' : 'translate3d(-72.98vw,0,0)' },
    opacity: open ? 1 : 0,
    display: open ? 'flex' : 'none',
    transform: open ? 'translate3d(0%,0,0)' : isMobile ? 'translate3d(-100%,0,0)' : 'translate3d(-72.98vw,0,0)',
    config: { duration: 200 }
  })
  useBus(
    CustomizeEvent.EVENT_ARROW_LEFT,
    () => {
      if (sliderRef.current) {
        sliderRef.current.slickPrev()
      }
    },
    []
  )

  useBus(
    CustomizeEvent.EVENT_ARROW_RIGHT,
    () => {
      if (sliderRef.current) {
        sliderRef.current.slickNext()
      }
    },
    []
  )

  const innerHeight = useMemo(() => {
    return size.clientHeight ? size.clientHeight : 0
  }, [size])

  return (
    <S.Panel style={style} innerheight={innerHeight}>
      <S.CloseIcon onClick={toggle}>
        <S.CloseColor />
      </S.CloseIcon>
      <S.Content>
        <S.ImageDetail
          ref={sliderRef}
          dots={false}
          infinite={true}
          draggable={true}
          slidesToShow={1}
          slidesToScroll={1}
          nextArrow={<S.ArrowRightButton src={ArrowRight} alt="arrow-right"></S.ArrowRightButton>}
          prevArrow={<S.ArrowButton src={ArrowLeft} alt="arrow-left"></S.ArrowButton>}
        >
          {postImages &&
            postImages.map((item: any, index: number) => {
              return <ReloadImage key={index} src={replaceImageUrl(item, 2048)} alt={'large-post-detail'}></ReloadImage>
            })}
        </S.ImageDetail>
      </S.Content>
    </S.Panel>
  )
}

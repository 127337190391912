import { useSpring } from '@react-spring/web'
import Messages from 'components/ChatInfoPanel/Messages'
import * as S from 'components/ChatInfoPanel/styles'
import { doc, onSnapshot, updateDoc } from 'firebase/firestore'
import { useWindowSize } from 'hooks/useWindowSize'
import { useEffect, useMemo, useState } from 'react'
import { useLeftModalOpen, useToggleChatInfo, useToggleMobileChatInfo } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { updateChatInfo, updateOtherId } from 'state/home/reducer'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { MEDIA_WIDTHS } from 'theme'
import { db } from 'utils/firestore'

export default function ChatInfoPanel() {
  const open = useLeftModalOpen(ApplicationModal.CHATINFO)
  const toggleChatInfo = useToggleChatInfo()
  const toggleMobileChatInfo = useToggleMobileChatInfo()
  const [chats, setChats] = useState([] as any[])
  const currentUserInfo = useAppSelector((state) => state.user.userInfo)
  const chatInfo = useAppSelector((state) => state.home.chatInfo)
  const dispatch = useAppDispatch()

  const size = useWindowSize()
  const isMobile = (size?.width ?? MEDIA_WIDTHS.upToMedium) <= MEDIA_WIDTHS.upToSmall
  const style = useSpring({
    from: { opacity: 0, display: 'none', transform: isMobile ? 'translate3d(-100%,0,0)' : 'translate3d(-72.98vw,0,0)' },
    opacity: open ? 1 : 0,
    display: open ? 'flex' : 'none',
    transform: open ? 'translate3d(0%,0,0)' : isMobile ? 'translate3d(-100%,0,0)' : 'translate3d(-72.98vw,0,0)',
    config: { duration: 200 }
  })

  const innerHeight = useMemo(() => {
    return size.clientHeight ? size.clientHeight : 0
  }, [size])

  useEffect(() => {
    const getChats = () => {
      const unsub = onSnapshot(doc(db as any, 'userChats', currentUserInfo?.userId ?? ''), (doc: any) => {
        if (doc && doc?.data()) {
          setChats(doc?.data())
        }
      })

      return () => {
        unsub()
      }
    }

    currentUserInfo?.userId && getChats()
  }, [currentUserInfo?.userId])

  return (
    <S.Panel style={style} innerheight={innerHeight}>
      <S.CloseIcon onClick={toggleChatInfo}>
        <S.CloseColor />
      </S.CloseIcon>
      <S.Content>
        <S.ChatListPanel>
          {Object.entries(chats)
            ?.sort((a, b) => b[1].date - a[1].date)
            ?.map((chat, index) => {
              if (!chatInfo && index === 0) {
                dispatch(
                  updateChatInfo({
                    chatInfo: {
                      chatId: chat[0],
                      user: chat[1].userInfo
                    }
                  })
                )
                dispatch(updateOtherId({ userId: chat[1].userInfo.uid }))
              }
              return (
                <S.ChatListItem
                  key={chat[0]}
                  actived={chatInfo?.chatId === chat[0]}
                  onClick={() => {
                    if (chatInfo?.chatId === chat[0]) return
                    if (currentUserInfo?.userId) {
                      updateDoc(doc(db, 'userChats', currentUserInfo?.userId), {
                        [chatInfo?.chatId + '.hasUnread']: false
                      })
                    }
                    dispatch(updateChatInfo({ chatInfo: { chatId: chat[0], user: chat[1].userInfo } }))
                    dispatch(updateOtherId({ userId: chat[1].userInfo.uid }))
                  }}
                >
                  {chatInfo?.chatId === chat[0] && <S.ActivedLine></S.ActivedLine>}
                  <S.ListItemAvatar actived={chatInfo?.chatId === chat[0]} src={chat[1]?.userInfo?.photoURL} alt="" />
                  <S.ItemInfoPanel>{chat[1]?.userInfo?.displayName}</S.ItemInfoPanel>
                  {chat[1].hasUnread && <S.NotifyIcon></S.NotifyIcon>}
                </S.ChatListItem>
              )
            })}
        </S.ChatListPanel>
        <S.ChatContentPanel>
          <Messages />
        </S.ChatContentPanel>
      </S.Content>
      <S.MobileContent>
        <S.MobileTitle>Messages</S.MobileTitle>
        <S.ChatListPanel>
          {Object.entries(chats)
            ?.sort((a, b) => b[1].date - a[1].date)
            ?.map((chat, index) => {
              if (!chatInfo && index === 0) {
                dispatch(
                  updateChatInfo({
                    chatInfo: {
                      chatId: chat[0],
                      user: chat[1].userInfo
                    }
                  })
                )

                dispatch(updateOtherId({ userId: chat[1].userInfo.uid }))
              }
              return (
                <S.ChatListItem
                  key={chat[0]}
                  actived={true}
                  onClick={() => {
                    if (currentUserInfo?.userId) {
                      updateDoc(doc(db, 'userChats', currentUserInfo?.userId), {
                        [chatInfo?.chatId + '.hasUnread']: false
                      })
                    }
                    dispatch(updateChatInfo({ chatInfo: { chatId: chat[0], user: chat[1].userInfo } }))

                    dispatch(updateOtherId({ userId: chat[1].userInfo.uid }))
                    toggleMobileChatInfo()
                  }}
                >
                  <S.ListItemAvatar actived={true} src={chat[1]?.userInfo?.photoURL} alt="" />
                  <S.ItemInfoPanel>
                    {chat[1]?.userInfo?.displayName}
                    <S.LastMessagePanel>{chat[1]?.lastMessage?.text}</S.LastMessagePanel>
                  </S.ItemInfoPanel>
                  {chat[1].hasUnread && <S.NotifyIcon></S.NotifyIcon>}
                </S.ChatListItem>
              )
            })}
        </S.ChatListPanel>
      </S.MobileContent>
    </S.Panel>
  )
}

import styled from 'styled-components/macro'

export const Close = styled.div`
  width: 35px;
  height: 35px;
  position: absolute;
  right: 1vw;
  top: 2vh;
  z-index: 2147483600 !important;
`

export const FullScreen = styled.div`
  width: 35px;
  height: 35px;
  position: absolute;
  left: 1vw;
  top: 2vh;
  z-index: 2147483600 !important;
`

export const MobilePanel = styled.div<{ visible: boolean }>`
  width: ${({ visible }) => (visible ? '100%' : '1px')};
  height: ${({ visible }) => (visible ? '100%' : '1px')};
  background: #161616;
  position: fixed;
  top: 0;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  z-index: 2147483600 !important;
`

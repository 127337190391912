import { animated } from '@react-spring/web'
import { ButtonEmpty } from 'components/Button'
import ReloadImage from 'components/ReloadImage'
import InfiniteScroll from 'react-infinite-scroller'
import styled from 'styled-components/macro'
import { CommonPanel, PANEL_HEIGHTS } from 'theme'

export const AnimationPanel = styled(animated.div)``

export const Community = styled(CommonPanel)<{ innerheight?: number }>`
  margin-top: 0.54vh;
  position: relative;
  flex: 1;
  width: 100%;
  max-height: ${PANEL_HEIGHTS.right};
  overflow: hidden;
  background: #161616;

  ${({ theme, innerheight }) => theme.mediaWidth.upToExtraLarge`
    max-height: ${innerheight ? innerheight * 0.8846 + 'px' : '85.94vh'};
  `};

  ${({ theme, innerheight }) => theme.mediaWidth.upToLarge`
    max-height: ${innerheight ? innerheight * 0.9094 + 'px' : '86.94vh'};
  `};

  ${({ theme, innerheight }) => theme.mediaWidth.upToSmall`
    margin-top: 1.54vh;
    max-height: ${innerheight ? innerheight * 0.8246 + 'px' : PANEL_HEIGHTS.mobile};
  `};
`

export const MobileScroll = styled.div`
  overflow: hidden;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    overflow: auto;

    border-radius: 8px 8px 8px 8px;

    -webkit-border-radius: 8px 8px 8px 8px;
    -moz-border-radius: 8px 8px 8px 8px;
    -khtml-border-radius: 8px 8px 8px 8px;
    background: transparent;
    z-index: 0;
    /* 滚动槽 */
    ::-webkit-scrollbar {
      width: 0.16vw;
      height: 0.16vw;
    }
    ::-webkit-scrollbar-track {
      border-radius: 0.08vw;
    }
    /* 滚动条滑块 */
    ::-webkit-scrollbar-thumb {
      border-radius: 0.08vw;
    }
  `};
`

export const HeaderPanel = styled.div`
  border-radius: 8px 8px 0px 0px;
  width: 100%;
  max-height: 23.28vh;
  background: #161616;
  position: relative;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-height: 26.66vh;
  `};
`

export const DepositedTip = styled.div`
  display: none;
  position: absolute;
  width: fit-content;
  height: 2.42vh;
  bottom: 0px;
  right: 0px;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.text4};
  background: ${({ theme }) => theme.primary1};
  padding: 0.45vh 0.58vw;
  border-radius: 4px 0px 0px 0px;
`

export const TipContent = styled.span`
  text-align: center;
`

export const TimeContent = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 0.9vh;
  /* identical to box height */
  margin-left: 0.23vw;

  color: rgba(222, 222, 222, 0.6);
`

export const HeaderImage = styled.img`
  height: 23.28vh;
  object-fit: cover;
  border-radius: 8px 8px 0px 0px;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 26.66vh;
  `};
  cursor: pointer;
`

export const MobileHeaderPanel = styled.div<{ fixed?: boolean }>`
  display: flex;
  position: absolute;
  width: 100%;
  height: fit-content;
  bottom: 0px;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.text4};
  background: ${({ theme }) => theme.bg7};
  border-radius: 8px 8px 0px 0px;
  min-height: 4.21vh;
  ${({ theme, fixed }) => theme.mediaWidth.upToSmall`
    height: fit-content;
    min-height: 5.57vh;
    ${fixed ? 'top: 0px' : ''};
    ${fixed ? 'z-index: 3;' : ''};
  `};
`

export const DaoInfoPanel = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  bottom: 0px;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.text4};
  background: ${({ theme }) => theme.bg7};
  border-radius: 8px 8px 0px 0px;

  min-height: 4.21vh;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-height: 5.57vh;
  `}
`

export const InfoContent = styled.div`
  margin: 0.72vh 0.81vw 0.72vh 1.22vw;
  flex: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0.95vh 5.38vw 0.95vh 5.38vw;
  `};
`

export const InfoTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 1.25vh;

  color: ${({ theme }) => theme.text4};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.66vh;
  `};
`

export const InfoSubtitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 0.9vh;
  margin-top: 0.36vh;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.18vh;
    margin-top: 0.47vh;
  `};

  color: rgba(222, 222, 222, 0.6);
`

export const ActionMore = styled.div`
  height: 2.78vh;
  width: 2.78vh;
  position: relative;
  border-radius: 50%;
  margin-right: 0.87vw;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 3.67vh;
    width: 3.67vh;
    margin-right: 3.85vw;
  `};

  &:hover {
    background: rgba(222, 222, 222, 0.06);
  }
`

export const LikeImage = styled.img`
  height: 0.36vh;
  width: 100%;
  margin: 0 0.35vw;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 0.47vh;
    margin: 0 1.54vw;
  `};
`

export const ActionMenuPanel = styled.div`
  background: #161616;
  border: 0.3px solid rgba(222, 222, 222, 0.44);
  position: absolute;
  width: fit-content;
  z-index: 1;
  border-radius: 0.72vh;
  top: 4.03vh;
  right: 0.87vw;
  width: 10.88vw;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    border-radius: 0.95vh;
    top: 5.33vh;
    right: 5.13vw;
    width: 48.21vw;
  `};
`

export const ActionItem = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 1.25vh;
  line-height: 1.43vh;
  border-radius: 0.72vh;
  padding: 0.98vh 0 1.07vh 1.27vw;
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.66vh;
    line-height: 1.9vh;
    border-radius: 0.95vh;
    padding: 1.3vh 0 1.42vh 5.64vw;
  `};

  color: ${({ theme }) => theme.text4};
  &:hover {
    background: ${({ theme }) => theme.bg7};
  }
`

export const Content = styled.div`
  padding: 0px 2.43vw 0px 1.79vw;
  position: relative;
  align-items: center;
  background: #161616;
  ${({ theme }) => theme.mediaWidth.upToSmall`
     padding: 0px 4.62vw 0px 5.13vw;
  `};
`

export const Tabs = styled.div`
  padding-top: 1.34vh;
  padding-bottom: 1.79vh;
  text-align: center;
  border-bottom: 1px solid rgba(222, 222, 222, 0.22);
  display: flex;
  align-items: center;
  justify-content: center;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const FirstTab = styled.div<{ isConfirmation: boolean }>`
  font-style: normal;
  font-weight: 400;
  font-size: 1.43vh;
  color: ${({ theme }) => theme.text4};
  cursor: pointer;
  padding-left: 0.81vw;
  padding-right: 0.81vw;
  ${({ isConfirmation }) => (isConfirmation ? 'flex: 4' : 'flex: 1')};
`

export const Tab = styled(FirstTab)`
  border-left: 1px solid rgba(222, 222, 222, 0.22);
`

export const TabContent = styled.div<{ isToggle: boolean }>`
  background: ${({ isToggle }) => (isToggle ? 'rgba(222, 222, 222, 0.06)' : 'none')};
  border-radius: ${({ isToggle }) => (isToggle ? '8px' : '0px')};
  padding: 4px 0px;
`

export const CreateButton = styled.div`
  background: ${({ theme }) => theme.primary1};
  border-radius: 8px;
  text-align: center;
  padding: 0.54vh 1.33vw;
  width: fit-content;

  font-weight: 400;
  font-size: 1.43vh;

  color: ${({ theme }) => theme.text4};
  position: absolute;
  bottom: 2.06vh;
  cursor: pointer;
  left: 50%;
  transform: translate(-50%);
  ${({ theme }) => theme.mediaWidth.upToSmall`
     padding: 0.71vh 5.9vw;
     font-size: 1.9vh;
     bottom: 2.84vh;
  `};
`

export const ScrollPanel = styled(InfiniteScroll)`
  // ${({ theme }) => theme.mediaWidth.upToSmall`
  //   padding-bottom: 8.41vh;
  // `};
`

export const PostList = styled.div<{ innerheight?: number; showTabs?: number }>`
  overflow: auto;
  height: ${({ showTabs }) => (showTabs ? '52.96vh' : '58.46vh')};
  width: 100%;
  /* 滚动槽 */
  ::-webkit-scrollbar {
    width: 0.16vw;
    height: 0.16vw;
  }
  ::-webkit-scrollbar-track {
    border-radius: 0.08vw;
  }
  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    border-radius: 0.08vw;
  }

  ${({ theme, innerheight }) => theme.mediaWidth.upToExtraLarge`
    height: ${innerheight ? innerheight * 0.6126 + 'px' : '61.96vhvh'};
  `};

  ${({ theme, innerheight }) => theme.mediaWidth.upToLarge`
    height: ${innerheight ? innerheight * 0.6696 + 'px' : '61.96vhvh'};
  `};

  ${({ theme, innerheight }) => theme.mediaWidth.upToSmall`
    min-height: ${innerheight ? innerheight * 0.5504 + 'px' : '55.09vh'};
    height: fit-content;
    overflow: hidden;
  `};
`

export const Grid = styled.div`
  display: grid;
  flex-direction: column;
`

export const Nothing = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 1.25vh;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-top: 21.75vh;

  color: rgba(222, 222, 222, 0.6);
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.66vh;
    padding-top: 13.39vh;
  `};
`

export const CardPanel = styled.div<{ last: boolean }>`
  display: flex;
  flex-direction: row;
  margin-top: 1.79vh;
  position: relative;
  margin-bottom: ${({ last }) => (last ? '3.41vh' : '0')};
  ${({ theme, last }) => theme.mediaWidth.upToSmall`
    margin-top: 2.37vh;
    margin-bottom: ${last ? '8.41vh' : '0'};
  `};
`

export const AvatarPanel = styled.div<{ role: string }>`
  width: 2.95vh;
  height: 2.95vh;
  border-radius: 50%;

  cursor: pointer;
  .reload-image-error {
    border-radius: 50%;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 3.91vh;
    height: 3.91vh;
  `}
`

export const Avatar = styled(ReloadImage)`
  width: 2.95vh;
  height: 2.95vh;
  border-radius: 50%;
  overflow: hidden;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 3.91vh;
    height: 3.91vh;
  `}
  object-fit: cover;
`

export const InfoPanel = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(222, 222, 222, 0.22);
  flex: 1;
  width: 0;
  margin-left: 0.52vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-left: 2.31vw;
  `};
`

export const PostHeader = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 0.81vh;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 1.07vh;
  `};
`

export const AccountName = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 1.07vh;
  color: ${({ theme }) => theme.text4};
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 30%;
  cursor: pointer;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.42vh;
  `};
`

export const AccountType = styled.div<{ role: string }>`
  display: none;
  border-radius: 8px;
  font-style: normal;
  font-weight: 300;
  font-size: 0.9vh;
  text-transform: capitalize;
  color: ${({ role, theme }) => (role === 'customer' ? 'none' : role === 'developer' ? `${theme.text4}` : '#161616')};
  padding: 0.18vh 0.46vw;
  margin-left: 0.46vw;
  background: ${({ role, theme }) =>
    role === 'customer' ? 'none' : role === 'developer' ? `${theme.primary1}` : `${theme.primary2}`};
  ::first-letter {
    text-transform: uppercase;
  }
`

export const MoreInfo = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const TimeInfo = styled.div`
  font-style: normal;
  font-weight: 150;
  font-size: 1.07vh;
  color: rgba(222, 222, 222, 0.6);
  text-overflow: ellipsis;
  overflow: hidden;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.42vh;
  `};
`

export const MoreIcon = styled.div`
  width: 1.04vw;
  height: 1.25vh;
  margin-left: 0.69vw;
  cursor: pointer;
  display: flex;
  align-items: center;
  img {
    width: 100%;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 4.62vw;
    height: 0.47vh;
    margin-left: 3.08vw;
  `};
`

export const PostContent = styled.div`
  margin-top: 0.98vh;
  font-style: normal;
  font-weight: 300;
  font-size: 1.25vh;
  color: rgba(222, 222, 222, 0.8);
  word-wrap: break-word;

  p {
    white-space: pre-line;
    margin: 0;
    a {
      color: rgba(222, 222, 222, 0.8);
    }
  }

  pre {
    white-space: pre-line;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 1.3vh;
    font-size: 1.66vh;
  `};
`

export const DeleteButton = styled(ButtonEmpty)`
  float: right;
  position: absolute;
  width: fit-content;
  right: 0.1vh;
  background: #161616;
  border: 1px solid rgba(222, 222, 222, 0.22);
  border-radius: 0.36vh;
  padding: 0.72vh 1.68vw;
  color: ${({ theme }) => theme.text4};
  font-style: normal;
  font-weight: 400;
  font-size: 1.43vh;
  text-decoration: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    border-radius: 0.47vh;
    padding: 0.95vh 7.44vw;
    font-size: 1.9vh;
  `};
`

export const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.36vh 0.23vw;
  justify-content: center;
  margin-top: 0.98vh;
  max-width: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-gap: 0.47vh 1.03vw;
    margin-top: 2.49vh;
  `};
`

export const PostImagePanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .reload-image-error {
    border-radius: 4px;
  }
  &:before {
    content: ' ';
    /* 设为块元素，宽度撑满 */
    display: block;
    background-color: blue;
    /* padding-bottom和padding-top百分比是取的父元素的宽度 */
    padding-bottom: 100%;
  }
`

export const PostSingleImagePanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  .reload-image-error {
    border-radius: 4px;
  }
`

export const PostImage = styled(ReloadImage)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
`

export const PostSingleImage = styled(ReloadImage)`
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
`

export const CommentsPanel = styled.div`
  background: rgba(222, 222, 222, 0.06);
  border-radius: 8px;
  padding: 0.45vh 0;
  text-align: center;
  margin: 0.9vh 0px;

  font-style: normal;
  font-weight: 300;
  font-size: 1.25vh;

  color: rgba(222, 222, 222, 0.6);
  cursor: pointer;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0.59vh 0;
    margin: 1.3vh 0px;
    font-size: 1.66vh;
  `};
`
export const SliderButton = styled.div`
  position: absolute;
  width: 0.75vw;
  height: 6.89vh;

  background: ${({ theme }) => theme.primary1};
  border-radius: 8px;
  bottom: 2.06vh;
  left: -0.375vw;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

export const ArrowIcon = styled.img`
  height: 1.07vh;
`

import { ReactComponent as Close } from 'assets/svg/x.svg'
import styled from 'styled-components/macro'
import { CommonPanel, PANEL_HEIGHTS } from 'theme'

export const Panel = styled(CommonPanel)<{ width?: number | undefined; innerheight?: number }>`
  position: relative;
  margin-right: 1.45vw;
  height: 100%;
  flex: 1;
  height: ${PANEL_HEIGHTS.left};

  ${({ theme, innerheight }) => theme.mediaWidth.upToExtraLarge`
    height: ${innerheight ? innerheight * 0.955 + 'px' : '96.5vh'};
    margin-right: 0.83vw;
  `};

  ${({ theme, innerheight }) => theme.mediaWidth.upToLarge`
    height: ${innerheight ? innerheight * 0.975 + 'px' : '97.5vh'};
    margin-right: 0.83vw;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 0px;
  `};
`

export const CloseIcon = styled.div`
  position: absolute;
  top: 1.61vh;
  right: 1.04vw;
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 2.49vh;
    right: 5.38vw;
  `}
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`
export const CloseColor = styled(Close)`
  color: ${({ theme }) => theme.text4};
  width: 0.87vw;
  height: 0.87vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 3.85vw;
    height: 3.85vw;
  `}
`

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 14px 22px 32px 14px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0;
  `}
`
export const SketcherFabDiv = styled.div`
  width: inherit;
`

export const ImagePanel = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-height: 49.52vh;
  min-height: 49.52vh;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    max-height: fit-content;
    min-height: fit-content;
  `}
`

export const ImageDetailPanel = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-right: 0.69vw;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-right: 0;
  `}
`

export const DetailImage = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  max-height: 100%;

  img {
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 0.71vh;

    ${({ theme }) => theme.mediaWidth.upToSmall`
      border-radius: 0.95vh 0.95vh 0px 0px;
      height: 27.01vh;
    `}
  }
`

export const ArrowLeftPanel = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0.87vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `}
`

export const ArrowRightPanel = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0.87vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `}
`

export const ArrowIcon = styled.img`
  height: 1.79vh;
  cursor: pointer;
`

export const ImageSlider = styled.div`
  width: 5.9vw;
  padding: 1.96vh 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    padding: 1.42vh 0;
  `}
`

export const ArrowSliderPanel = styled.div`
  margin: 1.96vh 0;
  flex: 1;
  display: block;
  overflow: auto;
  /* 滚动槽 */
  ::-webkit-scrollbar {
    width: 0.16vw;
    height: 0.16vw;
  }
  ::-webkit-scrollbar-track {
    border-radius: 0.08vw;
  }
  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    border-radius: 0.08vw;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0;
    max-width: 100%;
    display: flex;
  `}
`

export const SliderImage = styled.img<{ active: boolean }>`
  width: 5.9vw;
  max-height: 5.09vh;
  cursor: pointer;
  border: ${({ active }) => (active ? '1px solid #dedede' : 'none')};
  border-radius: 0.71vh;
  margin-left: 0;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    border-radius: 0.95vh;
    margin-left: 2.05vw;
    max-height: 6.87vh;
    width: 26.15vw;
  `}
`

export const ArrowSliderIcon = styled.img`
  width: 1.74vw;
  height: 1.34vh;
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `}
`

export const PropertyPanel = styled.div`
  flex: 1;
  margin-top: 2.68vh;
  margin-bottom: 2.68vh;
  width: 100%;
  overflow: auto;
  /* 滚动槽 */
  ::-webkit-scrollbar {
    width: 0.16vw;
    height: 0.16vw;
  }
  ::-webkit-scrollbar-track {
    border-radius: 0.08vw;
  }
  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    border-radius: 0.08vw;
  }
`

export const PropertyInfoPanel = styled.div`
  margin-left: 1.27vw;
  width: 100%;
  display: flex;
  flex-direction: row;
  max-height: 6.36vh;
`

export const IntroPanel = styled.div`
  margin-left: 1.27vw;
  margin-right: 1.27vw;
  max-height: 14.62vh;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-left: 6.41vw;
    margin-right: 5.38vw;
  `}
`

export const ProInfoPanel = styled.div`
  flex: 1;
  margin-bottom: 2.68vh;
`

export const Title = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 1.25vh;
  line-height: 1.43vh;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.66vh;
    line-height: 1.9vh;
  `}

  color: ${({ theme }) => theme.text4};
`

export const TextInfo = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 1.43vh;
  line-height: 1.7vh;
  margin-top: 1.25vh;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.9vh;
    line-height: 2.25vh;
    margin-top: 1.66vh;
  `}

  color: rgba(222, 222, 222, 0.6);
`

import { Trans } from '@lingui/macro'
import { TransactionInfo, TransactionType, TransferTransactionInfo } from 'state/transactions/types'

function TransferSummary({ info }: { info: TransferTransactionInfo }) {
  return <Trans>Transfer</Trans>
}

export function TransactionSummary({ info }: { info: TransactionInfo }) {
  switch (info.type) {
    case TransactionType.TRANSFER:
      return <TransferSummary info={info} />
  }
}

import { ReactComponent as Close } from 'assets/svg/x.svg'
import styled from 'styled-components/macro'
import { CommonPanel, PANEL_HEIGHTS } from 'theme'

export const Panel = styled(CommonPanel)<{ width?: number | undefined }>`
  position: relative;
  margin-right: 1.45vw;
  height: fit-content;
  flex: 1;
  max-height: ${PANEL_HEIGHTS.left};
`

export const CloseIcon = styled.div`
  position: absolute;
  right: 18px;
  top: 18px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`
export const CloseColor = styled(Close)`
  color: ${({ theme }) => theme.text4};
  width: 0.87vw;
  height: 0.87vw;
`

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 37px 52px 32px 33px;
`

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const TableTitle = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;

  color: ${({ theme }) => theme.text4};
`

export const FirstCell = styled.div`
  flex: 6;
`

export const UnitCell = styled.div`
  flex: 2;
  text-align: right;
`

export const MaxCell = styled.div`
  flex: 2;
  text-align: right;
`

export const TableContent = styled.div<{ lastItem: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding: 15px 0px;

  color: rgba(222, 222, 222, 0.6);
  border-bottom: ${({ lastItem }) => (lastItem ? '0px' : '1px')} solid rgba(222, 222, 222, 0.22);
`

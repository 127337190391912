import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { CustomizeEvent } from 'constants/events'
import multicall from 'lib/state/multicall'
import { load, save } from 'redux-localstorage-simple'
import application from 'state/application/reducer'
import { updateVersion } from 'state/global/actions'
import home from 'state/home/reducer'
import logs from 'state/logs/slice'
import { postApi } from 'state/postApi/slice'
import transactions from 'state/transactions/reducer'
import user from 'state/user/reducer'
import { userApi } from 'state/userApi/slice'
import wallet from 'state/wallet/reducer'
import { dispatch as dispatchEvent } from 'use-bus'

import { daoApi } from './daoApi/slice'
import { payApi } from './payApi/slice'
import { projectApi } from './projectApi/slice'
import { taskApi } from './taskApi/slice'

const PERSISTED_KEYS: string[] = ['user', 'transactions']

const store = configureStore({
  reducer: {
    application,
    user,
    wallet,
    home,
    transactions,
    multicall: multicall.reducer,
    logs,
    [userApi.reducerPath]: userApi.reducer,
    [postApi.reducerPath]: postApi.reducer,
    [daoApi.reducerPath]: daoApi.reducer,
    [taskApi.reducerPath]: taskApi.reducer,
    [payApi.reducerPath]: payApi.reducer,
    [projectApi.reducerPath]: projectApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: true })
      .concat(userApi.middleware)
      .concat(postApi.middleware)
      .concat(daoApi.middleware)
      .concat(taskApi.middleware)
      .concat(projectApi.middleware)
      .concat(payApi.middleware)
      .concat(save({ states: PERSISTED_KEYS, debounce: 1000 })),
  preloadedState: load({ states: PERSISTED_KEYS, disableWarnings: process.env.NODE_ENV === 'test' })
})

store.dispatch(updateVersion())

setupListeners(store.dispatch)

export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const customValidateStatus = (response: any) => {
  if (response.status === 403) {
    dispatchEvent(CustomizeEvent.LOGIN_RELOGIN)
  }

  // if (response.status === 429) {
  //   console.log('response.url', response.url, typeof response.url)
  //   if (response.url.contain('posts/publish')) {
  //     dispatchEvent(CustomizeEvent.EVENT_POST_RATE_LIMIT)
  //   }
  //   if (response.url.contain('posts/comment')) {
  //     dispatchEvent(CustomizeEvent.EVENT_COMMENT_RATE_LIMIT)
  //   }
  // }
  return response.status >= 200 && response.status <= 299
}

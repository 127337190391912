import { ReactComponent as Close } from 'assets/svg/x.svg'
import { CommentTextArea } from 'components/TextInput'
import styled from 'styled-components/macro'
import { CommonPanel, PANEL_HEIGHTS } from 'theme'

export const Panel = styled(CommonPanel)<{ width?: number | undefined; innerheight?: number }>`
  position: relative;
  margin-right: 1.45vw;
  height: 100%;
  flex: 1;
  max-height: ${PANEL_HEIGHTS.left};

  ${({ theme, innerheight }) => theme.mediaWidth.upToExtraLarge`
    max-height: ${innerheight ? innerheight * 0.955 + 'px' : '96.5vh'};
    margin-right: 0.83vw;
  `};

  ${({ theme, innerheight }) => theme.mediaWidth.upToLarge`
    max-height: ${innerheight ? innerheight * 0.975 + 'px' : '97.5vh'};
    margin-right: 0.83vw;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    max-height: 100%;
    border-radius: 0px;
  `};
`

export const CloseIcon = styled.div`
  position: absolute;
  top: 1.61vh;
  right: 1.04vw;
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 2.49vh;
    right: 5.38vw;
  `}
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`
export const CloseColor = styled(Close)`
  color: ${({ theme }) => theme.text4};
  width: 0.87vw;
  height: 0.87vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 3.85vw;
    height: 3.85vw;
  `}
`
export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `}
`

export const MessageHeader = styled.div`
  background: ${({ theme }) => theme.bg7};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const MessageAvatar = styled.img`
  width: 6.52vh;
  height: 6.52vh;
  border-radius: 50%;
  margin: 1.78vh 3.33vw 1.78vh 3.33vw;
`

export const MessageName = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 1.9vh;
  line-height: 2.25vh;
  flex: 1;

  color: ${({ theme }) => theme.text4};
`

export const MessagesContent = styled.div<{ innerHeight?: number }>`
  position: relative;
  overflow-y: auto;

  height: 80.11vh;
  width: 100%;
  padding: 1.9vh 0;

  /* 滚动槽 */
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  ::-webkit-scrollbar-track {
    border-radius: 0.08vw;
  }
  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    border-radius: 0.08vw;
  }

  ${({ theme, innerHeight }) => theme.mediaWidth.upToExtraLarge`
    height: ${innerHeight ? innerHeight * 0.8126 + 'px' : '78.26vh'};
  `};
  ${({ theme, innerHeight }) => theme.mediaWidth.upToLarge`
    height: ${innerHeight ? innerHeight * 0.8356 + 'px' : '79.26vh'};
  `};
  ${({ theme, innerHeight }) => theme.mediaWidth.upToSmall`
    height: ${innerHeight ? innerHeight * 0.7487 + 'px' : '72.87vh'};
  `}
`

export const InputPanel = styled.div`
  display: flex;
  width: 100%;
`

export const InputImagePanel = styled.div`
  background: #222222;
  width: 3.91vh;
  height: 3.91vh;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 3.59vw;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`

export const InputImage = styled.img`
  width: 1.54vh;
  height: 1.54vh;
`

export const CommentInput = styled(CommentTextArea)`
  flex: 1;
  display: flex;
  width: auto;
  align-items: center;
  background: rgba(222, 222, 222, 0.06);
  justify-content: center;

  width: auto;
  align-items: center;
  justify-content: center;
  overflow: auto;
  caret-color: #2142ff;

  max-height: 11.28vh;
  padding: 0.63vh 0.75vw;
  margin-left: 0.52vw;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-left: 2.31vw;
    padding: 0.83vh 3.08vw;
    border-radius: 1.95vh;
    background: ${theme.bg7};
    max-height: 24.64vh;
    color: ${({ theme }) => theme.text4};
  `}

  textarea {
    background-color: transparent;
    caret-color: #2142ff;
    ::placeholder {
      color: rgba(222, 222, 222, 0.22);
    }
  }

  /* 滚动槽 */
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  ::-webkit-scrollbar-track {
    border-radius: 0.08vw;
  }
  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    border-radius: 0.08vw;
  }
`

export const SendButton = styled.div<{ disabled: boolean }>`
  display: block;
  cursor: pointer;
  font-style: normal;
  font-weight: 300;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25vh;
  line-height: 1.43vh;
  margin-left: 2.08vw;
  border-radius: 1.525vh;
  padding: 0.81vh 0.75vw;

  margin-right: 1.85vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.66vh;
    line-height: 1.9vh;

    margin-right: 3.08vw;
    margin-left: 2.31vw;
    border-radius: 1.9vh;
    padding: 0.95vh 4.36vw;
  `}
  color: ${({ disabled, theme }) => (disabled ? 'rgba(222, 222, 222, 0.22)' : theme.text4)};
  background: ${({ disabled, theme }) => (disabled ? '#232323' : theme.primary1)};
`

import { useSpring } from '@react-spring/web'
import TermsOfUseMd from 'assets/mds/Yephome-Term-of-Use.md'
import * as S from 'components/TermsOfUsePanel/styles'
import { useWindowSize } from 'hooks/useWindowSize'
import { useEffect, useMemo, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { useModalOpen, useToggleTermOfUse } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { useAppSelector } from 'state/hooks'
import { ExternalLink } from 'theme/components'

export default function TermsOfUse() {
  const currentUserInfo = useAppSelector((state) => state.user.userInfo)
  const open = useModalOpen(ApplicationModal.TERMOFUSE)

  const toggle = useToggleTermOfUse()

  const [tosText, setTosText] = useState('')

  // Fetch Terms of Use
  useEffect(() => {
    fetch(TermsOfUseMd)
      .then((res) => res.text())
      .then((text) => setTosText(text))
  }, [])

  const style = useSpring({
    from: { opacity: 0, display: 'none', transform: 'translate3d(104.39%,0,0)' },
    opacity: open ? 1 : 0,
    display: open ? 'flex' : 'none',
    transform: open ? 'translate3d(0%,0,0)' : 'translate3d(104.39%,0,0)',
    config: { duration: 200 }
  })
  const size = useWindowSize()
  const innerHeight = useMemo(() => {
    return size.clientHeight ? size.clientHeight - 46 : 0
  }, [size])
  return (
    <S.Panel style={style} innerheight={innerHeight} showall={currentUserInfo?.userId ? 0 : 1}>
      <S.CloseIcon onClick={toggle}>
        <S.CloseColor />
      </S.CloseIcon>
      <S.Content>
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          components={{
            // eslint-disable-next-line react/display-name
            a: ({ ...props }) => <ExternalLink href="" {...props} />
          }}
        >
          {tosText}
        </ReactMarkdown>
      </S.Content>
    </S.Panel>
  )
}

import MoreIcon from 'assets/images/icon-dots.png'
import Guest from 'assets/images/icon-guest.png'
import { sendEvent } from 'components/Analytics'
import * as S from 'components/CommentsDetailPanel/styles'
import { CustomizeEvent } from 'constants/events'
import { getShowTime } from 'constants/misc'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { useCallback, useMemo, useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { useAddPopup, useLeftModalOpen, useToggleOtherUser } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { updateOtherId } from 'state/home/reducer'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { useDeleteCommentMutation, useGetCommentListMutation } from 'state/postApi/slice'
import { ExternalLink } from 'theme/components'
import useBus, { dispatch as dispatchEvent } from 'use-bus'
import { replaceImageUrl, shortenAddress } from 'utils'
import { shortAddress } from 'utils/safeNamehash'

export default function Comment({
  item,
  onReply,
  type = 1
}: {
  item: any
  onReply: (name: string, id: string, users: string[]) => void
  type?: number
}) {
  const addPopup = useAddPopup()
  const [showDeleteButton, setShowDeleteButton] = useState(false)
  const [commentDeleted, setCommentDeleted] = useState(false)
  const [deleteComment] = useDeleteCommentMutation()
  const [getCommentList] = useGetCommentListMutation()
  const toggleOtherUser = useToggleOtherUser()
  const [comments, setComments] = useState([] as any[])
  const userProfile = useAppSelector((state) => state.user.userProfile)
  const currentUserInfo = useAppSelector((state) => state.user.userInfo)
  const node = useRef<HTMLDivElement>()
  const otherUserOpen = useLeftModalOpen(ApplicationModal.OTHERUSER)
  const dispatch = useAppDispatch()
  useOnClickOutside(
    node,
    showDeleteButton
      ? () => {
          setShowDeleteButton(false)
        }
      : undefined
  )

  const showDeleteButtonClick = useCallback(() => {
    setShowDeleteButton(!showDeleteButton)
  }, [showDeleteButton])

  const handleClickOtherUser = useCallback(
    (commentInfo) => {
      dispatch(updateOtherId({ userId: commentInfo?.owner }))
      if (!otherUserOpen) {
        toggleOtherUser()
      }
      //if (currentUserInfo?.userId !== commentInfo?.owner) {
      //}
    },
    [dispatch, otherUserOpen, toggleOtherUser]
  )

  const handleDeleteComment = useCallback(
    (commentId: string) => {
      deleteComment({ commentId })
        .then((data: any) => {
          //showDeleteButton()
          if (data?.data?.code === 0) {
            setCommentDeleted(true)
            setShowDeleteButton(false)
            if (type === 1) {
              dispatchEvent(CustomizeEvent.REFRESH_COMMENT + item?.upId)
              sendEvent({
                category: 'Delete-Comment',
                action: 'Delete-Comment Success',
                label: commentId
              })
            } else {
              sendEvent({
                category: 'Delete-Reply',
                action: 'Delete-Reply Success',
                label: commentId
              })
            }
            addPopup({ tip: 'The comment was successfully deleted!' })
          } else {
            if (data?.error?.status === 429) {
              addPopup({ error: "You're deleting comment too quickly. Please wait a few minutes and try again." })
            } else {
              addPopup({ error: 'Failed to delete comment. Please Try again.' })
            }
            if (type === 1) {
              sendEvent({
                category: 'Delete-Comment',
                action: 'Delete-Comment Unsuccess',
                label: commentId
              })
            } else {
              sendEvent({
                category: 'Delete-Reply',
                action: 'Delete-Reply Unsuccess',
                label: commentId
              })
            }
          }
        })
        .catch((e) => {
          console.error(e)
          addPopup({ error: 'Failed to delete comment. Please Try again.' })
          if (type === 1) {
            sendEvent({
              category: 'Delete-Comment',
              action: 'Delete-Comment Unsuccess',
              label: commentId
            })
          } else {
            sendEvent({
              category: 'Delete-Reply',
              action: 'Delete-Reply Unsuccess',
              label: commentId
            })
          }
        })
    },
    [addPopup, deleteComment, item?.upId, type]
  )

  const handleShowRepies = useCallback(() => {
    if (comments.length === 0) {
      getCommentList({ upID: item?.commentId, current: 1, pageSize: 200 })
        .then((data: any) => {
          if (data?.data?.code === 0) {
            setComments(data?.data?.data?.data)
          }
        })
        .catch((e) => {
          console.error(e)
          addPopup({ error: 'Error!' })
        })
    } else {
      setComments([])
    }
  }, [addPopup, comments.length, getCommentList, item?.commentId])

  useBus(CustomizeEvent.REFRESH_REPLIES + item?.commentId, () => {
    handleShowRepies()
  })
  const commentContent = useMemo(() => {
    let content = ''
    if (item?.refCallUsers.length > 0) {
      content = item?.refCallUsers.map((user: any) => {
        return '@' + user?.nickName ?? shortenAddress(user?.userId) + ' '
      })
      content += ' '
    }
    content += item.content
    return content
  }, [item.content, item?.refCallUsers])
  return (
    <S.CommentContainer>
      {commentDeleted === false && (
        <S.LevelOnePanel>
          <S.CommentPanel onClick={() => handleClickOtherUser(item)}>
            <S.AvatarPanel role={item?.role ?? 'customer'}>
              <S.Avatar
                src={item?.avatar ? replaceImageUrl(item?.avatar, 200) : Guest}
                alt={'comment-avatar'}
              ></S.Avatar>
            </S.AvatarPanel>
            <S.InfoPanel type={type} showBorder={comments.length === 0}>
              <S.PostHeader>
                <S.AccountName>{item.nickName ?? shortAddress(item.owner, 6, 4)}</S.AccountName>
                {item.role != null && item?.role !== 'customer' && (
                  <S.AccountType role={item.role}>{item.role}</S.AccountType>
                )}
                <S.MoreInfo>
                  <S.TimeInfo>{getShowTime(item.createdAt)}</S.TimeInfo>{' '}
                  {((currentUserInfo &&
                    currentUserInfo?.userId &&
                    currentUserInfo?.userId?.toLowerCase() === item.owner.toLowerCase()) ||
                    (userProfile?.role && (userProfile?.role === 'developer' || userProfile?.role === 'agent'))) && (
                    <S.MoreIcon onClick={showDeleteButtonClick}>
                      <img src={MoreIcon} alt="more icon" />
                    </S.MoreIcon>
                  )}
                </S.MoreInfo>
                {showDeleteButton && (
                  <S.DeletaButton
                    ref={node}
                    style={{}}
                    color="#DEDEDE"
                    $borderRadius="4px"
                    padding="12px"
                    fontWeight={400}
                    onClick={() => {
                      handleDeleteComment(item.commentId)
                    }}
                  >
                    Delete
                  </S.DeletaButton>
                )}
              </S.PostHeader>
              <S.PostContent>
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  components={{
                    // eslint-disable-next-line react/display-name
                    a: ({ ...props }) => <ExternalLink href="" {...props} />
                  }}
                >
                  {commentContent}
                </ReactMarkdown>
              </S.PostContent>
              {item?.comments > 0 && comments.length === 0 && (
                <S.ShowReply onClick={handleShowRepies}>
                  Show {item?.comments > 1 ? `${item?.comments} Replies` : `${item?.comments} Reply`}{' '}
                </S.ShowReply>
              )}
              <S.ReplyMobilePanel>
                {item?.comments > 0 && (
                  <S.ShowReplyMobile onClick={handleShowRepies}>
                    {comments.length === 0
                      ? `Show ${item?.comments > 1 ? `${item?.comments} Replies` : `${item?.comments} Reply`}`
                      : 'Hide Replies'}
                  </S.ShowReplyMobile>
                )}
                <S.ReplyButtonPanel>
                  <S.ReplyButton
                    onClick={() => {
                      onReply(item?.nickName ?? shortenAddress(item?.owner), item?.commentId, [item?.owner])
                    }}
                  >
                    Reply
                  </S.ReplyButton>
                </S.ReplyButtonPanel>
              </S.ReplyMobilePanel>
            </S.InfoPanel>
            <S.ReplyPanel>
              <S.ReplyButton
                onClick={() => {
                  onReply(item?.nickName ?? shortenAddress(item?.owner), item?.commentId, [item?.owner])
                }}
              >
                Reply
              </S.ReplyButton>
            </S.ReplyPanel>
          </S.CommentPanel>
          {comments.length > 0 && (
            <S.LevelTwoPanel>
              {comments.map((subItem) => {
                return (
                  <Comment
                    onReply={() => {
                      onReply(subItem?.nickName ?? shortenAddress(subItem?.owner), subItem?.upId, [subItem?.owner])
                    }}
                    key={subItem?.id}
                    item={subItem}
                    type={2}
                  ></Comment>
                )
              })}
            </S.LevelTwoPanel>
          )}
        </S.LevelOnePanel>
      )}
    </S.CommentContainer>
  )
}

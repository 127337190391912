import { ReactComponent as Close } from 'assets/svg/x.svg'
import styled from 'styled-components/macro'
import { CommonPanel, PANEL_HEIGHTS } from 'theme'

export const Panel = styled(CommonPanel)<{ innerheight?: number; showall?: number }>`
  position: relative;
  margin-top: 0.54vh;

  width: 100%;
  max-height: ${({ showall }) => (showall ? '100%' : '81.94vh')};
  flex: 1;

  ${({ theme, showall, innerheight }) => theme.mediaWidth.upToExtraLarge`
    max-height: ${showall ? '100%' : innerheight ? innerheight * 0.8776 + 'px' : '84.14vh'};
  `};

  ${({ theme, showall, innerheight }) => theme.mediaWidth.upToLarge`
    max-height: ${showall ? '100%' : innerheight ? innerheight * 0.9076 + 'px' : '85.14vh'};
  `};

  ${({ theme, innerheight }) => theme.mediaWidth.upToSmall`
    margin-top: 1.54vh;
    max-height: ${innerheight ? innerheight * 0.8246 + 'px' : PANEL_HEIGHTS.mobile};
  `};
`

export const CloseIcon = styled.div`
  position: absolute;
  right: 1.04vw;
  top: 1.61vh;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    right: 5.13vw;
    top: 2.13vh;
  `}
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`
export const CloseColor = styled(Close)`
  color: ${({ theme }) => theme.text4};
  width: 0.87vw;
  height: 0.87vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 3.85vw;
    height: 3.85vw;
  `}
`

export const Content = styled.div`
  height: 100%;
  margin: 1.25vh 1.27vw 2.86vh 0.81vw;
  overflow: auto;
  display: flex;
  flex-direction: column;
  /* 滚动槽 */
  ::-webkit-scrollbar {
    width: 0.16vw;
    height: 0.16vw;
  }
  ::-webkit-scrollbar-track {
    border-radius: 0.08vw;
  }
  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    border-radius: 0.08vw;
  }
  font-style: normal;
  font-weight: 300;
  font-size: 1.07vh;
  line-height: 1.25vh;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 1.54vh 5.38vw 2.13vh 5.13vw;
    font-size: 1.42vh;
    line-height: 1.66vh;
  `}

  p strong {
    font-weight: 500;
    font-size: 1.25vh;
    line-height: 1.43vh;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      font-size: 1.66vh;
      line-height: 1.9vh;
    `}
  }

  h2 {
    font-weight: 500;
    font-size: 1.34vh;
    line-height: 1.52vh;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      font-size: 1.78vh;
      line-height: 2.01vh;
    `}
  }

  color: #dedede;
`

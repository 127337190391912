import { ReactComponent as Close } from 'assets/svg/x.svg'
import styled from 'styled-components/macro'
import { CommonPanel, PANEL_HEIGHTS } from 'theme'

export const Panel = styled(CommonPanel)<{ width?: number | undefined }>`
  position: relative;
  margin-right: 1.45vw;
  height: 100%;
  flex: 1;
  max-height: ${PANEL_HEIGHTS.left};
`

export const CloseIcon = styled.div`
  position: absolute;
  right: 18px;
  top: 18px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`
export const CloseColor = styled(Close)`
  color: ${({ theme }) => theme.text4};
  width: 0.87vw;
  height: 0.87vw;
`

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 41px 22px 32px 35px;
  overflow: auto;
  /* 滚动槽 */
  ::-webkit-scrollbar {
    width: 0.16vw;
    height: 0.16vw;
  }
  ::-webkit-scrollbar-track {
    border-radius: 0.08vw;
  }
  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    border-radius: 0.08vw;
  }
`

export const ConfirmationTitle = styled.div`
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 52px;
  text-transform: uppercase;
  text-align: left;

  color: #dedede;
`

export const OverviewTitle = styled.div`
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  text-align: left;
  margin-top: 32px;

  color: #dedede;
`

export const PropertyPanel = styled.div`
  flex: 1;
  margin-top: 30px;
  width: 100%;
`

export const PropertyInfoPanel = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  max-height: 6.36vh;
`

export const ProInfoPanel = styled.div`
  flex: 1;
  margin-bottom: 30px;
`

export const LocationInfoPanel = styled.div`
  flex: 2;
  margin-bottom: 30px;
`

export const Title = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: ${({ theme }) => theme.text4};
`

export const TextInfo = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-top: 14px;

  color: rgba(222, 222, 222, 0.6);
`

export const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 28px;
  justify-content: center;
`

export const DepositImage = styled.img`
  width: 100%;
`

export const SignPanel = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 32px;
`

export const SignInfo = styled.div`
  flex: 1;
  align-items: center;
  display: flex;
`

export const SignLabel = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #dedede;
`

export const ContractInfo = styled.div`
  flex: 1;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #dedede;
`

export const SignImagePanel = styled.div`
  flex: 1;
  margin-left: 14px;
  margin-right: 32px;
`

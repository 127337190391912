export enum PropertyStatus {
  UNAVAILABLE = 0,
  AVAILABLE = 1,
  EOI = 2,
  RESERVE = 3,
  CONDITIONAL = 4,
  UNCONDITIONAL = 5,
  SOLD = 6
}

const STAUS_DESC: Record<number, string> = {
  [PropertyStatus.UNAVAILABLE]: 'Unavailable',
  [PropertyStatus.AVAILABLE]: 'Available',
  [PropertyStatus.EOI]: 'EOI',
  [PropertyStatus.RESERVE]: 'Reserve',
  [PropertyStatus.CONDITIONAL]: 'Conditional',
  [PropertyStatus.UNCONDITIONAL]: 'Unconditional',
  [PropertyStatus.SOLD]: 'Sold'
}

const STAUS_COLOR: Record<number, string> = {
  [PropertyStatus.UNAVAILABLE]: '#FD4954',
  [PropertyStatus.AVAILABLE]: '#74FF17',
  [PropertyStatus.EOI]: '#FD4954',
  [PropertyStatus.RESERVE]: '#FD4954',
  [PropertyStatus.CONDITIONAL]: '#FD4954',
  [PropertyStatus.UNCONDITIONAL]: '#FD4954',
  [PropertyStatus.SOLD]: '#FD4954'
}

export const GetPropertyStatus = (status: number) => {
  return STAUS_DESC[status] ?? 'Available'
}

export const GetPropertyColor = (status: number) => {
  return STAUS_COLOR[status] ?? '#FD4954'
}

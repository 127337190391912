import { ReactComponent as Close } from 'assets/svg/x.svg'
import styled from 'styled-components/macro'
import { CommonPanel, PANEL_HEIGHTS } from 'theme'

export const Panel = styled(CommonPanel)<{ width?: number | undefined; innerheight?: number }>`
  position: relative;
  margin-right: 1.45vw;
  flex: 1;
  height: ${PANEL_HEIGHTS.left};

  ${({ theme, innerheight }) => theme.mediaWidth.upToExtraLarge`
    height: ${innerheight ? innerheight * 0.995 + 'px' : '96.5vh'};
    margin-right: 0.83vw;
  `};

  ${({ theme, innerheight }) => theme.mediaWidth.upToLarge`
    height: ${innerheight ? innerheight * 1.035 + 'px' : '97.5vh'};
    margin-right: 0.83vw;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 0px;
  `};
`

export const CloseIcon = styled.div`
  position: absolute;
  right: 1.04vw;
  top: 1.61vh;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 2.49vh;
    right: 5.38vw;
  `}
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`
export const CloseColor = styled(Close)`
  color: ${({ theme }) => theme.text4};
  width: 0.87vw;
  height: 0.87vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 3.85vw;
    height: 3.85vw;
  `}
`

export const GalleryPanel = styled.div<{ innerheight?: number }>`
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: min-content;
  grid-gap: 1.48vh 0.49vw;
  justify-content: center;
  margin-top: 3.47vh;
  margin-bottom: 3.47vh;
  padding-left: 1.07vw;
  padding-right: 1.07vw;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-gap: 1.01vh 1.67vw;
    grid-template-columns: 1fr;
    margin-top: 5.47vh;
    margin-bottom: 3.47vh;
    padding-left: 3.07vw;
    padding-right: 3.07vw;
  `}
  /* 滚动槽 */
  ::-webkit-scrollbar {
    width: 0.16vw;
    height: 0.16vw;
  }
  ::-webkit-scrollbar-track {
    border-radius: 0.08vw;
  }
  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    border-radius: 0.08vw;
  }
`

export const GalleryImageBox = styled.div`
  display: inline-block;
  background: rgba(222, 222, 222, 0.06);
  border-radius: 0.3vw;
`

export const GalleryImageBoxHeader = styled.div`
  // height: 3.76vh;
`

export const GalleryImageBoxHeaderUnit = styled.div`
  font-family: Roboto FlexVariable;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  color: #dedede;
  margin-left: 1vh;
  margin-top: 0.5vh;
  display: inline-block;
`

export const GalleryImageBoxHeaderStatus = styled.div<{ statusColor: string }>`
  font-family: Roboto FlexVariable;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: ${({ statusColor }) => (statusColor ? statusColor : '')};
  float: right;
  margin-top: 1.2vh;
  padding-right: 0.5vw;
  display: inline-block;
`

export const GalleryImageBoxContent = styled.div`
  display: flex;
  justify-content: center;
`
export const GalleryImageBoxFooter = styled.div`
  display: block;
`

export const GalleryImageBoxDesc = styled.div`
  display: block;
`

export const GalleryImageBoxFooterSubtitle = styled.div`
  font-family: Roboto FlexVariable;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: grey;
  margin-top: 1.2vh;
  padding-top: 1.2vh;
  padding-left: 0.5vw;
`

export const GalleryImageBoxFooterPrice = styled.div`
  font-family: Roboto FlexVariable;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #dedede;
  margin-top: 1.2vh;
  display: inline-block;
  padding-left: 0.5vw;
  padding-bottom: 1vh;
`

export const GalleryImageBoxFooterRight = styled.div`
  display: inline-block;
  float: right;
  padding-right: 0.5vw;
  padding-bottom: 0.5vh;
`

import JSBI from 'jsbi'
import moment from 'moment'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const NetworkContextName = 'NETWORK'

export const IS_IN_IFRAME = window.parent !== window

// 30 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 30
export const L2_DEADLINE_FROM_NOW = 60 * 5

// transaction popup dismisal amounts
export const DEFAULT_TXN_DISMISS_MS = 5000
export const L2_TXN_DISMISS_MS = 5000

// used for rewards deadlines
export const BIG_INT_SECONDS_IN_WEEK = JSBI.BigInt(60 * 60 * 24 * 7)

export const BIG_INT_ZERO = JSBI.BigInt(0)

export const getShowTime = (utcTime: string) => {
  // if (moment().diff(moment.utc(utcTime), 'years') >= 1) {
  //   return moment.utc(utcTime).local().format('DD MMM yyyy')
  // } else if (moment().diff(moment.utc(utcTime), 'hours') >= 24) {
  //   return moment.utc(utcTime).local().format('DD MMM HH:mm')
  // } else if (moment().diff(moment.utc(utcTime), 'minutes') >= 60) {
  //   const hours = moment().diff(moment.utc(utcTime), 'hours')
  //   return hours === 1 ? `${hours} hour ago` : `${hours} hours ago`
  // } else if (moment().diff(moment.utc(utcTime), 'seconds') >= 60) {
  //   const minutes = moment().diff(moment.utc(utcTime), 'minutes')
  //   return minutes === 1 ? `${minutes} minute ago` : `${minutes} minutes ago`
  // } else {
  //   return 'Just now'
  // }
  //Today 3:30 pm
  // Yesterday 9:23 am
  // 21 Jan (if this year)
  // 21 Jan 2022 (pre-this year)

  if (moment().isSame(moment.utc(utcTime).local(), 'day')) {
    return 'Today ' + moment.utc(utcTime).local().format('h:mm a')
  } else if (moment().subtract(1, 'day').isSame(moment.utc(utcTime).local(), 'day')) {
    return 'Yesterday ' + moment.utc(utcTime).local().format('h:mm a')
  } else if (moment().isSame(moment.utc(utcTime).local(), 'year')) {
    return moment.utc(utcTime).local().format('DD MMM')
  } else {
    return moment.utc(utcTime).local().format('DD MMM yyyy')
  }
}

export const getChatTime = (timestamp: number) => {
  if (moment().isSame(moment(timestamp).local(), 'day')) {
    return moment(timestamp).local().format('h:mm a')
  } else if (moment().subtract(1, 'day').isSame(moment(timestamp).local(), 'day')) {
    return 'Yesterday ' + moment(timestamp).local().format('h:mm a')
  } else if (moment().isSame(moment(timestamp).local(), 'year')) {
    return moment(timestamp).local().format('DD MMM')
  } else {
    return moment(timestamp).local().format('DD MMM yyyy')
  }
}

import { ReactComponent as Close } from 'assets/svg/x.svg'
import styled from 'styled-components/macro'
import { CommonPanel, ExternalLink, PANEL_HEIGHTS } from 'theme'

export const NotificationsListPanel = styled(CommonPanel)<{ pageheight?: number }>`
  padding: 1.25vh 1.27vw 2.24vh 1.27vw;
  margin-top: 0.54vh;
  position: relative;
  height: 78.56vh;

  ${({ theme, pageheight }) => theme.mediaWidth.upToExtraLarge`
    height: ${pageheight ? pageheight * 0.8594 + 'px' : '81.06vh'};
  `};

  ${({ theme, pageheight }) => theme.mediaWidth.upToLarge`
    height: ${pageheight ? pageheight * 0.8694 + 'px' : '82.06vh'};
  `};

  ${({ theme, pageheight }) => theme.mediaWidth.upToSmall`
    padding: 2.25vh 5.13vw 2.25vh 5.13vw;
    margin-top: 1.54vh;
    height: ${pageheight ? pageheight * 0.8246 + 'px' : PANEL_HEIGHTS.mobile};
  `};
`

export const HeaderText = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 1.43vh;
  cursor: pointer;
  color: ${({ theme }) => theme.text4};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.9vh;
  `};
`

export const CloseIcon = styled.div`
  position: absolute;
  right: 1.04vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    right: 4.62vw;
  `};
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`
export const CloseColor = styled(Close)`
  color: ${({ theme }) => theme.text4};
  width: 0.87vw;
  height: 0.87vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 3.85vw;
    height: 3.85vw;
  `};
`

export const NoContentPanel = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const NoContentTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 2.14vh;
  line-height: 136.19%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 2.84vh;
  `};

  color: ${({ theme }) => theme.text4};
`

export const NoContentSubTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 1.43vh;
  line-height: 136.19%;

  color: rgba(222, 222, 222, 0.6);
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.9vh;
  `};
`

export const NotificationsList = styled.div<{ innerHeight?: number }>`
  display: flex;
  flex-direction: column;
  margin-top: 0.18vh;
  overflow: auto;
  flex: 1;
  /* 滚动槽 */
  ::-webkit-scrollbar {
    width: 0.16vw;
    height: 0.16vw;
  }
  ::-webkit-scrollbar-track {
    border-radius: 0.08vw;
  }
  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    border-radius: 0.08vw;
  }
  height: 75.76vh;
  ${({ theme, innerHeight }) => theme.mediaWidth.upToExtraLarge`
    height: ${innerHeight ? innerHeight * 0.8126 + 'px' : '78.26vh'};
  `};
  ${({ theme, innerHeight }) => theme.mediaWidth.upToLarge`
    height: ${innerHeight ? innerHeight * 0.8356 + 'px' : '79.26vh'};
  `};
  ${({ theme, innerHeight }) => theme.mediaWidth.upToSmall`
    margin-top: 0.24vh;
    height: ${innerHeight ? innerHeight * 0.7587 + 'px' : '72.87vh'};
  `}
`

export const NotifyItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1.34vh 0 1.34vh 0;
  cursor: pointer;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 1.78vh 0 1.78vh 0;
  `};
  &:hover {
    opacity: 0.6;
  }
`

export const NotifyInfo = styled.div`
  flex: 1;
  font-style: normal;
  font-weight: 300;
  font-size: 1.25vh;
  line-height: 136.19%;
  padding-right: 2.03vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.66vh;
    padding-right: 2.56vw;
  `};

  color: rgba(222, 222, 222, 0.8);
`

export const NormalNotify = styled.span`
  font-weight: 700;
`

export const StyledExtLink = styled(ExternalLink)`
  cursor: pointer;
  color: #c2c2c2;
  text-decoration: underline;

  :hover {
    text-decoration: underline;
    color: #c2c2c2;
  }

  :focus {
    outline: none;
    text-decoration: underline;
  }

  :active {
    text-decoration: none;
  }
`

export const StyledInLink = styled.em`
  text-decoration: none;
  cursor: pointer;
  text-decoration: underline;

  :hover {
    text-decoration: underline;
  }

  :focus {
    outline: none;
    text-decoration: underline;
  }

  :active {
    text-decoration: none;
  }
`

export const NotifySubInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const TimeInfo = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 1.25vh;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.66vh;
  `};

  display: flex;
  align-items: center;
  text-align: center;
  flex: 1;

  color: rgba(222, 222, 222, 0.4);
`

export const NotifyContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0.45vh;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 0.59vh;
  `};
`

export const NotifyPanel = styled.div`
  width: 0.89vh;
  height: 0.89vh;
  border-radius: 50%;
  background: ${({ theme }) => theme.primary1};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 1.18vh;
    height: 1.18vh;
  `};
`

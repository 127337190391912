import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import Circle from 'assets/svg/blue-loader.svg'
import * as S from 'components/BuyProperty/styles'
import { CustomizeEvent } from 'constants/events'
import { useCallback, useEffect, useState } from 'react'
import { useAddPopup } from 'state/application/hooks'
import { useCheckOrderStatusMutation } from 'state/payApi/slice'
import { setTimeout } from 'timers'
import useBus from 'use-bus'

export default function PaymentForm({
  orderId,
  onSuccess,
  onClose
}: {
  orderId: string
  onSuccess: () => void
  onClose: () => void
}) {
  const stripe = useStripe()
  const elements = useElements()

  const addPopup = useAddPopup()

  const [checkOrderStatus] = useCheckOrderStatusMutation()

  const [, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const reset = useCallback(() => {
    setIsLoading(false)
    setMessage('')
  }, [])

  useBus(CustomizeEvent.REFRESH_PAYMENT, reset, [])

  useEffect(() => {
    if (!stripe) {
      return
    }

    const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret')

    if (!clientSecret) {
      return
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case 'succeeded':
          setMessage('Payment succeeded!')
          break
        case 'processing':
          setMessage('Your payment is processing.')
          break
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.')
          break
        default:
          setMessage('Something went wrong.')
          break
      }
    })
  }, [stripe])

  const startCheckOrderStatus = useCallback(() => {
    setTimeout(() => {
      checkOrderStatus({ orderId })
        .then((data: any) => {
          if (data?.data?.code === 0) {
            onSuccess()
            reset()
          } else {
            startCheckOrderStatus()
          }
        })
        .catch((e) => {
          console.error(e)
          startCheckOrderStatus()
        })
    }, 1500)
  }, [checkOrderStatus, onSuccess, orderId, reset])

  const handleSubmit = useCallback(
    async (e: any) => {
      if (isLoading) return
      e.preventDefault()
      setIsLoading(true)

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return
      }

      try {
        const { error } = await stripe.confirmPayment({
          elements,
          redirect: 'if_required'
        })

        if (error) {
          addPopup({ error: 'Error!' })
          console.error(error)
          setIsLoading(false)
        } else {
          startCheckOrderStatus()
        }
      } catch (error) {
        console.error(error)
        setIsLoading(false)
      }
    },
    [addPopup, elements, isLoading, startCheckOrderStatus, stripe]
  )

  return (
    <S.CheckoutForm id="payment-form" onSubmit={handleSubmit}>
      <S.CloseIcon onClick={onClose}>
        <S.CloseColor />
      </S.CloseIcon>
      <PaymentElement id="payment-element" />

      <S.SubmitPayment id="submit">
        {isLoading ? <S.Loading src={Circle} alt="loader"></S.Loading> : 'SUBMIT Payment'}
      </S.SubmitPayment>
    </S.CheckoutForm>
  )
}

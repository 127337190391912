import { ReactComponent as Close } from 'assets/svg/x.svg'
import Slider from 'react-slick'
import styled from 'styled-components/macro'
import { CommonPanel, PANEL_HEIGHTS } from 'theme'

export const Panel = styled(CommonPanel)<{ width?: number | undefined; innerheight?: number }>`
  position: relative;
  margin-right: 1.45vw;
  height: 100%;
  flex: 1;
  height: ${PANEL_HEIGHTS.left};

  ${({ theme, innerheight }) => theme.mediaWidth.upToExtraLarge`
    height: ${innerheight ? innerheight * 0.955 + 'px' : '96.5vh'};
    margin-right: 0.83vw;
  `};

  ${({ theme, innerheight }) => theme.mediaWidth.upToLarge`
    height: ${innerheight ? innerheight * 0.975 + 'px' : '97.5vh'};
    margin-right: 0.83vw;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 0px;
  `};
`

export const CloseIcon = styled.div`
  position: absolute;
  top: 1.61vh;
  right: 1.04vw;
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 2.49vh;
    right: 5.38vw;
  `}
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`
export const CloseColor = styled(Close)`
  color: ${({ theme }) => theme.text4};
  width: 0.87vw;
  height: 0.87vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 3.85vw;
    height: 3.85vw;
  `}
`
export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2.06vh 0;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 6.75vh 0;
  `}
`

export const ArrowButton = styled.img`
  height: 33px;
  width: 14.5px;
  z-index: 1;
  mix-blend-mode: difference;
  background-blend-mode: difference;
  left: 0.58vw;
  object-fit: contain;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    position: absolute;
    margin-right: 0;
    left: 3.33vw;
  `}
`

export const ArrowRightButton = styled.img`
  height: 33px;
  width: 14.5px;
  z-index: 1;
  mix-blend-mode: difference;
  background-blend-mode: difference;
  right: 0.58vw;
  object-fit: contain;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    position: absolute;
    margin-right: 0;
    right: 3.33vw;
  `}
`

export const ImageDetail = styled(Slider)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 100%;
  `}
  .slick-list img {
    width: 100%;
    height: 79.08vh;
    object-fit: contain;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      max-height: 100%;
    `}
  }
  .slick-list .reload-image-error {
    width: 100%;
    height: 79.08vh;
    object-fit: contain;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      max-height: 100%;
    `}
  }
  .slick-list {
    flex: 1;
  }
  .slick-arrow {
    z-index: 1;
    position: absolute;
  }
  .slick-prev {
    ::before {
      content: '';
    }
  }
  .slick-next {
    ::before {
      content: '';
    }
  }
`

import { ReactComponent as Close } from 'assets/svg/x.svg'
import styled from 'styled-components/macro'
import { CommonPanel, PANEL_HEIGHTS } from 'theme'

export const PlacesListPanel = styled(CommonPanel)<{ pageheight?: number }>`
  padding: 1.25vh 1.27vw 2.24vh 1.27vw;
  margin-top: 0.54vh;
  position: relative;
  max-height: 78.56vh;

  ${({ theme, pageheight }) => theme.mediaWidth.upToExtraLarge`
    max-height: ${pageheight ? pageheight * 0.8594 + 'px' : '81.06vh'};
  `};

  ${({ theme, pageheight }) => theme.mediaWidth.upToLarge`
    max-height: ${pageheight ? pageheight * 0.8694 + 'px' : '82.06vh'};
  `};

  ${({ theme, pageheight }) => theme.mediaWidth.upToSmall`
    padding: 2.25vh 5.13vw 2.25vh 5.13vw;
    margin-top: 1.54vh;
    max-height: ${pageheight ? pageheight * 0.8246 + 'px' : PANEL_HEIGHTS.mobile};
    flex: 1;
  `};
`

export const HeaderText = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 1.43vh;
  cursor: pointer;
  color: ${({ theme }) => theme.text4};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.9vh;
  `};
`

export const CloseIcon = styled.div`
  display: none;
  position: absolute;
  right: 1.04vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    right: 5.13vw;
  `}
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`
export const CloseColor = styled(Close)`
  color: ${({ theme }) => theme.text4};
  width: 0.87vw;
  height: 0.87vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 3.85vw;
    height: 3.85vw;
  `}
`

export const PlacesList = styled.div<{ innerHeight?: number }>`
  display: flex;
  flex-direction: column;
  margin-top: 0.18vh;
  overflow: auto;
  flex: 1;
  /* 滚动槽 */
  ::-webkit-scrollbar {
    width: 0.16vw;
    height: 0.16vw;
  }
  ::-webkit-scrollbar-track {
    border-radius: 0.08vw;
  }
  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    border-radius: 0.08vw;
  }
  height: 75.76vh;
  ${({ theme, innerHeight }) => theme.mediaWidth.upToExtraLarge`
    max-height: ${innerHeight ? innerHeight * 0.8126 + 'px' : '78.26vh'};
  `};
  ${({ theme, innerHeight }) => theme.mediaWidth.upToLarge`
    height: ${innerHeight ? innerHeight * 0.8356 + 'px' : '79.26vh'};
  `};
  ${({ theme, innerHeight }) => theme.mediaWidth.upToSmall`
    margin-top: 0.24vh;
    max-height: ${innerHeight ? innerHeight * 0.7587 + 'px' : '72.87vh'};
  `}
`

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2.6vh;
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 3.44vh;
  `}
`

export const LocationImg = styled.img`
  display: none;
  width: 3.58vh;
  height: 3.58vh;
`

export const InfoContent = styled.div``

export const Title = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 1.43vh;
  color: ${({ theme }) => theme.text4};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.9vh;
  `}
`

export const SubTitle = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 1.25vh;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.66vh;
  `}

  color: rgba(222, 222, 222, 0.6);
`

import { useSpring } from '@react-spring/web'
import DefaultProperty from 'assets/images/detail-image.png'
import ArrowBottom from 'assets/images/icon-arrow-bottom.png'
import ArrowLeft from 'assets/images/icon-arrow-left.png'
import ArrowRight from 'assets/images/icon-arrow-right.png'
import ArrowTop from 'assets/images/icon-arrow-top.png'
import * as S from 'components/InfoPanel/styles'
import { useWindowSize } from 'hooks/useWindowSize'
import { useCallback, useEffect, useMemo, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { useLeftModalOpen, useTogglePropertyInfo } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { useAppSelector } from 'state/hooks'
import { MEDIA_WIDTHS } from 'theme'

export default function InfoPanel() {
  const open = useLeftModalOpen(ApplicationModal.PROPERTY_INFO)
  const buildingInfo = useAppSelector((state) => state.home.buildingInfo)
  const toggle = useTogglePropertyInfo()

  const [curIndex, setCurIndex] = useState(0)

  useEffect(() => {
    if (!open) {
      setCurIndex(0)
      setCurPage(0)
    }
  }, [open])

  const handleArrowLeft = useCallback(() => {
    if (curIndex === 0) {
      setCurIndex(buildingInfo?.images.length - 1)
    } else {
      setCurIndex(curIndex - 1)
    }
  }, [curIndex, buildingInfo?.images])

  const handleArrowRight = useCallback(() => {
    if (curIndex === buildingInfo?.images.length - 1) {
      setCurIndex(0)
    } else {
      setCurIndex(curIndex + 1)
    }
  }, [curIndex, buildingInfo?.images])

  const [lastAction, setLastAction] = useState(0) // 0 initail 1 Up 2 Down
  const [curPage, setCurPage] = useState(0) // 0 initail 1 Up 2 Down

  const handleDown = useCallback(() => {
    if (lastAction === 0 || lastAction === 1) {
      let steps = curPage + 7
      if (steps > buildingInfo?.images.length - 1) {
        steps = buildingInfo?.images.length - 1
      }
      const element = document.getElementById(`section-${steps}`)
      setCurPage(steps)
      if (element) {
        setLastAction(2)
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth' })
      }
    } else {
      let steps = curPage + 4
      if (steps > buildingInfo?.images.length - 1) {
        steps = buildingInfo?.images.length - 1
      }
      const element = document.getElementById(`section-${steps}`)
      setCurPage(steps)
      if (element) {
        setLastAction(2)
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [curPage, lastAction, buildingInfo?.images])

  const handleUp = useCallback(() => {
    if (lastAction === 0 || lastAction === 2) {
      let steps = curPage - 7
      if (steps < 0) {
        steps = 0
      }
      const element = document.getElementById(`section-${steps}`)
      setCurPage(steps)
      if (element) {
        setLastAction(1)
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth' })
      }
    } else {
      let steps = curPage - 4
      if (steps < 0) {
        steps = 0
      }
      const element = document.getElementById(`section-${steps}`)
      setCurPage(steps)
      if (element) {
        setLastAction(1)
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [curPage, lastAction])

  const size = useWindowSize()
  const isMobile = (size?.width ?? MEDIA_WIDTHS.upToMedium) <= MEDIA_WIDTHS.upToSmall
  const style = useSpring({
    from: {
      opacity: 0,
      display: 'none',
      transform: isMobile ? 'translate3d(-100%,0,0)' : 'translate3d(-72.98vw,0,0)'
    },
    opacity: open ? 1 : 0,
    display: open ? 'flex' : 'none',
    transform: open ? 'translate3d(0,0,0)' : isMobile ? 'translate3d(-100%,0,0)' : 'translate3d(-72.98vw,0,0)',
    config: { duration: 200 }
  })
  const innerHeight = useMemo(() => {
    return size.clientHeight ? size.clientHeight : 0
  }, [size])
  return (
    <S.Panel style={style} innerheight={innerHeight}>
      <S.CloseIcon onClick={toggle}>
        <S.CloseColor />
      </S.CloseIcon>
      <S.Content>
        <S.ImagePanel>
          {buildingInfo?.mapImages !== '' &&
          buildingInfo?.mapImages.length > 100 &&
          buildingInfo?.showSketcherFab === true ? (
            <S.SketcherFabDiv dangerouslySetInnerHTML={{ __html: buildingInfo?.mapImages }} />
          ) : (
            <>
              <S.ImageDetailPanel>
                <S.ArrowLeftPanel onClick={handleArrowLeft}>
                  <S.ArrowIcon src={ArrowLeft} alt={'arrow-left'}></S.ArrowIcon>
                </S.ArrowLeftPanel>
                {buildingInfo?.images && (
                  <S.DetailImage>
                    <img src={buildingInfo?.images[curIndex] ?? DefaultProperty} alt={'detail'} />
                  </S.DetailImage>
                )}
                <S.ArrowRightPanel onClick={handleArrowRight}>
                  <S.ArrowIcon src={ArrowRight} alt={'arrow-right'}></S.ArrowIcon>
                </S.ArrowRightPanel>
              </S.ImageDetailPanel>
              <S.ImageSlider>
                <S.ArrowSliderIcon onClick={handleUp} src={ArrowTop} alt={'arrow-top'}></S.ArrowSliderIcon>
                <S.ArrowSliderPanel>
                  {buildingInfo?.images && buildingInfo?.images.length > 0 ? (
                    buildingInfo?.images.map((item: string, index: number) => {
                      return (
                        <S.SliderImage
                          active={index === curIndex}
                          key={index}
                          onClick={() => {
                            setCurIndex(index)
                          }}
                          src={item}
                          alt={'slider-image' + index}
                          id={`section-${index}`}
                        ></S.SliderImage>
                      )
                    })
                  ) : (
                    <S.SliderImage active={true} src={DefaultProperty} alt={'slider-image'}></S.SliderImage>
                  )}
                </S.ArrowSliderPanel>
                <S.ArrowSliderIcon onClick={handleDown} src={ArrowBottom} alt={'arrow-bottom'}></S.ArrowSliderIcon>
              </S.ImageSlider>
            </>
          )}
        </S.ImagePanel>
        <S.PropertyPanel>
          <S.PropertyInfoPanel style={{ display: 'none' }}>
            <S.ProInfoPanel>
              <S.Title>Project Status</S.Title>
              <S.TextInfo>Off The Plan</S.TextInfo>
            </S.ProInfoPanel>
            <S.ProInfoPanel>
              <S.Title>Project Type</S.Title>
              <S.TextInfo>Apartment</S.TextInfo>
            </S.ProInfoPanel>
            <S.ProInfoPanel>
              <S.Title>Property size</S.Title>
              <S.TextInfo>143m2</S.TextInfo>
            </S.ProInfoPanel>
            <S.ProInfoPanel>
              <S.Title>Price</S.Title>
              <S.TextInfo>AU$888,888</S.TextInfo>
            </S.ProInfoPanel>
            <S.ProInfoPanel></S.ProInfoPanel>
          </S.PropertyInfoPanel>
          <S.PropertyInfoPanel style={{ display: 'none' }}>
            <S.ProInfoPanel>
              <S.Title>Bedrooms</S.Title>
              <S.TextInfo>4</S.TextInfo>
            </S.ProInfoPanel>
            <S.ProInfoPanel>
              <S.Title>Bathrooms</S.Title>
              <S.TextInfo>2</S.TextInfo>
            </S.ProInfoPanel>
            <S.ProInfoPanel>
              <S.Title>Car Space</S.Title>
              <S.TextInfo>2</S.TextInfo>
            </S.ProInfoPanel>
            <S.ProInfoPanel>
              <S.Title>Location</S.Title>
              <S.TextInfo>Corner of Alarah Hayton Park Blvd, Cranbourne West, Vic 3977</S.TextInfo>
            </S.ProInfoPanel>
            <S.ProInfoPanel></S.ProInfoPanel>
          </S.PropertyInfoPanel>
          <S.IntroPanel>
            <S.Title>Location</S.Title>
            <S.TextInfo>
              <ReactMarkdown>
                {(buildingInfo?.addressLine1 ?? '') +
                  (buildingInfo?.addressLine1 &&
                  (buildingInfo?.addressLine2 || buildingInfo?.area || buildingInfo?.city)
                    ? ', '
                    : '') +
                  (buildingInfo?.addressLine2 ?? '') +
                  (buildingInfo?.addressLine2 && (buildingInfo?.area || buildingInfo?.city) ? ', ' : '') +
                  (buildingInfo?.area ?? '') +
                  (buildingInfo?.area && buildingInfo?.city ? ', ' : '') +
                  (buildingInfo?.city ?? '') +
                  ', ' +
                  buildingInfo?.country ?? 'Australia'}
              </ReactMarkdown>
            </S.TextInfo>
          </S.IntroPanel>
          <S.IntroPanel>
            <S.Title>Introduction</S.Title>
            <S.TextInfo>
              <ReactMarkdown>
                {buildingInfo?.notes
                  ? buildingInfo?.notes.replaceAll('\\"', '"')
                  : `A precinct of architecturally-designed townhouses created for smart living, it considers each moment, from when your alarm goes off to that last cup of tea before bed—and everything life involves in between.

Your everyday errands like quick grocery shops, café dates and school runs happen with ease. Restaurants, bars and places to play are conveniently close by throughout Cranbourne and surrounds, with a bus line to connect you with what you need and where you need to be.

Lifestyles are made outdoors, so we’ve designed bike and walking paths
to weave through the neighbourhood, connecting Phoenix to local schools and shopping. Closer to home, meet your neighbours in the nearby wetlands and play areas, or spend evenings stretched out with a book or at a barbecue with friends and family in the central park.`}
              </ReactMarkdown>
            </S.TextInfo>
          </S.IntroPanel>
        </S.PropertyPanel>
      </S.Content>
    </S.Panel>
  )
}

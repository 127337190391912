import { ReactComponent as Close } from 'assets/svg/x.svg'
import styled from 'styled-components/macro'
import { CommonPanel } from 'theme'

export const Panel = styled(CommonPanel)`
  padding: 2.78vh 0.81vw 1.88vh 1.22vw;
  margin-top: 0.54vh;
  position: relative;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 1.9vh 5.64vw 2.49vh 5.38vw;
    margin-top: 0.71vh;
  `};
`

export const CloseIcon = styled.div`
  position: absolute;
  top: 1.43vh;
  right: 0.81vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 2.13vh;
    right: 4.62vw;
  `};
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`
export const CloseColor = styled(Close)`
  color: ${({ theme }) => theme.text4};
  width: 0.87vw;
  height: 0.87vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 3.85vw;
    height: 3.85vw;
  `};
`

export const PanelTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 1.43vh;

  color: ${({ theme }) => theme.text4};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.9vh;
  `};
`

export const WalletItem = styled.div`
  display: flex;
  margin-top: 2.15vh;
  border: 1px solid #242424;
  border-radius: 0.72vh;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 2.84vh;
    border-radius: 0.95vh;
  `};
  width: fit-content;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`

export const WalletIcon = styled.img`
  height: 3.22vh;
  margin: 0.27vh 0.46vw 0.27vh 0.35vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 4.27vh;
    margin: 0.36vh 2.05vw 0.36vh 1.54vw;
  `};
`

export const WalletTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 1.43vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2.08vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.9vh;
    margin-right: 11.28vw;
  `};

  color: ${({ theme }) => theme.text4};
`

import React, { memo, useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components/macro'

const Input = styled.input<{ error?: boolean; fontSize?: string }>`
  font-size: ${({ fontSize }) => fontSize || '1.25rem'};
  outline: none;
  border: none;
  flex: 1;
  width: 0;
  background-color: ${({ theme }) => theme.bg1};
  transition: color 300ms ${({ error }) => (error ? 'step-end' : 'step-start')};
  color: ${({ error, theme }) => (error ? theme.red1 : theme.text1)};
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  width: 100%;
  padding: 0px;
  -webkit-appearance: textfield;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.text4};
  }
`

const TextAreaInput = styled.textarea<{ error?: boolean; fontSize?: string }>`
  font-size: ${({ fontSize }) => fontSize || '1.25rem'};
  outline: none;
  border: none;
  flex: 1 1 auto;
  width: 0;
  resize: none;
  background-color: ${({ theme }) => theme.bg1};
  transition: color 300ms ${({ error }) => (error ? 'step-end' : 'step-start')};
  color: ${({ error, theme }) => (error ? theme.red1 : theme.text1)};
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  width: 100%;
  line-height: 1.2;
  padding: 0px;
  -webkit-appearance: textfield;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.text4};
  }
`

const ReplyUserPanel = styled.div`
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
  color: #dedede;
  overflow: hidden;
  white-space: nowrap;
`

export const TextInput = ({
  className,
  value,
  onUserInput,
  placeholder,
  fontSize,
  onKeyUp,
  replyUser,
  disabledInput,
  maxLength,
  refInput
}: {
  className?: string
  value: string
  onUserInput: (value: string) => void
  placeholder: string
  fontSize: string
  onKeyUp?: (e: any) => void
  replyUser?: string
  disabledInput?: boolean
  maxLength?: number
  refInput?: any
}) => {
  const handleInput = useCallback(
    (event) => {
      onUserInput(event.target.value)
    },
    [onUserInput]
  )

  return (
    <div className={className} style={{ display: 'flex' }}>
      {replyUser && <ReplyUserPanel>{replyUser}&nbsp;</ReplyUserPanel>}
      <Input
        ref={refInput}
        type="text"
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        disabled={disabledInput}
        placeholder={placeholder || ''}
        onChange={handleInput}
        value={value}
        fontSize={fontSize}
        maxLength={maxLength}
        onKeyUp={(e) => {
          if (onKeyUp) onKeyUp(e)
        }}
      />
    </div>
  )
}

export const ResizingTextArea = memo(
  ({
    className,
    value,
    onUserInput,
    placeholder,
    fontSize,
    resize = true
  }: {
    className?: string
    value: string
    onUserInput: (value: string) => void
    placeholder: string
    fontSize?: string
    resize?: boolean
  }) => {
    const inputRef = useRef<HTMLTextAreaElement>(document.createElement('textarea'))

    const handleInput = useCallback(
      (event) => {
        onUserInput(event.target.value)
      },
      [onUserInput]
    )

    useEffect(() => {
      if (inputRef.current && value && resize) {
        inputRef.current.style.height = 'auto'
        inputRef.current.style.height = inputRef.current.scrollHeight + 'px'
      }
    }, [value, resize])

    return (
      <TextAreaInput
        className={className}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        autoFocus
        placeholder={placeholder || ''}
        onChange={handleInput}
        value={value}
        fontSize={fontSize}
        ref={inputRef}
        maxLength={1000}
      />
    )
  }
)

ResizingTextArea.displayName = 'ResizingTextArea'

const CommentTextAreaInput = styled.textarea<{ error?: boolean; fontSize?: string; mutilLine: boolean }>`
  font-size: ${({ fontSize }) => fontSize || '1.25vh'};
  outline: none;
  border: none;
  resize: none;
  background-color: ${({ theme }) => theme.bg1};
  transition: color 300ms ${({ error }) => (error ? 'step-end' : 'step-start')};
  color: ${({ error, theme }) => (error ? theme.red1 : theme.text1)};
  overflow: hidden;
  width: 100%;
  font-weight: 500;
  -webkit-appearance: textfield;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  flex: 1;
  text-overflow: ellipsis;
  font-size: 1.43vh;
  line-height: 1.7vh;
  border-radius: ${({ mutilLine }) => (mutilLine ? '0.72vh' : '2.69vh')};
  height: fit-content;
  ${({ theme, mutilLine }) => theme.mediaWidth.upToSmall`
    font-size: 1.9vh;
    line-height: 2.25vh;
    border-radius: ${mutilLine ? '0.95vh' : '1.95vh'};
  `}

  ::placeholder {
    color: rgba(222, 222, 222, 0.22);
    font-size: 1.43vh;
    line-height: 1.7vh;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      font-size: 1.9vh;
      line-height: 2.25vh;
    `}
  }
`

export const CommentTextArea = memo(
  ({
    className,
    value,
    onUserInput,
    placeholder,
    fontSize,
    resize = true,
    onKeyDown,
    replyUser,
    disabledInput,
    maxLength,
    refCallback
  }: {
    className?: string
    value: string
    onUserInput: (value: string) => void
    placeholder: string
    fontSize?: string
    resize?: boolean
    onKeyDown?: (e: any) => void
    replyUser?: string
    disabledInput?: boolean
    maxLength?: number
    refCallback?: (ref: any) => void
  }) => {
    const inputRef = useRef<HTMLTextAreaElement>(document.createElement('textarea'))

    const handleInput = useCallback(
      (event) => {
        onUserInput(event.target.value)
      },
      [onUserInput]
    )

    useEffect(() => {
      const lines = value.split('\n').length
      if (inputRef.current && value && resize && lines > 1) {
        inputRef.current.style.height = 'fit-content'

        inputRef.current.style.height = inputRef.current.scrollHeight - 15 - lines + 'px'
      } else if (inputRef.current && resize) {
        inputRef.current.style.height = 'fit-content'
      }
    }, [value, resize])

    useEffect(() => {
      if (inputRef.current && refCallback) {
        refCallback(inputRef)
      }
    }, [refCallback])

    return (
      <CommentTextAreaInput
        className={className}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        autoFocus
        mutilLine={value.split('\n').length > 1}
        rows={1}
        placeholder={placeholder || ''}
        onChange={handleInput}
        value={value}
        fontSize={fontSize}
        ref={inputRef}
        maxLength={maxLength}
        disabled={disabledInput}
        onSelectCapture={(e) => {
          if (replyUser) {
            if (e.currentTarget.selectionStart < replyUser?.length) {
              inputRef.current.setSelectionRange(replyUser?.length, replyUser?.length)
              return false
            }
          }
          return true
        }}
        onKeyDown={(e) => {
          if (onKeyDown) onKeyDown(e)
        }}
      />
    )
  }
)

CommentTextArea.displayName = 'CommentTextArea'

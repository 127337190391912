import { DEFAULT_TXN_DISMISS_MS } from 'constants/misc'
import { useWindowSize } from 'hooks/useWindowSize'
import { useCallback, useMemo } from 'react'
import {
  addPopup,
  ApplicationModal,
  PopupContent,
  removePopup,
  setOpenLeftModal,
  setOpenModal,
  setOpenTopModal
} from 'state/application/reducer'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { AppState } from 'state/index'
import { MEDIA_WIDTHS } from 'theme'

export function useModalOpen(modal: ApplicationModal): boolean {
  const openModal = useAppSelector((state: AppState) => state.application.openModal)
  return openModal === modal
}

export function useToggleModal(modal: ApplicationModal): () => void {
  const open = useModalOpen(modal)
  const dispatch = useAppDispatch()
  const size = useWindowSize()
  return useCallback(() => {
    if ((size?.width ?? MEDIA_WIDTHS.upToMedium) <= MEDIA_WIDTHS.upToSmall) {
      dispatch(setOpenLeftModal(null))
    }
    dispatch(setOpenModal(open ? null : modal))
  }, [dispatch, modal, open, size?.width])
}

export function useTopModalOpen(modal: ApplicationModal): boolean {
  const openModal = useAppSelector((state: AppState) => state.application.openTopModal)
  return openModal === modal
}

export function useToggleTopModal(modal: ApplicationModal): () => void {
  const open = useTopModalOpen(modal)
  const dispatch = useAppDispatch()
  return useCallback(() => dispatch(setOpenTopModal(open ? null : modal)), [dispatch, modal, open])
}

export function useLeftModalOpen(modal: ApplicationModal): boolean {
  const openModal = useAppSelector((state: AppState) => state.application.openLeftModal)
  return openModal === modal
}

export function useToggleLeftModal(modal: ApplicationModal): () => void {
  const open = useLeftModalOpen(modal)
  const dispatch = useAppDispatch()
  const size = useWindowSize()
  return useCallback(() => {
    if (
      (size?.width ?? MEDIA_WIDTHS.upToMedium) <= MEDIA_WIDTHS.upToSmall &&
      modal !== ApplicationModal.POST_CREATE &&
      modal !== ApplicationModal.POST_COMMENTS &&
      modal !== ApplicationModal.POST_LARGE_IMAGE &&
      modal !== ApplicationModal.PROPERTY_INFO &&
      modal !== ApplicationModal.MOBILE_CHATINFO &&
      modal !== ApplicationModal.CHATINFO
    ) {
      dispatch(setOpenModal(null))
    }
    dispatch(setOpenLeftModal(open ? null : modal))
  }, [dispatch, modal, open, size?.width])
}

export function useWalletModalToggle(): () => void {
  return useToggleModal(ApplicationModal.WALLET)
}

export function useToggleMenu(): () => void {
  return useToggleModal(ApplicationModal.MENU)
}

export function useToggleProfileSetting(): () => void {
  return useToggleModal(ApplicationModal.PROFILE_SETTING)
}

export function useToggleMyCollection(): () => void {
  return useToggleModal(ApplicationModal.MY_COLLECTION)
}

export function useTogglePlaces(): () => void {
  return useToggleModal(ApplicationModal.PLACES_LIST)
}

export function useToggleNotification(): () => void {
  return useToggleModal(ApplicationModal.NOTIFICATIONS)
}

export function useToggleCommunity(): () => void {
  return useToggleModal(ApplicationModal.COMMUNITY)
}

export function useTogglePostImage(): () => void {
  return useToggleLeftModal(ApplicationModal.POST_LARGE_IMAGE)
}

export function useTogglePostComments(): () => void {
  return useToggleLeftModal(ApplicationModal.POST_COMMENTS)
}

export function useTogglePostCreate(): () => void {
  return useToggleLeftModal(ApplicationModal.POST_CREATE)
}

export function useToggleTaskInfo(): () => void {
  return useToggleLeftModal(ApplicationModal.TASK_INFO)
}

export function useTogglePropertyInfo(): () => void {
  return useToggleLeftModal(ApplicationModal.PROPERTY_INFO)
}

export function useToggleConfirmationInfo(): () => void {
  return useToggleLeftModal(ApplicationModal.CONFIRMATION)
}

export function useToggleBuyProperty(): () => void {
  return useToggleLeftModal(ApplicationModal.BUY_PROPERTY)
}

export function useToggle3DInfo(): () => void {
  return useToggleLeftModal(ApplicationModal.THREED_INFO)
}

export function useToggle3DInfoMobile(): () => void {
  return useToggleLeftModal(ApplicationModal.THREED_INFO_MOBILE)
}

export function useToggle3DGalleryInfo(): () => void {
  return useToggleLeftModal(ApplicationModal.THREED_GALLERY_INFO)
}

export function useToggleOtherUser(): () => void {
  return useToggleLeftModal(ApplicationModal.OTHERUSER)
}

export function useToggleChatInfo(): () => void {
  return useToggleLeftModal(ApplicationModal.CHATINFO)
}

export function useToggleMobileChatInfo(): () => void {
  return useToggleLeftModal(ApplicationModal.MOBILE_CHATINFO)
}

export function useToggleLogin(): () => void {
  return useToggleTopModal(ApplicationModal.LOGIN)
}

export function useToggleTermOfUse(): () => void {
  return useToggleModal(ApplicationModal.TERMOFUSE)
}

export function useToggleGroundRules(): () => void {
  return useToggleModal(ApplicationModal.GROUNDRULES)
}

// returns a function that allows adding a popup
export function useAddPopup(): (content: PopupContent, key?: string, removeAfterMs?: number) => void {
  const dispatch = useAppDispatch()

  return useCallback(
    (content: PopupContent, key?: string, removeAfterMs?: number) => {
      dispatch(addPopup({ content, key, removeAfterMs: removeAfterMs ?? DEFAULT_TXN_DISMISS_MS }))
    },
    [dispatch]
  )
}

// returns a function that allows removing a popup via its key
export function useRemovePopup(): (key: string) => void {
  const dispatch = useAppDispatch()
  return useCallback(
    (key: string) => {
      dispatch(removePopup({ key }))
    },
    [dispatch]
  )
}

// get the list of active popups
export function useActivePopups(): AppState['application']['popupList'] {
  const list = useAppSelector((state: AppState) => state.application.popupList)
  return useMemo(() => list.filter((item) => item.show), [list])
}

import { createSlice } from '@reduxjs/toolkit'

export interface HomeState {
  daoId: string | undefined
  referenceId: string | undefined
  propertyId: string | undefined
  buildingId: string | undefined
  postImages: string[]
  currentImage: number
  buildingInfo: any
  propertyInfo: any
  depositId: string | undefined
  selectBuildingName: string | undefined
  postInfo: any | undefined
  otherUserId: any | undefined
  chatInfo: any | undefined
  showNotify: boolean
  showMessage: boolean
}

export const initialState: HomeState = {
  daoId: undefined,
  referenceId: undefined,
  propertyId: undefined,
  buildingId: undefined,
  postImages: [],
  currentImage: 0,
  buildingInfo: undefined,
  propertyInfo: undefined,
  depositId: undefined,
  selectBuildingName: undefined,
  postInfo: undefined,
  otherUserId: undefined,
  chatInfo: undefined,
  showNotify: false,
  showMessage: false
}

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    updateDaoId(state, { payload: { daoId } }: { payload: { daoId: string | undefined } }) {
      state.daoId = daoId
    },
    updateReferenceId(state, { payload: { referenceId } }: { payload: { referenceId: string | undefined } }) {
      state.referenceId = referenceId
    },
    updatePropertyId(state, { payload: { propertyId } }: { payload: { propertyId: string | undefined } }) {
      state.propertyId = propertyId
    },
    updateBuildingId(state, { payload: { buildingId } }: { payload: { buildingId: string | undefined } }) {
      state.buildingId = buildingId
    },
    updateSelectBuildingName(
      state,
      { payload: { selectBuildingName } }: { payload: { selectBuildingName: string | undefined } }
    ) {
      state.selectBuildingName = selectBuildingName
    },
    updateDepositId(state, { payload: { depositId } }: { payload: { depositId: string | undefined } }) {
      state.depositId = depositId
    },
    updatePostImages(
      state,
      { payload: { postImages, currentIndex } }: { payload: { postImages: string[]; currentIndex: number } }
    ) {
      state.postImages = postImages
      state.currentImage = currentIndex
    },
    updateBuildingInfo(state, { payload: { buildingInfo } }: { payload: { buildingInfo: any } }) {
      state.buildingInfo = buildingInfo
    },
    updatePropertyInfo(state, { payload: { propertyInfo } }: { payload: { propertyInfo: any } }) {
      state.propertyInfo = propertyInfo
    },
    updatePostInfo(state, { payload: { postInfo } }: { payload: { postInfo: any } }) {
      state.postInfo = postInfo
    },
    updateOtherId(state, { payload: { userId } }: { payload: { userId: any } }) {
      state.otherUserId = userId
    },
    updateChatInfo(state, action) {
      const { chatInfo } = action.payload
      state.chatInfo = chatInfo
    },
    updateShowNotify(state, action) {
      const { notify } = action.payload
      state.showNotify = notify
    },
    updateShowMessage(state, action) {
      const { notify } = action.payload
      state.showMessage = notify
    }
  }
})

export const {
  updateDaoId,
  updatePropertyId,
  updateReferenceId,
  updateBuildingId,
  updatePostImages,
  updateBuildingInfo,
  updatePropertyInfo,
  updateDepositId,
  updateSelectBuildingName,
  updatePostInfo,
  updateOtherId,
  updateChatInfo,
  updateShowNotify,
  updateShowMessage
} = homeSlice.actions
export default homeSlice.reducer

import { useSpring } from '@react-spring/web'
import DefaultProperty from 'assets/images/detail-image.png'
import MoreInfo from 'assets/images/icon-more-community.png'
import ArrowLeft from 'assets/images/icon-slider-left.png'
import ArrowRight from 'assets/images/icon-slider-right.png'
import { sendEvent } from 'components/Analytics'
import * as S from 'components/Community/styles'
import { CustomizeEvent } from 'constants/events'
import { PublicUrl } from 'constants/publicurl'
import useCopyClipboard from 'hooks/useCopyClipboard'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { useWindowSize } from 'hooks/useWindowSize'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AppState } from 'state'
import {
  useAddPopup,
  useLeftModalOpen,
  useModalOpen,
  useToggle3DGalleryInfo,
  //useToggle3DInfo,
  useToggleBuyProperty,
  useToggleConfirmationInfo,
  useTogglePostCreate,
  useTogglePropertyInfo
  //useToggleTaskInfo
} from 'state/application/hooks'
import { ApplicationModal, setCollsapeCommunity } from 'state/application/reducer'
import { useGetDaoInfoByReferenceIdMutation } from 'state/daoApi/slice'
import { updateBuildingInfo, updateDaoId, updateDepositId } from 'state/home/reducer'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { useGetPostListMutation } from 'state/postApi/slice'
import {
  useAddCollectionMutation,
  useGetBuildingInfoMutation,
  useGetCollectionListMutation,
  useGetDepositListMutation,
  useRemoveCollectionMutation
} from 'state/projectApi/slice'
import useBus from 'use-bus'
import { replaceImageUrl } from 'utils'

import PostCard from './PostCard'

export default function Community() {
  const buildingId = useAppSelector((state) => state.home.buildingId)
  const currentUserInfo = useAppSelector((state) => state.user.userInfo)

  const throttling = useRef(false)
  const [getDaoInfoByReferenceId] = useGetDaoInfoByReferenceIdMutation()
  const [getBuildingInfo] = useGetBuildingInfoMutation()
  const [getCollectionList] = useGetCollectionListMutation()
  const [getPostList] = useGetPostListMutation()
  const [getDepositList] = useGetDepositListMutation()
  const [buildingInfo, setBuildingInfo] = useState(null as any)
  const [depositedInfo, setDepositedInfo] = useState(null as any)
  const [addCollection] = useAddCollectionMutation()
  const [removeCollection] = useRemoveCollectionMutation()
  const [addedCollection, setAddedCollection] = useState([] as any[])

  const dispatch = useAppDispatch()
  const addPopup = useAddPopup()

  const open = useModalOpen(ApplicationModal.COMMUNITY)
  const togglePostCreate = useTogglePostCreate()

  const communityScroll = useRef(null as any)
  const headerRef = useRef(null as any)
  const [fixedDaoInfo, setFixedDaoInfo] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const [showHeaderMenu, setShowHeaderMenu] = useState(false)

  const pageSize = 20
  const [current, setCurrent] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [posts, setPosts] = useState([])
  const [isConfirmation, setIsConfrimation] = useState(false)
  const [curDaoId, setCurDaoId] = useState('')

  const [controller, setController] = useState(null as any)

  const reset = useCallback(() => {
    setPosts([])
    setCurDaoId('')
    setHasMore(true)
    setCurrent(1)
    setIsConfrimation(false)
    setBuildingInfo(null)
    setDepositedInfo(null)
    setFixedDaoInfo(false)
  }, [])
  useEffect(() => {
    if (open && buildingId) {
      getDaoInfoByReferenceId({ referenceId: buildingId })
        .then((data: any) => {
          if (data?.data?.code === 0) {
            dispatch(updateDaoId({ daoId: data?.data?.data?.daoId }))
            setCurDaoId(data?.data?.data?.daoId)
          } else {
            dispatch(updateDaoId({ daoId: undefined }))
            setPosts([].concat([]))
            setCurDaoId('')
          }
        })
        .catch((e) => {
          console.error(e)
          dispatch(updateDaoId({ daoId: undefined }))
          setPosts([])
          setCurDaoId('')
        })
      getBuildingInfo({ buildingId })
        .then((data: any) => {
          if (data?.data?.code === 0) {
            setBuildingInfo(data?.data?.data)

            dispatch(updateBuildingInfo({ buildingInfo: data?.data?.data }))
          }
        })
        .catch((e) => {
          console.error(e)
        })
      getCollectionList({})
        .then((data: any) => {
          setAddedCollection(data.data.data.data)
        })
        .catch((e) => {
          //
        })
      getDepositList({ buildingId })
        .then((data: any) => {
          if (data?.data?.code === 0) {
            if (data?.data?.data?.count > 0) {
              setIsConfrimation(true)
              setDepositedInfo(data?.data?.data?.data[0])
              dispatch(updateDepositId({ depositId: data?.data?.data?.data[0].id }))
            } else {
              setIsConfrimation(false)
              setDepositedInfo(null)
              dispatch(updateDepositId({ depositId: undefined }))
            }
          } else {
            setIsConfrimation(false)
            setDepositedInfo(null)
            dispatch(updateDepositId({ depositId: undefined }))
          }
        })
        .catch((e) => {
          console.error(e)
          setIsConfrimation(false)
          setDepositedInfo(null)
          dispatch(updateDepositId({ depositId: undefined }))
        })
    }
  }, [
    addPopup,
    buildingId,
    dispatch,
    getBuildingInfo,
    getCollectionList,
    getDaoInfoByReferenceId,
    getDepositList,
    open
  ])

  //const taskOpen = useLeftModalOpen(ApplicationModal.TASK_INFO + 100)
  //const toggleTask = useToggleTaskInfo()

  const infoOpen = useLeftModalOpen(ApplicationModal.PROPERTY_INFO)
  const toggleInfo = useTogglePropertyInfo()

  const buyOpen = useLeftModalOpen(ApplicationModal.BUY_PROPERTY)
  const toggleBuy = useToggleBuyProperty()

  const open3DInfo = useLeftModalOpen(ApplicationModal.THREED_INFO)
  //const toggle3DInfo = useToggle3DInfo()

  const open3GalleryInfo = useLeftModalOpen(ApplicationModal.THREED_GALLERY_INFO)
  const toggle3DGalleryInfo = useToggle3DGalleryInfo()

  const openConfirmation = useLeftModalOpen(ApplicationModal.CONFIRMATION)
  const toggleConfirmation = useToggleConfirmationInfo()

  const size = useWindowSize()

  const innerHeight = useMemo(() => {
    return size.clientHeight ? size.clientHeight - 46 : 0
  }, [size])

  const getPostListCall = useCallback(
    (current, daoId) => {
      let temp = controller
      if (current === 1) {
        if (controller) {
          controller.abort()
        }
        temp = new AbortController()
        setController(temp)
      }
      getPostList({ current, pageSize, daoId, signal: temp.signal })
        .then((data: any) => {
          if (data?.data?.code === 0) {
            setCurDaoId(daoId)
            if (data?.data?.data?.count < pageSize) {
              setHasMore(false)
            }
            if (current === 1) {
              setPosts([].concat(data?.data?.data?.data))
            } else {
              setPosts(posts.concat(data?.data?.data?.data))
            }
          }

          throttling.current = false
        })
        .catch((e) => {
          console.error(e)
          throttling.current = false
        })
    },
    [controller, getPostList, posts]
  )

  useEffect(() => {
    if (open && curDaoId) {
      setHasMore(true)
      setCurrent(1)
      getPostListCall(1, curDaoId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curDaoId, open])

  useBus(
    CustomizeEvent.REFRESH_POSTS,
    () => {
      setHasMore(true)
      setCurrent(1)
      getPostListCall(1, curDaoId)
    },
    [curDaoId]
  )

  useEffect(() => {
    if (!open) {
      reset()
    }
  }, [open, reset])

  const handleCreatePost = useCallback(() => {
    if (currentUserInfo?.userId) {
      togglePostCreate()
    } else {
      addPopup({ error: 'Please login to create post' })
    }
  }, [addPopup, currentUserInfo?.userId, togglePostCreate])

  const addToMyCollection = useCallback(
    (buildingId: string) => {
      addCollection({ buildingId })
        .then((data: any) => {
          if (data?.data?.code === 0) {
            sendEvent({
              category: 'Community',
              action: 'Collecting Communtiy',
              label: buildingId
            })
            addPopup({ tip: 'Great! You are now following this community.' })
            getCollectionList({})
              .then((data: any) => {
                setAddedCollection(data.data.data.data)
              })
              .catch((e) => {
                console.error(e)
              })
          } else {
            addPopup({ error: 'Failed to collect community. Please try again.' })
          }
        })
        .catch((e) => {
          console.error(e)
          addPopup({ error: 'Failed to collect community. Please try again.' })
        })
    },
    [addCollection, addPopup, getCollectionList]
  )
  const removeFromMyCollection = useCallback(
    (propertyId) => {
      const collection = addedCollection.filter((el) => el.buildingId.toString() === buildingId?.toString())
      const collectionId = collection[0].collectionId
      removeCollection({ collectionId })
        .then((data: any) => {
          if (data?.data.code === 0) {
            sendEvent({
              category: 'Community',
              action: 'UnCollecting Communtiy',
              label: buildingId
            })
            addPopup({ tip: 'You have unfollowed this community.' })
            getCollectionList({})
              .then((data: any) => {
                if (data?.data?.code === 0) {
                  setAddedCollection(data.data.data.data)
                } else {
                }
              })
              .catch((e) => {
                console.error(e)
              })
          } else {
            addPopup({ error: 'Failed to remove community. Please try again.' })
          }
        })
        .catch((e) => {
          console.error(e)
          addPopup({ error: 'Failed to remove community. Please try again.' })
        })
    },
    [addedCollection, removeCollection, buildingId, getCollectionList, addPopup]
  )

  const getCollectionType = function (item: any) {
    if (addedCollection !== null) {
      if (addedCollection?.some((el) => el.buildingId.toString() === buildingId?.toString())) {
        return true
      }
    }
    return false
  }

  const { transform } = useSpring({
    from: { transform: 'translate3d(104.39%,0,0)' },
    transform: open ? 'translate3d(0%,0,0)' : 'translate3d(104.39%,0,0)',
    config: { duration: 200 }
  })

  const collapseCommunity = useAppSelector((state: AppState) => state.application.collapseCommunity)
  const hanldeCollapse = useCallback(() => {
    dispatch(setCollsapeCommunity({ collapse: !collapseCommunity }))
  }, [collapseCommunity, dispatch])

  const menuPanel = useRef<HTMLDivElement>(null)
  useOnClickOutside(
    menuPanel,
    showMenu
      ? () => {
          setShowMenu(false)
        }
      : undefined
  )

  const menuHeaderPanel = useRef<HTMLDivElement>(null)
  useOnClickOutside(
    menuHeaderPanel,
    showHeaderMenu
      ? () => {
          setShowHeaderMenu(false)
        }
      : undefined
  )

  const [, setCopied] = useCopyClipboard()
  const shareCommunity = useCallback(() => {
    setCopied(
      encodeURI(PublicUrl(1) + '?community=' + buildingInfo?.name.trim().replace(/\s*/g, '') + ';' + buildingId)
    )
    addPopup({ tip: 'Copied to clipboard!' })
  }, [addPopup, buildingId, buildingInfo?.name, setCopied])

  return (
    <S.AnimationPanel style={{ transform }}>
      {open && (
        <S.Community innerheight={innerHeight}>
          <S.MobileHeaderPanel fixed={fixedDaoInfo}>
            <S.InfoContent>
              {buildingInfo && <S.InfoTitle>{buildingInfo?.name}</S.InfoTitle>}
              {buildingInfo && (
                <S.InfoSubtitle>
                  {(buildingInfo?.addressLine1 ?? '') +
                    (buildingInfo?.addressLine1 &&
                    (buildingInfo?.addressLine2 || buildingInfo?.area || buildingInfo?.city)
                      ? ', '
                      : '') +
                    (buildingInfo?.addressLine2 ?? '') +
                    (buildingInfo?.addressLine2 && (buildingInfo?.area || buildingInfo?.city) ? ', ' : '') +
                    (buildingInfo?.area ?? '') +
                    (buildingInfo?.area && buildingInfo?.city ? ', ' : '') +
                    (buildingInfo?.city ?? '') +
                    ', ' +
                    buildingInfo?.country ?? 'Australia'}
                </S.InfoSubtitle>
              )}
            </S.InfoContent>
            <S.ActionMore
              onClick={() => {
                setShowHeaderMenu(!showHeaderMenu)
              }}
            >
              <S.LikeImage alt="More Info" src={MoreInfo} />
            </S.ActionMore>
            {showHeaderMenu && (
              <S.ActionMenuPanel ref={menuHeaderPanel}>
                <S.ActionItem
                  onClick={() => {
                    if (!getCollectionType(buildingId)) {
                      addToMyCollection(buildingId ?? '')
                    } else {
                      removeFromMyCollection(buildingId)
                    }
                    setShowHeaderMenu(false)
                  }}
                >
                  {getCollectionType(buildingId) ? 'Unfollow community' : 'Follow community'}
                </S.ActionItem>
                <S.ActionItem
                  onClick={() => {
                    shareCommunity()
                    setShowHeaderMenu(false)
                  }}
                >
                  Share
                </S.ActionItem>
              </S.ActionMenuPanel>
            )}
          </S.MobileHeaderPanel>
          <S.MobileScroll
            ref={communityScroll}
            onScroll={(e: any) => {
              if (e.currentTarget.scrollTop >= headerRef.current.offsetTop) {
                setFixedDaoInfo(true)
              } else {
                setFixedDaoInfo(false)
              }
            }}
          >
            <S.ScrollPanel
              initialLoad={false}
              pageStart={1}
              threshold={250}
              loadMore={() => {
                if (throttling.current) {
                  return
                }
                throttling.current = true
                setTimeout(() => {
                  setCurrent(current + 1)
                  getPostListCall(current + 1, curDaoId)
                }, 200)
              }}
              hasMore={hasMore}
              useWindow={false}
              loader={<div key={1}></div>}
            >
              <S.HeaderPanel>
                <S.HeaderImage
                  src={buildingInfo?.images[0] ? replaceImageUrl(buildingInfo?.images[0], 1024) : DefaultProperty}
                  alt={'Building Image'}
                  onClick={() => {
                    if (buildingInfo && buildingInfo?.status && buildingInfo.status === 2) {
                      const newBuildingInfo = JSON.parse(JSON.stringify(buildingInfo))
                      newBuildingInfo.showSketcherFab = true
                      dispatch(updateBuildingInfo({ buildingInfo: newBuildingInfo }))
                      toggleInfo()
                    }
                  }}
                ></S.HeaderImage>

                {isConfirmation && (
                  <S.DepositedTip>
                    <S.TipContent>Deposited </S.TipContent>
                    <S.TimeContent>
                      {' '}
                      on{' '}
                      {depositedInfo?.createdAt
                        ? moment.utc(depositedInfo?.createdAt).local().format('DD MMM yyyy')
                        : ''}
                    </S.TimeContent>
                  </S.DepositedTip>
                )}
                <S.DaoInfoPanel ref={headerRef}>
                  <S.InfoContent>
                    {buildingInfo && <S.InfoTitle>{buildingInfo?.name}</S.InfoTitle>}
                    {buildingInfo && (
                      <S.InfoSubtitle>
                        {(buildingInfo?.addressLine1 ?? '') +
                          (buildingInfo?.addressLine1 &&
                          (buildingInfo?.addressLine2 || buildingInfo?.area || buildingInfo?.city)
                            ? ', '
                            : '') +
                          (buildingInfo?.addressLine2 ?? '') +
                          (buildingInfo?.addressLine2 && (buildingInfo?.area || buildingInfo?.city) ? ', ' : '') +
                          (buildingInfo?.area ?? '') +
                          (buildingInfo?.area && buildingInfo?.city ? ', ' : '') +
                          (buildingInfo?.city ?? '') +
                          ', ' +
                          buildingInfo?.country ?? 'Australia'}
                      </S.InfoSubtitle>
                    )}
                  </S.InfoContent>
                  <S.ActionMore
                    onClick={() => {
                      setShowMenu(!showMenu)
                    }}
                  >
                    <S.LikeImage alt="More Info" src={MoreInfo} />
                  </S.ActionMore>

                  {showMenu && (
                    <S.ActionMenuPanel ref={menuPanel}>
                      <S.ActionItem
                        onClick={() => {
                          if (!getCollectionType(buildingId)) {
                            addToMyCollection(buildingId ?? '')
                          } else {
                            removeFromMyCollection(buildingId)
                          }
                          setShowMenu(false)
                        }}
                      >
                        {getCollectionType(buildingId) ? 'Unfollow community' : 'Follow community'}
                      </S.ActionItem>
                      <S.ActionItem
                        onClick={() => {
                          shareCommunity()
                          setShowMenu(false)
                        }}
                      >
                        Share
                      </S.ActionItem>
                    </S.ActionMenuPanel>
                  )}
                </S.DaoInfoPanel>
              </S.HeaderPanel>
              <S.Content>
                <S.Tabs
                  style={{
                    display: buildingInfo && buildingInfo?.status && buildingInfo.status === 2 ? '' : 'none'
                  }}
                >
                  <S.FirstTab
                    isConfirmation={false}
                    onClick={() => {
                      dispatch(updateBuildingInfo({ buildingInfo }))
                      toggleInfo()
                    }}
                  >
                    <S.TabContent isToggle={infoOpen}>Info</S.TabContent>
                  </S.FirstTab>
                  <S.Tab
                    isConfirmation={false}
                    onClick={() => {
                      dispatch(updateBuildingInfo({ buildingInfo }))
                      toggle3DGalleryInfo()
                    }}
                  >
                    <S.TabContent isToggle={open3GalleryInfo || open3DInfo}>VR</S.TabContent>
                  </S.Tab>
                  {/*<S.Tab isConfirmation={false} onClick={toggleTask}>
                    <S.TabContent isToggle={taskOpen}>Task</S.TabContent>
                  </S.Tab>*/}

                  {isConfirmation ? (
                    <S.Tab isConfirmation={isConfirmation} onClick={toggleConfirmation}>
                      <S.TabContent isToggle={openConfirmation}>View Confirmation</S.TabContent>
                    </S.Tab>
                  ) : (
                    <S.Tab
                      isConfirmation={false}
                      onClick={() => {
                        if (currentUserInfo?.userId) {
                          toggleBuy()
                        } else {
                          addPopup({ error: 'Please connect wallet to buy.' })
                        }
                      }}
                    >
                      <S.TabContent isToggle={buyOpen}>Buy</S.TabContent>
                    </S.Tab>
                  )}
                </S.Tabs>
                <S.PostList
                  innerheight={innerHeight}
                  showTabs={buildingInfo && buildingInfo?.status && buildingInfo.status === 2 ? 1 : 0}
                >
                  <S.ScrollPanel
                    initialLoad={false}
                    pageStart={1}
                    threshold={250}
                    loadMore={() => {
                      if (throttling.current) {
                        return
                      }
                      throttling.current = true
                      setTimeout(() => {
                        setCurrent(current + 1)
                        getPostListCall(current + 1, curDaoId)
                      }, 200)
                    }}
                    hasMore={hasMore}
                    useWindow={false}
                    loader={<div key={1}></div>}
                  >
                    {/* <S.Grid key={2}> */}
                    {posts.length > 0 ? (
                      posts.map((item: any, index) => {
                        return <PostCard lastItem={posts.length - 1 === index} key={item?.id} item={item}></PostCard>
                      })
                    ) : (
                      <S.Nothing>Nothing is here, be the first one to post!</S.Nothing>
                    )}
                    {/* </S.Grid> */}
                  </S.ScrollPanel>
                </S.PostList>
              </S.Content>
            </S.ScrollPanel>
          </S.MobileScroll>
          {curDaoId && <S.CreateButton onClick={handleCreatePost}>Create Post</S.CreateButton>}
        </S.Community>
      )}

      {open && (
        <S.SliderButton id="js-slider" onClick={hanldeCollapse}>
          <S.ArrowIcon src={collapseCommunity ? ArrowLeft : ArrowRight} alt="arrow-icon"></S.ArrowIcon>
        </S.SliderButton>
      )}
    </S.AnimationPanel>
  )
}

import { ReactComponent as Close } from 'assets/svg/x.svg'
import ReloadImage from 'components/ReloadImage'
import { ResizingTextArea } from 'components/TextInput'
import styled from 'styled-components/macro'
import { CommonPanel, PANEL_HEIGHTS } from 'theme'

export const Panel = styled(CommonPanel)<{ width?: number | undefined; innerheight?: number }>`
  position: relative;
  margin-right: 1.45vw;
  height: 100%;
  flex: 1;
  height: ${PANEL_HEIGHTS.left};

  ${({ theme, innerheight }) => theme.mediaWidth.upToExtraLarge`
    height: ${innerheight ? innerheight * 0.955 + 'px' : '96.5vh'};
    margin-right: 0.83vw;
  `};

  ${({ theme, innerheight }) => theme.mediaWidth.upToLarge`
    height: ${innerheight ? innerheight * 0.975 + 'px' : '97.5vh'};
    margin-right: 0.83vw;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 0px;
  `};
`

export const CloseIcon = styled.div`
  position: absolute;
  top: 1.61vh;
  right: 1.04vw;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 2.49vh;
    right: 5.38vw;
    z-index: 1;
  `}
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`
export const CloseColor = styled(Close)`
  color: ${({ theme }) => theme.text4};
  width: 0.87vw;
  height: 0.87vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 3.85vw;
    height: 3.85vw;
  `}
`

export const Content = styled.div`
  height: 100%;
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3.31vh 3.18vw 2.15vh 1.91vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 6.4vh 4.1vw 0 3.33vw;
    display: block;
  `}
`

export const InfoPanel = styled.div`
  scrollbar-color: ${({ theme }) => theme.bg0};
  display: flex;
  flex-direction: row;
  overflow: auto;
  width: 100%;
  /* 滚动槽 */
  ::-webkit-scrollbar {
    width: 0.16vw;
    height: 0.16vw;
  }
  ::-webkit-scrollbar-track {
    border-radius: 0.08vw;
  }
  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    border-radius: 0.08vw;
  }
`

export const AvatarPanel = styled.div`
  width: 2.95vh;
  height: 2.95vh;
  .reload-image-error {
    border-radius: 50%;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 3.91vh;
    height: 3.91vh;
  `}
`

export const Avatar = styled(ReloadImage)`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`

export const PostImagesPanel = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 0.46vw;
  justify-content: center;
  margin-bottom: 2.24vh;
  margin-top: 2.24vh;
  max-height: 70.55vh;
  padding-left: 3.07vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-gap: 0.59vh;
    margin-top: 0;
    margin-bottom: 0;
    max-height: 42.77vh;
    grid-template-rows: none;
  `};
`

export const PostInfoPanel = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 1.27vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-left: 2.82vw;
  `}
`

export const EmptyImage = styled.div<{ empty: boolean; isUpload: boolean }>`
  border: ${({ empty }) => (empty ? '1px solid rgba(222, 222, 222, 0.22)' : 'none')};
  border-radius: 4px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 22.56vh;
  label {
    width: 100%;
    height: 100%;
    cursor: ${({ empty }) => (empty ? 'auto' : 'pointer')};
  }
  ${({ empty, theme }) => theme.mediaWidth.upToSmall`
    display: ${empty ? 'none' : 'flex'};
    height: 13.39vh;
  `};

  background: ${({ isUpload }) => (isUpload ? 'rgba(222, 222, 222, 0.06)' : 'none')};
`

export const PostImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 4px;
`

export const DeleteIcon = styled.img`
  position: absolute;
  width: 2.06vh;
  height: 2.06vh;
  top: 0.72vh;
  right: 0.46vw;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 2.73vh;
    height: 2.73vh;
    top: 0.71vh;
    right: 1.54vw;
  `}
`

export const UploadPanel = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const AddIcon = styled.img`
  width: 1.88vh;
  height: 1.88vh;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 2.49vh;
    height: 2.49vh;
  `}
`

export const UploadText = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 1.25vh;
  margin-top: 0.63vh;

  color: rgba(222, 222, 222, 0.6);
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `}
`

export const PostInput = styled(ResizingTextArea)`
  display: flex;
  background: none;
  width: auto;
  align-items: center;
  background: none;
  justify-content: center;
  margin-top: 0.72vh;
  width: 100%;
  height: 10.03vh;
  max-height: 10.03vh;
  overflow: auto;
  font-weight: 300;
  font-size: 1.43vh;
  color: rgba(222, 222, 222, 0.6);
  caret-color: #2142ff;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 0.47vh;
    height: 10.66vh;
    max-height: 10.66vh;
    font-size: 1.66vh;
    color: ${({ theme }) => theme.text4};
  `}

  ::placeholder {
    font-style: normal;
    font-weight: 300;
    font-size: 1.43vh;
    color: rgba(222, 222, 222, 0.22);
    padding-left: 0.46vw;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      font-size: 1.9vh;
      padding-left: 2.05vw;
    `}
  }

  /* 滚动槽 */
  ::-webkit-scrollbar {
    width: 0.16vw;
    height: 0.16vw;
  }
  ::-webkit-scrollbar-track {
    border-radius: 0.08vw;
  }
  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    border-radius: 0.08vw;
  }
`

export const PostButton = styled.div<{ disabled: boolean; posting: boolean }>`
  background: ${({ disabled, theme }) => (disabled ? 'rgba(222, 222, 222, 0.06)' : theme.primary1)};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  font-style: normal;
  font-weight: 400;
  font-size: 1.43vh;
  border-radius: 0.72vh;
  width: 100%;
  text-align: center;
  padding: 0.54vh 0px;
  margin-left: 1.22vw;
  color: ${({ posting, disabled, theme }) =>
    posting ? theme.text4 : disabled ? 'rgba(222, 222, 222, 0.06)' : theme.text4};
  cursor: ${({ posting }) => (posting ? 'default' : 'pointer')};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 3.79vh;
    font-size: 1.9vh;
    border-radius: 0.72vh;
    padding: 0.71vh 0px;
    margin-left: 1.22vw;
  `}
`

import { useSpring } from '@react-spring/web'
import Guest from 'assets/images/icon-guest.png'
import { sendEvent } from 'components/Analytics'
import * as S from 'components/CommentsDetailPanel/styles'
import { CustomizeEvent } from 'constants/events'
import { getShowTime } from 'constants/misc'
import { arrayUnion, doc, getDoc, setDoc, Timestamp, updateDoc } from 'firebase/firestore'
import { useWindowSize } from 'hooks/useWindowSize'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import uuid from 'react-uuid'
import remarkGfm from 'remark-gfm'
import { useAddPopup, useLeftModalOpen, useTogglePostComments } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { useAppSelector } from 'state/hooks'
import { useGetCommentListMutation, usePostCommentMutation } from 'state/postApi/slice'
import { MEDIA_WIDTHS } from 'theme'
import { ExternalLink } from 'theme/components'
import { dispatch as dispatchEvent } from 'use-bus'
import { replaceImageUrl } from 'utils'
import { db } from 'utils/firestore'
import { shortAddress } from 'utils/safeNamehash'

import Comment from './Comment'

export default function CommentsDetailPanel() {
  const open = useLeftModalOpen(ApplicationModal.POST_COMMENTS)
  const postInfo = useAppSelector((state: any) => state.home.postInfo)
  const daoId = useAppSelector((state) => state.home.daoId)
  const userProfile = useAppSelector((state) => state.user.userProfile)
  const currentUserInfo = useAppSelector((state) => state.user.userInfo)
  const buildingInfo = useAppSelector((state) => state.home.buildingInfo)
  const toggle = useTogglePostComments()
  const [commentText, setCommentText] = useState('')
  const [getCommentList] = useGetCommentListMutation()
  const [postComment] = usePostCommentMutation()
  const addPopup = useAddPopup()
  const [comments, setComments] = useState([] as any[])
  const [current, setCurrent] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const pageSize = 20
  const [replyUser, setReplyUser] = useState('')
  const [replayId, setReplyId] = useState('')
  const [commentType, setCommentType] = useState('post')
  const [refUsers, setRefUsers] = useState([] as any[])
  const [loading, setLoading] = useState(false)
  const throttling = useRef(false)
  let commentInput = useRef(null as any)

  const [curPostId, setCurPostId] = useState('')

  const getCommentsListCall = useCallback(
    (page, id) => {
      getCommentList({ upID: id, current: page, pageSize })
        .then((data: any) => {
          if (data?.data?.code === 0) {
            setCurPostId(postInfo?.postId)
            if (data?.data?.data?.data?.length < pageSize) {
              setHasMore(false)
            }
            if (page === 1) {
              setComments([].concat(data?.data?.data?.data))
            } else {
              setComments(comments.concat(data?.data?.data?.data))
            }
          }

          throttling.current = false
        })
        .catch((e) => {
          console.error(e)
          throttling.current = false
          addPopup({ error: 'Error!' })
        })
    },
    [addPopup, comments, getCommentList, postInfo?.postId]
  )

  const reset = useCallback(() => {
    setCurrent(1)
    setHasMore(true)
    setComments([])
    setReplyUser('')
    setReplyId('')
    setCommentType('post')
    setRefUsers([])
    setLoading(false)
  }, [])

  useEffect(() => {
    if (open && postInfo?.postId && curPostId !== postInfo?.postId) {
      setCurrent(1)
      setHasMore(true)
      setCommentText('')
      getCommentsListCall(1, postInfo?.postId)

      if (commentInput.current) {
        commentInput.current.focus()
      }
    }
    if (!open) {
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, postInfo?.postId])

  const handleSubmitComment = useCallback(() => {
    if (
      commentText.replace(replyUser, '').length > 0 &&
      commentText.replace(replyUser, '').trim().length > 0 &&
      !loading
    ) {
      setLoading(true)
      postComment({
        content: commentText.replace(replyUser, ''),
        upID: replayId !== '' ? replayId : postInfo?.postId,
        daoId: daoId ?? '',
        refUsers,
        type: commentType
      })
        .then((data: any) => {
          if (data?.data?.code === 0) {
            if (commentType === 'post') {
              sendEvent({
                category: 'Comment',
                action: 'Comment Success',
                label: postInfo?.postId
              })

              sendEvent({
                category: 'Comment',
                action: 'Comment Content',
                label: postInfo?.postId,
                value: commentText.length
              })
              dispatchEvent(CustomizeEvent.REFRESH_COMMENT + postInfo?.postId)
            } else {
              sendEvent({
                category: 'Reply',
                action: 'Reply Success',
                label: postInfo?.postId
              })
              sendEvent({
                category: 'Reply',
                action: 'Reply Content',
                label: postInfo?.postId,
                value: commentText.length
              })
              dispatchEvent(CustomizeEvent.REFRESH_REPLIES + replayId)
            }
            setCommentText('')
            setReplyUser('')
            setReplyId(postInfo?.postId)
            setCommentType('post')
            setRefUsers([])

            getDoc(doc(db, 'userNotifications', postInfo?.owner))
              .then((res) => {
                if (!res.exists()) {
                  setDoc(doc(db, 'userNotifications', postInfo?.owner), {})
                    .then(() => {
                      if (commentType === 'post') {
                        updateDoc(doc(db, 'userNotifications', postInfo?.owner), {
                          messages: arrayUnion({
                            id: uuid(),
                            type: 'comment',
                            sendUser: {
                              uid: currentUserInfo?.userId,
                              displayName: userProfile?.nickName,
                              photoURL: userProfile?.avatar
                            },
                            community: {
                              name: buildingInfo?.name,
                              id: buildingInfo?.id
                            },
                            daoId,
                            postId: postInfo?.postId,
                            upId: postInfo?.postId,
                            date: Timestamp.now(),
                            hasUnread: true
                          })
                        }).catch((e) => {
                          console.error(e)
                        })
                      } else {
                        updateDoc(doc(db, 'userNotifications', refUsers[0]), {
                          messages: arrayUnion({
                            id: uuid(),
                            type: 'replay',
                            sendUser: {
                              uid: currentUserInfo?.userId,
                              displayName: userProfile?.nickName,
                              photoURL: userProfile?.avatar
                            },
                            community: {
                              name: buildingInfo?.name,
                              id: buildingInfo?.id
                            },
                            daoId,
                            postId: postInfo?.postId,
                            upId: replayId,
                            date: Timestamp.now(),
                            hasUnread: true
                          })
                        }).catch((e) => {
                          console.error(e)
                        })
                      }
                    })
                    .catch((e) => {
                      console.error(e)
                    })
                } else {
                  if (commentType === 'post') {
                    updateDoc(doc(db, 'userNotifications', postInfo?.owner), {
                      messages: arrayUnion({
                        id: uuid(),
                        type: 'comment',
                        sendUser: {
                          uid: currentUserInfo?.userId,
                          displayName: userProfile?.nickName,
                          photoURL: userProfile?.avatar
                        },
                        community: {
                          name: buildingInfo?.name,
                          id: buildingInfo?.id
                        },
                        daoId,
                        postId: postInfo?.postId,
                        upId: postInfo?.postId,
                        date: Timestamp.now(),
                        hasUnread: true
                      })
                    }).catch((e) => {
                      console.error(e)
                    })
                  } else {
                    updateDoc(doc(db, 'userNotifications', refUsers[0]), {
                      messages: arrayUnion({
                        id: uuid(),
                        type: 'replay',
                        sendUser: {
                          uid: currentUserInfo?.userId,
                          displayName: userProfile?.nickName,
                          photoURL: userProfile?.avatar
                        },
                        community: {
                          name: buildingInfo?.name,
                          id: buildingInfo?.id
                        },
                        daoId,
                        postId: postInfo?.postId,
                        upId: replayId,
                        date: Timestamp.now(),
                        hasUnread: true
                      })
                    }).catch((e) => {
                      console.error(e)
                    })
                  }
                }
              })
              .catch((e) => {
                console.error(e)
              })

            if (data?.data?.data?.dailyTaskResp && data?.data?.data?.dailyTaskResp?.executed) {
              addPopup({ tip: `Congratulation! Task Reward ${data?.data?.data?.dailyTaskResp?.asset?.quantity}` })
            } else {
              addPopup({ tip: 'Your comment was successfully submitted!' })
            }
          } else {
            if (data?.error?.status === 429) {
              addPopup({ error: "You're commenting too quickly. Please wait a few minutes and try again." })
            } else {
              addPopup({ error: 'The comment was unsuccessful. Please Try again.' })
            }

            if (commentType === 'post') {
              sendEvent({
                category: 'Comment',
                action: 'Comment Unsuccess',
                label: postInfo?.postId
              })
            } else {
              sendEvent({
                category: 'Reply',
                action: 'Reply Unsuccess',
                label: postInfo?.postId
              })
            }
          }
          setLoading(false)

          setCurrent(1)
          setHasMore(true)
          getCommentsListCall(1, postInfo?.postId)
        })
        .catch((e) => {
          console.error(e)
          setLoading(false)
          addPopup({ error: 'The comment was unsuccessful. Please Try again.' })

          if (commentType === 'post') {
            sendEvent({
              category: 'Comment',
              action: 'Comment Unsuccess',
              label: postInfo?.postId
            })
          } else {
            sendEvent({
              category: 'Reply',
              action: 'Reply Unsuccess',
              label: postInfo?.postId
            })
          }
        })
    }
  }, [
    addPopup,
    buildingInfo?.id,
    buildingInfo?.name,
    commentText,
    commentType,
    currentUserInfo?.userId,
    daoId,
    getCommentsListCall,
    loading,
    postComment,
    postInfo?.owner,
    postInfo?.postId,
    refUsers,
    replayId,
    replyUser,
    userProfile?.avatar,
    userProfile?.nickName
  ])

  const size = useWindowSize()
  const isMobile = (size?.width ?? MEDIA_WIDTHS.upToMedium) <= MEDIA_WIDTHS.upToSmall
  const style = useSpring({
    from: { opacity: 0, display: 'none', transform: isMobile ? 'translate3d(-100%,0,0)' : 'translate3d(-72.98vw,0,0)' },
    opacity: open ? 1 : 0,
    display: open ? 'flex' : 'none',
    transform: open ? 'translate3d(0,0,0)' : isMobile ? 'translate3d(-100%,0,0)' : 'translate3d(-72.98vw,0,0)',
    config: { duration: 200 }
  })
  const innerHeight = useMemo(() => {
    return size.clientHeight ? size.clientHeight : 0
  }, [size])

  return (
    <S.Panel style={style} innerheight={innerHeight}>
      <S.CloseIcon onClick={toggle}>
        <S.CloseColor />
      </S.CloseIcon>
      <S.Content>
        <S.PostContainer>
          <S.AvatarPanel role={postInfo?.role ?? 'customer'}>
            <S.Avatar
              src={postInfo?.avatar ? replaceImageUrl(postInfo?.avatar, 200) : Guest}
              alt={'comment avatar'}
            ></S.Avatar>
          </S.AvatarPanel>
          <S.HeaderContainer>
            <S.HeaderTop>
              <S.AccountName>{postInfo?.nickName ?? shortAddress(postInfo?.owner, 6, 4)}</S.AccountName>
              <S.HeaderTime>
                <S.TimeInfo>{getShowTime(postInfo?.createdAt)}</S.TimeInfo>
              </S.HeaderTime>
            </S.HeaderTop>
            {postInfo?.content && (
              <S.HeaderContent>
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  components={{
                    // eslint-disable-next-line react/display-name
                    a: ({ ...props }) => <ExternalLink href="" {...props} />
                  }}
                >
                  {postInfo.content}
                </ReactMarkdown>
              </S.HeaderContent>
            )}
            {postInfo?.images && postInfo?.images.length > 0 && (
              <S.ImageGrid>
                {postInfo?.images.map((image: string, index: number) => {
                  return (
                    <S.PostImagePanel key={index}>
                      <S.PostImage src={replaceImageUrl(image, 600)} alt="post image"></S.PostImage>
                    </S.PostImagePanel>
                  )
                })}
              </S.ImageGrid>
            )}
          </S.HeaderContainer>
        </S.PostContainer>
        <S.ScrollPanel
          initialLoad={false}
          pageStart={1}
          threshold={250}
          loadMore={() => {
            if (throttling.current) {
              return
            }
            throttling.current = true
            setTimeout(() => {
              setCurrent(current + 1)
              getCommentsListCall(current + 1, postInfo?.postId)
            }, 200)
          }}
          hasMore={hasMore}
          useWindow={false}
          loader={<div key={1}></div>}
        >
          {comments.map((item) => {
            return (
              <Comment
                onReply={(name, id, users) => {
                  if (replyUser) {
                    setCommentText(commentText.replace(replyUser, '@' + name + ' '))
                  } else {
                    setCommentText('@' + name + ' ' + commentText)
                  }
                  setReplyUser('@' + name + ' ')
                  setReplyId(id)
                  setCommentType('comment')
                  setRefUsers(users)
                  if (commentInput.current) {
                    commentInput.current.focus()
                  }
                }}
                key={item?.id}
                item={item}
              ></Comment>
            )
          })}
        </S.ScrollPanel>
      </S.Content>
      <S.GradientPanel></S.GradientPanel>
      <S.CommentsPanel>
        <S.AvatarPanel style={{ marginLeft: 33, display: 'none' }} role={userProfile?.role ?? 'customer'}>
          <S.Avatar
            src={userProfile?.avatar ? replaceImageUrl(userProfile?.avatar, 200) : Guest}
            alt={'comment avatar'}
          ></S.Avatar>
        </S.AvatarPanel>
        <S.CommentInput
          resize={true}
          value={commentText}
          onUserInput={function (value: string): void {
            if (value.length > 2000) {
              addPopup({ error: "Comment can't exceed 2000 characters" })
              value = value.substring(0, 2000)
            }
            setCommentText(value)
          }}
          placeholder={'Write a comment here'}
          replyUser={replyUser}
          refCallback={(ref) => {
            commentInput = ref
          }}
          onKeyDown={(e: any) => {
            if (e.keyCode === 8 || e.keyCode === 46) {
              if (replyUser && commentInput.current.selectionStart <= replyUser?.length) {
                if (commentText.length <= replyUser.length) {
                  setCommentText('')
                } else {
                  setCommentText(commentText.replace(replyUser, ''))
                }

                setReplyUser('')
                setReplyId('')
                setCommentType('post')
                setRefUsers([])
                return false
              }
            }
            return true
          }}
        ></S.CommentInput>
        <S.SendButton
          onClick={handleSubmitComment}
          disabled={
            commentText.replace(replyUser, '').length === 0 ||
            commentText.replace(replyUser, '').trim().length === 0 ||
            loading
          }
        >
          Send
        </S.SendButton>
      </S.CommentsPanel>
    </S.Panel>
  )
}

import * as S from 'components/MapBox/styles'
import { addDoc, collection } from 'firebase/firestore'
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from 'mapbox-gl'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useModalOpen, useToggleCommunity } from 'state/application/hooks'
import { ApplicationModal, setOpenModal } from 'state/application/reducer'
import { setCollsapeCommunity } from 'state/application/reducer'
import { updateBuildingId, updateSelectBuildingName } from 'state/home/reducer'
import { useAppDispatch } from 'state/hooks'
import { useAppSelector } from 'state/hooks'
import { useGet3dMapBuildingMutation, useGetMapBuildingMutation } from 'state/projectApi/slice'
import { Threebox } from 'threebox-plugin'
import { db } from 'utils/firestore'

// eslint-disable-next-line import/no-webpack-loader-syntax, @typescript-eslint/no-var-requires
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

mapboxgl.accessToken = 'pk.eyJ1IjoiYXVzdGluYzIwMjIiLCJhIjoiY2xienFsdXhwMGJpejNxcDd0ZDUyamU3biJ9.BC7C-elNHQ8Y_XiRi9Qp_w'

export default function MapBox() {
  const mapContainer = useRef(null as any)
  const map = useRef(null as any)
  const lat = useAppSelector((state) => state.application.mapCenterLat)
  const lng = useAppSelector((state) => state.application.mapCenterLng)
  const mapCenter = useMemo(() => {
    return {
      lat,
      lng
    }
  }, [lat, lng])
  const [zoom] = useState(15.72)
  const [getMapBuildings] = useGetMapBuildingMutation()
  const [get3dMapBuildings] = useGet3dMapBuildingMutation()
  const open = useModalOpen(ApplicationModal.COMMUNITY)

  const dispatch = useAppDispatch()
  const toggle = useToggleCommunity()
  const currentUserInfo = useAppSelector((state) => state.user.userInfo)

  const clickBuilding = useCallback(
    (bid: number, name: string) => {
      dispatch(setOpenModal(null))
      ;(window as any).openBuildingDao = true
      if ((window as any).openBuildingDao === true) {
        setTimeout(() => {
          dispatch(setCollsapeCommunity({ collapse: false }))
          dispatch(updateBuildingId({ buildingId: bid.toString() }))
          dispatch(updateSelectBuildingName({ selectBuildingName: name }))
          if (!open) {
            toggle()
          }
          ;(window as any).openBuildingDao = false
          addDoc(collection(db, 'ACTIONS'), {
            ACTION: 'DAO',
            TIME: new Date().toLocaleDateString('en-AU'),
            TIMESTAMP: Date.now(),
            USER_ID: currentUserInfo?.userId !== undefined ? currentUserInfo.userId : ''
          })
        }, 100)
      }
    },
    [currentUserInfo?.userId, dispatch, open, toggle]
  )

  /*function getZoomedIconWidth() {
    const currentZoom = window.mapbox.getZoom()
    if (currentZoom >= 20) {
      return '20px'
    }
    if (currentZoom >= 15) {
      return '12px'
    }
    return '12px'
  }*/

  /*document.addEventListener('mousemove', function (e) {
    let found = false
    const mapboxDiv = document.getElementsByClassName('mapboxgl-map')[0]
    for (let i = 0; i < document.getElementsByClassName('markerlabel').length; i++) {
      const el = document.getElementsByClassName('markerlabel')[i]
      const elemRect = el.getBoundingClientRect()
      if (
        e.clientX > elemRect.left &&
        e.clientX < elemRect.left + elemRect.width &&
        e.clientY > elemRect.top &&
        e.clientY < elemRect.top + elemRect.height
      ) {
        found = true
      }
    }
    if (mapboxDiv instanceof HTMLElement) {
      if (found) {
        console.log('found')
        const css = document.createElement('style')
        css.type = 'text/css'
        css.innerHTML = '*{cursor: move;}'
        document.body.appendChild(css)
        const css = document.querySelector('.mapboxgl-map *')
        console.log(css?.parentElement)
        if (css instanceof HTMLElement) {
          css.style.cursor = 'move !important'
        }
      } else {
        //document.documentElement.style.cursor = 'default'
        console.log('not found')
      }
    }
  })*/

  /*document.addEventListener('click', function (e) {
    let element = null
    let elementArea = 10000
    if (e.target instanceof HTMLElement) {
      if (e.target.id !== 'labelCanvas') {
        return
      }
    } else {
      return
    }
    for (let i = 0; i < document.getElementsByClassName('markerlabel').length; i++) {
      const el = document.getElementsByClassName('markerlabel')[i]
      const elemRect = el.getBoundingClientRect()
      if (
        e.clientX > elemRect.left &&
        e.clientX < elemRect.left + elemRect.width &&
        e.clientY > elemRect.top &&
        e.clientY < elemRect.top + elemRect.height
      ) {
        if (el instanceof HTMLElement) {
          const area =
            Math.abs(elemRect.left + elemRect.width / 2 - e.clientX) *
            Math.abs(elemRect.top + elemRect.height / 2 - e.clientY)
          if (area < elementArea && el.dataset.bid !== undefined) {
            element = el
            elementArea = area
          }
        }
      }
    }
    if (element instanceof HTMLElement) {
      if ((window as any).selectedMarker !== null) {
        ;(window as any).selectedMarker.style.width = getZoomedIconWidth()
        ;(window as any).selectedMarker.style.height = getZoomedIconWidth()
        ;(window as any).selectedMarker.style.backgroundImage = `url('svg/dot.png')`
      }
      const markerElement = document.getElementsByClassName(
        'js-marker-' + (element.dataset.bid !== undefined ? element.dataset.bid.toString() : '')
      )[0]
      if (markerElement !== null && markerElement instanceof HTMLElement) {
        markerElement.style.backgroundImage = `url('images/marker_ani.gif')`
        markerElement.style.width = `36px`
        markerElement.style.height = `49px`
        ;(window as any).selectedMarker = markerElement
      }
      clickBuilding(
        parseInt(element.dataset.bid !== undefined ? element.dataset.bid.toString() : ''),
        element.dataset.bname !== undefined ? element.dataset.bname.toString() : ''
      )
    }
  })*/
  /*const handleMapBuildingClick = useCallback(
    (bid) => {
      dispatch(setCollsapeCommunity({ collapse: false }))
      dispatch(updateBuildingId({ buildingId: bid }))
      if (!open) {
        toggle()
      }
    },
    [dispatch, open, bid, toggle]
  )*/

  const fetchBuilidngs = useCallback(() => {
    const currentZoom = map.current.getZoom()
    if (currentZoom < 14.5) {
      const markersContent = document.getElementsByClassName('mapboxgl-popup-content')
      for (let i = 0; i < markersContent.length; i++) {
        ;(markersContent[i] as HTMLElement).style.display = 'none'
      }
    } else {
      const markersContent = document.getElementsByClassName('mapboxgl-popup-content')
      for (let i = 0; i < markersContent.length; i++) {
        ;(markersContent[i] as HTMLElement).style.display = ''
      }
    }
    //if (map.current) return
    //{lng: 144.95447178267057, lat: -37.80346582162169}
    if (map.current) {
      const vector1 = map.current?.getBounds().getNorthEast()
      const vector2 = map.current?.getBounds().getSouthWest()
      //const { lng, lat } = map.current.getCenter()
      getMapBuildings({
        latitudeLeft: vector2.lat,
        latitudeRight: vector1.lat,
        longitudeLeft: vector2.lng,
        longitudeRight: vector1.lng
      })
        .then((res: any) => {
          const buildings = res.data.data.data
          const buildingModels: any[] = []
          for (let i = 0; i < buildings.length; i++) {
            /*const el = document.createElement('div')
            el.className = 'marker js-marker-' + buildings[i].id.toString()
            el.style.backgroundImage = `url('svg/dot.png')`
            el.style.width = getZoomedIconWidth()
            el.style.height = getZoomedIconWidth()
            el.style.backgroundSize = '100%'
            el.style.zIndex = `1`
            el.onclick = function (e) {
              if ((window as any).selectedMarker !== null) {
                ;(window as any).selectedMarker.style.width = getZoomedIconWidth()
                ;(window as any).selectedMarker.style.height = getZoomedIconWidth()
                ;(window as any).selectedMarker.style.backgroundImage = `url('svg/dot.png')`
              }
              el.style.backgroundImage = `url('images/marker_ani.gif')`
              el.style.width = `36px`
              el.style.height = `49px`
              ;(window as any).selectedMarker = el
              clickBuilding(buildings[i].id, buildings[i].name)

              const popupLabel = document.getElementsByClassName('js-markerlabel-' + buildings[i].id.toString())[0]
              if (popupLabel !== null && popupLabel !== undefined && popupLabel instanceof HTMLElement) {
                popupLabel.dataset.bid = buildings[i].id.toString()
                popupLabel.dataset.bname = buildings[i].name.toString()
              }
            }*/
            const { lng, lat } = map.current.getCenter()
            if (
              buildings[i].longitude.toFixed(4) === lng.toFixed(4) &&
              buildings[i].latitude.toFixed(4) === lat.toFixed(4)
            ) {
              // dont do anything on map center
              //el.style.backgroundImage = `url('svg/marker.svg')`
              //el.style.width = `25px`
              //el.style.height = `35px`
              /*if ((window as any).selectedMarker !== null) {
                //;(window as any).selectedMarker.style.width = `15px`
                //;(window as any).selectedMarker.style.height = `15px`
                //;(window as any).selectedMarker.style.backgroundImage = `url('svg/dot.png')`
              }
              el.style.backgroundImage = `url('svg/marker.svg')`
              el.style.width = `36px`
              el.style.height = `51px`
              ;(window as any).selectedMarker = el*/
            }

            /*
             draggable: false,
              rotationAlignment: 'auto',
              pitchAlignment: 'auto',
              color:
                buildings[i].longitude.toFixed(4) === lng.toFixed(4) &&
                buildings[i].latitude.toFixed(4) === lat.toFixed(4)
                  ? hoverColor
                  : markerColor
            */
            if (map.current.getPitch() > 75) {
              ;(window as any).markers.forEach((mker: any) => mker.remove())
              ;(window as any).markers = []
            } else {
              const found = false
              /*;(window as any).markers.forEach((mker: any) => {
                if (
                  mker._lngLat.lng.toFixed(4) === buildings[i].longitude.toFixed(4) &&
                  mker._lngLat.lat.toFixed(4) === buildings[i].latitude.toFixed(4)
                ) {
                  found = true
                }
              })*/
              if (!found) {
                /* const popup = new mapboxgl.Popup({
                  closeButton: false,
                  className: 'markerlabel js-markerlabel-' + buildings[i].id.toString(),
                  closeOnClick: false,
                  closeOnMove: false,
                  anchor: 'left'
                }).setText(buildings[i].name)
                const marker = new mapboxgl.Marker(el)
                  .setLngLat([buildings[i].longitude, buildings[i].latitude])
                  .addTo(map.current)
                  .setPopup(popup)
                  .togglePopup()
                ;(window as any).markers.push(marker)
                popup.getElement().dataset.bid = buildings[i].id
                popup.getElement().dataset.bname = buildings[i].name*/
                /*popup.getElement().onclick = function (e: any) {
                  return false
                }*/
                /*popup.getElement().onclick = function (e: any) {
                  if ((window as any).selectedMarker !== null) {
                    ;(window as any).selectedMarker.style.width = `15px`
                    ;(window as any).selectedMarker.style.height = `15px`
                    ;(window as any).selectedMarker.style.backgroundImage = `url('svg/dot.png')`
                  }
                  marker.getElement().style.backgroundImage = `url('images/marker_ani.gif')`
                  marker.getElement().style.width = `36px`
                  marker.getElement().style.height = `51px`
                  ;(window as any).selectedMarker = marker.getElement()
                  clickBuilding(buildings[i].id, buildings[i].name)
                  e.stopPropagation()
                  return
                }

                marker.getElement().addEventListener('click', () => {
                  marker.togglePopup()
                })*/
              } else {
                /*;(window as any).markers.forEach((mker: any) => {
                  if (
                    mker.getElement() instanceof HTMLElement &&
                    mker.getElement() !== (window as any).selectedMarker
                  ) {
                    mker.getElement().style.width = getZoomedIconWidth()
                    mker.getElement().style.height = getZoomedIconWidth()
                  }
                })*/
              }
            }
            //.setPopup(popup)
            /*marker.getElement().addEventListener('mouseover', (e: any) => {
              const foundMarker = e.target.querySelectorAll('svg path[fill="' + markerColor + '"]')
              if (foundMarker.length > 0) {
                foundMarker[0].setAttribute('fill', hoverColor)
              }
            })

            marker.getElement().addEventListener('mouseleave', (e: any) => {
              const foundMarker = e.target.querySelectorAll('svg path[fill="' + hoverColor + '"]')
              if (foundMarker.length > 0) {
                foundMarker[0].setAttribute('fill', markerColor)
              }
            })*/
            //draw 3d buildings
            ;(window as any).newBuildings = []
            if (
              buildings[i].vrUrls !== undefined &&
              buildings[i].vrUrls != null &&
              buildings[i].vrUrls.x != null &&
              buildings[i].vrUrls.x !== undefined
            ) {
              buildingModels.push({
                id: buildings[i].id,
                model: buildings[i].vrUrls.files[0],
                longitude: buildings[i].vrUrls.longitude,
                latitude: buildings[i].vrUrls.latitude,
                modelScale: buildings[i].vrUrls.scale,
                modelX: buildings[i].vrUrls.x,
                modelY: buildings[i].vrUrls.y,
                modelZ: buildings[i].vrUrls.z
              })
              ;(window as any).newBuildings.push(buildings[i].id)
            }
          }

          const oldBuildings = (window as any).oldBuildings
          if (
            map.current.getLayer('custom_layer') !== undefined &&
            JSON.stringify((window as any).newBuildings) !== JSON.stringify(oldBuildings)
          ) {
            map.current.removeLayer('custom_layer')
          }

          if (JSON.stringify((window as any).newBuildings) !== JSON.stringify(oldBuildings)) {
            ;(window as any).oldBuildings = (window as any).newBuildings
            map.current.addLayer({
              id: 'custom_layer',
              type: 'custom',
              renderingMode: '3d',
              onAdd(map: any, mbxContext: any) {
                ;(window as any).tb = new Threebox(map, mbxContext, { defaultLights: true })
                for (let j = 0; j < buildingModels.length; j++) {
                  if (buildingModels[j].model) {
                    const options = {
                      obj: buildingModels[j].model,
                      type: 'gltf',
                      scale: parseFloat(buildingModels[j].modelScale),
                      units: 'meters',
                      rotation: {
                        x: buildingModels[j].modelX,
                        y: buildingModels[j].modelY,
                        z: buildingModels[j].modelZ
                      }
                    }
                    ;(window as any).tb.loadObj(options, function (model: any) {
                      ;(window as any).tb.add(
                        model.setCoords([buildingModels[j].longitude, buildingModels[j].latitude])
                      )
                    })
                  }
                }
              },
              render(gl: any, matrix: any) {
                ;(window as any).tb.update()
                if (map.current.getLayer('dao-dev') !== undefined) {
                  map.current.moveLayer('dao-dev')
                } else {
                  map.current.moveLayer('dao')
                }
              }
            })
          }
          //render click
          //const { lng, lat } = map.current.getCenter()
          /*;(window as any).markers.forEach((mker: any) => {
            if (mker._lngLat.lng.toFixed(4) === lng.toFixed(4) && mker._lngLat.lat.toFixed(4) === lat.toFixed(4)) {
              if ((window as any).selectedMarker !== null) {
                ;(window as any).selectedMarker.style.width = getZoomedIconWidth()
                ;(window as any).selectedMarker.style.height = getZoomedIconWidth()
                ;(window as any).selectedMarker.style.backgroundImage = `url('svg/dot.png')`
              }

              mker.getElement().style.width = `36px`
              mker.getElement().style.height = `49px`
              mker.getElement().style.backgroundImage = `url('images/marker_ani.gif')`
              ;(window as any).selectedMarker = mker.getElement()
            }
          })*/
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }, [getMapBuildings])

  const fetch3dBuilidngs = useCallback(() => {
    if (map.current) {
      const vector1 = map.current?.getBounds().getNorthEast()
      const vector2 = map.current?.getBounds().getSouthWest()
      //const { lng, lat } = map.current.getCenter()
      get3dMapBuildings({
        latitudeLeft: vector2.lat,
        latitudeRight: vector1.lat,
        longitudeLeft: vector2.lng,
        longitudeRight: vector1.lng
      })
        .then((res: any) => {
          const buildings = res.data.data.data
          const buildingModels: any[] = []
          for (let i = 0; i < buildings.length; i++) {
            //draw 3d buildings
            ;(window as any).new3dBuildings = []
            if (
              buildings[i].vrUrls !== undefined &&
              buildings[i].vrUrls != null &&
              buildings[i].vrUrls.x != null &&
              buildings[i].vrUrls.x !== undefined
            ) {
              buildingModels.push({
                id: buildings[i].id,
                model: buildings[i].vrUrls.files[0],
                longitude: buildings[i].vrUrls.longitude,
                latitude: buildings[i].vrUrls.latitude,
                modelScale: buildings[i].vrUrls.scale,
                modelX: buildings[i].vrUrls.x,
                modelY: buildings[i].vrUrls.y,
                modelZ: buildings[i].vrUrls.z
              })
              ;(window as any).new3dBuildings.push(buildings[i].id)
            }
          }

          const oldBuildings = (window as any).old3dBuildings
          if (
            map.current.getLayer('custom_3d_layer') !== undefined &&
            JSON.stringify((window as any).new3dBuildings) !== JSON.stringify(oldBuildings)
          ) {
            map.current.removeLayer('custom_3d_layer')
          }

          if (JSON.stringify((window as any).new3dBuildings) !== JSON.stringify(oldBuildings)) {
            ;(window as any).old3dBuildings = (window as any).new3dBuildings
            map.current.addLayer({
              id: 'custom_3d_layer',
              type: 'custom',
              renderingMode: '3d',
              onAdd(map: any, mbxContext: any) {
                ;(window as any).tb = new Threebox(map, mbxContext, { defaultLights: true })
                for (let j = 0; j < buildingModels.length; j++) {
                  if (buildingModels[j].model) {
                    const options = {
                      obj: buildingModels[j].model,
                      type: 'gltf',
                      scale: parseFloat(buildingModels[j].modelScale),
                      units: 'meters',
                      rotation: {
                        x: buildingModels[j].modelX,
                        y: buildingModels[j].modelY,
                        z: buildingModels[j].modelZ
                      }
                    }
                    ;(window as any).tb.loadObj(options, function (model: any) {
                      ;(window as any).tb.add(
                        model.setCoords([buildingModels[j].longitude, buildingModels[j].latitude])
                      )
                    })
                  }
                }
              },
              render(gl: any, matrix: any) {
                ;(window as any).tb.update()
              }
            })
          }
        })
        .catch((e: any) => {
          console.error(e)
        })
    }
  }, [get3dMapBuildings])

  useEffect(() => {
    if (map.current) return // initialize map only once
    map.current = new mapboxgl.Map({
      attributionControl: false,
      touchPitch: true,
      container: mapContainer.current,
      //prod
      // style: 'mapbox://styles/austinc2022/clco3ytdv000g14o7cqahvsid',
      //dev
      style: process.env.REACT_APP_MAPBOX_STYLE + '?version=' + new Date().toISOString().slice(0, 10),
      center: [mapCenter.lng, mapCenter.lat],
      pitch: 45,
      //bearing: -60,
      //minZoom: 5,
      zoom
    })
    window.mapbox = map.current
    ;(window as any).navigationControl = new mapboxgl.NavigationControl({
      showCompass: true,
      showZoom: true,
      visualizePitch: false
    })
    map.current.addControl((window as any).navigationControl, 'bottom-left')
    ;(window as any).geoControl = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: true,
      showUserHeading: false,
      showUserLocation: true,
      showAccuracyCircle: false
    })
    map.current.addControl((window as any).geoControl, 'bottom-left')

    /*map.current.addControl(new mapboxgl.FullscreenControl(), 'bottom-left')
    map.current.addControl(
      new mapboxgl.NavigationControl({
        showCompass: true,
        showZoom: true,
        visualizePitch: true
      }),
      'bottom-right'
    )*/
    /*map.current.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true,
        showUserHeading: true
      })
    )*/
    /*const scale = new mapboxgl.ScaleControl({
      maxWidth: 80,
      unit: 'imperial'
    })
    map.current.addControl(scale)
    scale.setUnit('metric')
    */
    //dev is dao-dev, prod is dao
    fetchBuilidngs()
    fetch3dBuilidngs()
  }, [fetch3dBuilidngs, fetchBuilidngs, clickBuilding, mapCenter, zoom, currentUserInfo])

  useEffect(() => {
    const mapboxLayer = process.env.REACT_APP_MAPBOX_LAYER ?? 'dao-dev'
    const mapCurrent = (window as any).mapbox
    mapCurrent.on('click', mapboxLayer, (e: any) => {
      mapCurrent.setLayoutProperty(mapboxLayer, 'icon-image', [
        'match',
        ['id'], // get the feature id (make sure your data has an id set or use generateIds for GeoJSON sources
        e.features[0].id,
        'Component 21', //image when id is the clicked feature id
        'Ellipse 266' // default
      ])
      if (
        e.features[0].properties.bid !== undefined &&
        e.features[0].properties.bid !== '' &&
        e.features[0].properties.bid !== null
      ) {
        clickBuilding(parseInt(e.features[0].properties.bid.toString()), e.features[0].properties.place_name.toString())
      }
    })

    mapCurrent.on('moveend', () => {
      fetchBuilidngs()
      fetch3dBuilidngs()
      //
      const propertyId = parseInt((window as any).openBuildingDaoId)
      if (propertyId !== undefined && propertyId !== null && (window as any).openBuildingDaoId !== '') {
        const feature = mapCurrent
          .querySourceFeatures('composite', {
            sourceLayer: mapboxLayer.toUpperCase()
          })
          .filter((x: any) => x.properties.bid === propertyId)[0]
        if (feature !== undefined) {
          mapCurrent.setLayoutProperty(mapboxLayer, 'icon-image', [
            'match',
            ['id'], // get the feature id (make sure your data has an id set or use generateIds for GeoJSON sources
            feature.id,
            'Component 21', //image when id is the clicked feature id
            'Ellipse 266' // default
          ])
          ;(window as any).openBuildingDaoId = ''
        }
      }
    })
  }, [fetch3dBuilidngs, fetchBuilidngs, clickBuilding, mapCenter, zoom, currentUserInfo])

  return (
    <div>
      <S.MapContainer ref={mapContainer}></S.MapContainer>
    </div>
  )
}

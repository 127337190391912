import IconLocation from 'assets/images/icon-location.png'
import * as S from 'components/PlacesList/styles'
import { addDoc, collection } from 'firebase/firestore'
import { useCallback } from 'react'
import { useToggleCommunity } from 'state/application/hooks'
import { useTogglePlaces } from 'state/application/hooks'
import { updateMapLocation } from 'state/application/reducer'
import { updateBuildingId, updateSelectBuildingName } from 'state/home/reducer'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { db } from 'utils/firestore'

export default function PlaceItem({ item }: { item: any }) {
  const toggle = useToggleCommunity()
  const dispatch = useAppDispatch()
  const togglePlaces = useTogglePlaces()
  const currentUserInfo = useAppSelector((state) => state.user.userInfo)
  const handleBuildingClick = useCallback(() => {
    dispatch(updateMapLocation({ lat: item.latitude, lng: item.longitude, building: item }))
    if (item.id !== null && item.id !== undefined && item.id !== '0' && item.id !== 0) {
      dispatch(updateBuildingId({ buildingId: item.id }))
      dispatch(updateSelectBuildingName({ selectBuildingName: item.name }))
      toggle()
    } else {
      dispatch(updateSelectBuildingName({ selectBuildingName: item.name }))
      togglePlaces()
    }
    ;(window as any).openBuildingDaoId = item.id.toString()
    window.mapbox.flyTo({
      center: [item.longitude, item.latitude],
      essential: true // this animation is considered essential with respect to prefers-reduced-motion
    })
    addDoc(collection(db, 'ACTIONS'), {
      ACTION: 'SEARCH',
      TIME: new Date().toLocaleDateString('en-AU'),
      TIMESTAMP: Date.now(),
      USER_ID: currentUserInfo?.userId !== undefined ? currentUserInfo.userId : ''
    })
  }, [currentUserInfo?.userId, dispatch, item, toggle, togglePlaces])
  return (
    <S.Item onClick={handleBuildingClick} className={'js-placeItem'}>
      <S.LocationImg src={IconLocation} alt=""></S.LocationImg>
      <S.InfoContent>
        <S.Title>{item.name}</S.Title>
        <S.SubTitle>
          {(item?.addressLine1 ?? '') +
            (item?.addressLine1 && (item?.addressLine2 || item?.area || item?.city) ? ', ' : '') +
            (item?.addressLine2 ?? '') +
            (item?.addressLine2 && (item?.area || item?.city) ? ', ' : '') +
            (item?.area ?? '') +
            (item?.area && item?.city ? ', ' : '') +
            (item?.city ?? '') +
            (item.id === '0' ? '' : ', ' + item?.country ?? 'Australia')}
        </S.SubTitle>
      </S.InfoContent>
    </S.Item>
  )
}

import MoreIcon from 'assets/images/icon-dots.png'
import Guest from 'assets/images/icon-guest.png'
import { sendEvent } from 'components/Analytics'
import * as S from 'components/Community/styles'
import { CustomizeEvent } from 'constants/events'
import { getShowTime } from 'constants/misc'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { useCallback, useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import {
  useLeftModalOpen,
  useToggleOtherUser,
  useTogglePostComments,
  useTogglePostImage
} from 'state/application/hooks'
import { useAddPopup } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { updateOtherId, updatePostImages, updatePostInfo } from 'state/home/reducer'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { useDeletePostMutation, useGetPostInfoMutation } from 'state/postApi/slice'
import { ExternalLink } from 'theme/components'
import useBus from 'use-bus'
import { dispatch as dispatchEvent } from 'use-bus'
import { replaceImageUrl } from 'utils'
import { shortAddress } from 'utils/safeNamehash'

export default function PostCard({ item, lastItem }: { item: any; lastItem?: boolean }) {
  const userProfile = useAppSelector((state) => state.user.userProfile)
  const currentUserInfo = useAppSelector((state) => state.user.userInfo)
  const toggle = useTogglePostImage()
  const toggleComments = useTogglePostComments()
  const addPopup = useAddPopup()
  const [deletePost] = useDeletePostMutation()
  const [getPostInfo] = useGetPostInfoMutation()
  const postImageOpen = useLeftModalOpen(ApplicationModal.POST_LARGE_IMAGE)
  const commentOpen = useLeftModalOpen(ApplicationModal.POST_COMMENTS)
  const otherUserOpen = useLeftModalOpen(ApplicationModal.OTHERUSER)
  const dispatch = useAppDispatch()
  const [showDeleteButton, setShowDeleteButton] = useState(false)
  const [postInfo, setPostInfo] = useState(item)
  const toggleOtherUser = useToggleOtherUser()

  const node = useRef<HTMLDivElement>()
  useOnClickOutside(
    node,
    showDeleteButton
      ? () => {
          setShowDeleteButton(false)
        }
      : undefined
  )

  const showDeleteButtonClick = useCallback(() => {
    setShowDeleteButton(!showDeleteButton)
  }, [showDeleteButton])

  const handleImageClick = useCallback(
    (index: number) => {
      dispatch(updatePostImages({ postImages: postInfo?.images, currentIndex: index }))
      if (!postImageOpen) toggle()
    },
    [dispatch, postInfo?.images, postImageOpen, toggle]
  )

  const handleDeletePost = useCallback(
    (postId: string) => {
      deletePost({ postId })
        .then((data: any) => {
          if (data?.data?.code === 0) {
            dispatchEvent(CustomizeEvent.REFRESH_POSTS)
            setShowDeleteButton(false)
            addPopup({ tip: 'The post was successfully deleted!' })
            sendEvent({
              category: 'Delete-Post',
              action: 'Delete-Post Success',
              label: postId
            })
          } else {
            if (data?.error?.status === 429) {
              addPopup({ error: "You're deleting post too quickly. Please wait a few minutes and try again." })
            } else {
              addPopup({ error: 'Failed to delete post. Please try again.' })
            }
            sendEvent({
              category: 'Delete-Post',
              action: 'Delete-Post Unsuccess',
              label: postId
            })
          }
        })
        .catch((e) => {
          console.error(e)
          addPopup({ error: 'Failed to delete post. Please try again.' })
          sendEvent({
            category: 'Delete-Post',
            action: 'Delete-Post Unsuccess',
            label: postId
          })
        })
    },
    [addPopup, deletePost]
  )

  const handleCommentsClick = useCallback(() => {
    if (!commentOpen) {
      dispatch(updatePostInfo({ postInfo }))
    }
    toggleComments()
  }, [commentOpen, dispatch, postInfo, toggleComments])

  useBus(CustomizeEvent.REFRESH_COMMENT + postInfo?.postId, () => {
    getPostInfo({ postId: postInfo?.postId })
      .then((data: any) => {
        if (data?.data?.code === 0) {
          setPostInfo(data?.data?.data)
        } else {
          addPopup({ error: 'Error!' })
        }
      })
      .catch((e) => {
        console.error(e)
        addPopup({ error: 'Error!' })
      })
  })

  const handleClickOtherUser = useCallback(() => {
    if (currentUserInfo?.userId !== postInfo?.owner) {
      dispatch(updateOtherId({ userId: postInfo?.owner }))
      if (!otherUserOpen) {
        toggleOtherUser()
      }
    }
  }, [currentUserInfo?.userId, dispatch, otherUserOpen, postInfo?.owner, toggleOtherUser])

  return (
    <S.CardPanel last={lastItem ?? false}>
      <S.AvatarPanel role={postInfo?.role ?? 'customer'} onClick={handleClickOtherUser}>
        <S.Avatar src={postInfo?.avatar ? replaceImageUrl(postInfo?.avatar, 200) : Guest} alt="post avatar"></S.Avatar>
      </S.AvatarPanel>
      <S.InfoPanel>
        <S.PostHeader>
          <S.AccountName onClick={handleClickOtherUser}>
            {postInfo?.nickName ?? shortAddress(postInfo?.owner, 6, 4)}
          </S.AccountName>
          {postInfo?.role && postInfo?.role !== 'customer' && (
            <S.AccountType role={postInfo?.role}>{postInfo?.role}</S.AccountType>
          )}
          <S.MoreInfo>
            <S.TimeInfo>{getShowTime(postInfo?.createdAt)}</S.TimeInfo>
            {((currentUserInfo &&
              currentUserInfo?.userId &&
              currentUserInfo?.userId?.toLowerCase() === postInfo.owner.toLowerCase()) ||
              (userProfile?.role && (userProfile?.role === 'developer' || userProfile?.role === 'agent'))) && (
              <S.MoreIcon onClick={showDeleteButtonClick}>
                <img src={MoreIcon} alt="more icon" />
              </S.MoreIcon>
            )}
          </S.MoreInfo>
        </S.PostHeader>
        <S.PostContent>
          {showDeleteButton && (
            <S.DeleteButton
              ref={node}
              color="#DEDEDE"
              fontWeight={400}
              onClick={() => {
                handleDeletePost(postInfo.postId)
              }}
            >
              Delete
            </S.DeleteButton>
          )}
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
              // eslint-disable-next-line react/display-name
              a: ({ ...props }) => <ExternalLink href="" {...props} />
            }}
          >
            {postInfo.content}
          </ReactMarkdown>
        </S.PostContent>
        {postInfo !== null && postInfo?.images.length > 1 && (
          <S.ImageGrid>
            {postInfo?.images.map((image: string, index: number) => {
              return (
                <S.PostImagePanel key={index} onClick={() => handleImageClick(index)}>
                  <S.PostImage src={replaceImageUrl(image, 600)} alt="post image"></S.PostImage>
                </S.PostImagePanel>
              )
            })}
          </S.ImageGrid>
        )}
        {postInfo !== null && postInfo?.images.length === 1 && (
          <S.PostSingleImagePanel style={{ marginTop: '1.07vh' }} onClick={() => handleImageClick(0)}>
            <S.PostSingleImage src={replaceImageUrl(postInfo?.images[0], 600)} alt="post image"></S.PostSingleImage>
          </S.PostSingleImagePanel>
        )}
        <S.CommentsPanel onClick={handleCommentsClick}>
          {postInfo.comments > 1 ? `${postInfo.comments} Comments` : `${postInfo.comments} Comment`}
        </S.CommentsPanel>
      </S.InfoPanel>
    </S.CardPanel>
  )
}
